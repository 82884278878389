import axios from 'axios'
import { config } from './apiServiceConfig'
import {store} from "@/store";
//import { store } from '@/store'
const apiUrl = config.$api_url

export default  {
    getGame(data) {
        return axios.get(apiUrl+'/game/'+data.link)
            .then(response => response.data)
    },
    getGames(data){
        return axios.post(apiUrl+'/games', data)
            .then((response) => response.data)
    },
    getStores(data){
        return axios.post(apiUrl+'/stores', data)
            .then((response) => response.data)
    },
    getStore(data) {
        return axios.get(apiUrl+'/store/'+data.link)
            .then(response => response.data)
    },
    getProduct(data) {
        return axios.get(apiUrl+'/product/'+data.id)
            .then((response) => response.data)
    },
    buyProducts(data) {
        axios.defaults.headers.common['x-access-token'] = `${store.getters["User/token"]}`

        return axios.post(apiUrl+'/game/buyProducts', data)
            .then((response) => response.data)
    }
}