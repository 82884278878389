<template>
  <div>
    <Header></Header>
    <section class="contact">
      <div class="page-title">
        <div class="container">
          <div class="bg-blur"></div>
          <h1>İletişim</h1>
        </div>
      </div>

      <div class="content">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <form>
                <div class="contact-form">
                  <div class="item">
                    <label>ADINIZ SOYADINIZ</label>
                    <input type="text" class="form-control">
                  </div>
                  <div class="item">
                    <label>E-POSTA ADRESİNİZ</label>
                    <input type="email" class="form-control">
                  </div>
                  <div class="item">
                    <label>TELEFON NUMARANIZ</label>
                    <input type="phone" class="form-control">
                  </div>
                  <div class="item">
                    <label>MESAJIN KONUSU</label>
                    <div class="dropdown bootstrap-select"><select class="selectpicker" data-show-subtext="true"
                        tabindex="-98">
                        <option>Şikayet</option>
                        <option>Öneri</option>
                        <option>Reklam</option>
                      </select><button type="button" class="btn dropdown-toggle btn-light" data-toggle="dropdown"
                        role="combobox" aria-owns="bs-select-1" aria-haspopup="listbox" aria-expanded="false"
                        title="Şikayet">
                        <div class="filter-option">
                          <div class="filter-option-inner">
                            <div class="filter-option-inner-inner">Şikayet</div>
                          </div>
                        </div>
                      </button>
                      <div class="dropdown-menu ">
                        <div class="inner show" role="listbox" id="bs-select-1" tabindex="-1">
                          <ul class="dropdown-menu inner show" role="presentation"></ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="item">
                    <label>MESAJINIZ</label>
                    <textarea class="form-control"></textarea>
                  </div>
                  <div class="item text-right">
                    <button class="btn-hover"><span><img :src="require('../assets/images/paper.png')">
                        GÖNDER</span></button>
                  </div>
                  <div class="clearfix"></div>
                </div>
              </form>
            </div>
            <div class="col-md-6">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6526.1384182878055!2d33.93656192751971!3d35.129946395889405!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14dfc82f78818d4b%3A0x19d239f6ea88966f!2zR2F6aW1hxJ91c2EgTGltYW7EsSwgR2F6aW1hxJ91c2E!5e0!3m2!1str!2str!4v1584290760038!5m2!1str!2str"
                width="100%" height="396" frameborder="0" style="border:0; border-radius:8px;" allowfullscreen=""
                aria-hidden="false" tabindex="0"></iframe>
              <div class="contact-adress">
                <div class="item">
                  <div class="img"><img :src="require('../assets/images/firma.png')"></div>
                  <div class="text">
                    <label>FİRMA BİLGİLERİ</label>
                    <h2>
                      Decade Bilişim LTD.
                    </h2>
                    <h5>Serbest Liman ve Bölge , Posta Kutusu No : 568<br>
                      Gazimağusa / Kıbrıs</h5>
                  </div>
                  <div class="clearfix"></div>
                </div>

                <div class="item">
                  <div class="img"><img :src="require('../assets/images/email.png')"></div>
                  <div class="text">
                    <label>E-POSTA</label>
                    <h2>
                      iletisim@kasagame.com
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/Header';
import Footer from '@/components/Footer';

export default {
  name: "PageDetail",
  data() {
    return {
      loading: true,
    }
  },
  components: { Header, Footer },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { name: 'description', content: this.title },
        { name: 'keywords', content: this.title },
        { charset: 'utf-8' }
      ]
    }
  }

}
</script>

<style scoped>
.contact {
  background: #130E17;
}
</style>