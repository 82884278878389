<template>
<div>
  <div>
    loading..
  </div>
</div>

</template>

<script>
export default {
name: "PaymentApi"
}
</script>

<style scoped>

</style>