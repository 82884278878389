<template>
  <section class="pubpanel">
    <div class="pubpanel-title">
      <div class="container">
        <div class="bg-blur"></div>
        <h1>Aldığım İlanlar</h1>
      </div>
    </div>
    <div class="container">
      <div class="sidebar-one">
        <LeftMenu :data="page"></LeftMenu>
      </div>
      <div class="pubpanel-content">
        <div class="stepullah">
          <b-alert variant="danger" show v-if="dataError"> Veriler Yüklenemedi.Lütfen sayfayı yenileyin veya daha sonra tekrar deneyin.</b-alert>,
          <b-container >
            <b-row lg="12" class="user-filters">
              <b-col md="3">
                <b-form-datepicker v-model="startDate" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" class="mb-2" size="sm" label-no-date-selected="Bşlgç. Tarihi" dark></b-form-datepicker>
              </b-col>
              <b-col md="3">
                <b-form-datepicker v-model="endDate" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" class="mb-2" size="sm" label-no-date-selected="Bitiş Tarihi" dark></b-form-datepicker>
              </b-col>
              <b-col md="3">
                <b-select size="sm" v-model="status"  :options="[{value: null, text: 'Tümü', select: true},{value: 'approved', text: 'Başarılı'},{value: 'waiting_saler', text: 'Satıcı Onayı Bekleyen'},{value: 'waiting_buyer', text: 'Sizin Onayınızı Bekleyen'},{value: 'declined', text:'İptal / İade edilen'}]"></b-select>
              </b-col>
              <b-col md="3">
                <b-button variant="info"  size="sm" @click="search">Sorgula</b-button>
              </b-col>
            </b-row>
            <b-row lg="12">

              <b-table
                  v-if="!dataError"
                  :items="items"
                  :fields="fields"
                  :busy="isLoading"
                  hover
                  dark
                  striped
                  borderless
                  responsive
                  show-empty
              >
                <template v-slot:table-busy>
                  <div class="text-center text-white my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Yükleniyor...</strong>
                  </div>
                </template>
                <template v-slot:empty="">
                  <b-alert show variant="warning">
                    Kayıt bulunamadı.
                  </b-alert>
                </template>
                <template v-slot:cell(price)="data">
                  {{ data.item.price | currency}} TL
                </template>
                <template v-slot:cell(title)="data">
                  {{ data.item.title }} <br>
                  <router-link :to="{path: '/profile/'+data.item.user.username}" style="">
                    <b-badge variant="info">
                      <i class="fa fa-user"/> {{data.item.user.username}}
                    </b-badge>
                  </router-link>
                </template>
                <template v-slot:cell(product)="data">
                 <router-link :to="{path: data.item.product.gameLink}" class="text-white">{{ data.item.product.gameTitle }} - {{ data.item.product.title }}</router-link>
                </template>
                <template v-slot:cell(game)="data">
                  <router-link :to="{path: '/store/'+data.item.game[0].link}" v-if="data.item.game[0].is_mall" class="text-white">
                    {{ data.item.game[0].title }}
                  </router-link>
                  <router-link :to="{path: '/game/'+data.item.game[0].link}" class="text-white" v-else>{{ data.item.game[0].title }}</router-link>
                </template>
                <template v-slot:cell(status)="data">
                  <b-badge pill variant="warning" v-if="data.item.status === 'waiting'">Yayın Onayı Bekliyor.</b-badge>
                  <b-badge pill variant="warning" v-if="data.item.status === 'waiting_sale'">Yayında</b-badge>
                  <b-badge pill variant="warning" v-if="data.item.status === 'waiting_saler'">Satıcı Onayı Bekleniyor.</b-badge>
                  <b-badge pill variant="warning" v-if="data.item.status === 'waiting_buyer'">Sizin Onayınız Bekleniyor.</b-badge>
                  <b-badge pill variant="success" v-if="data.item.status === 'approved'">Başarılı.</b-badge>
                  <b-badge pill variant="danger" v-if="data.item.status === 'declined'">İptal Edildi.</b-badge>
                </template>
                <template v-slot:cell(date)="data">
                  {{ data.item.check_date  | formatDate }}
                </template>
                <template v-slot:cell(details)="data">
                  <b-button @click="checkAdvertisement(data.item.id)"  v-if="data.item.status === 'waiting_buyer'" variant="success" class="btn btn-sm"> <i class="fa fa-check"/> Teslim Aldım </b-button>
                </template>


              </b-table>
            </b-row>
          </b-container>
        </div>

      </div>
      <div class="clearfix"></div>
    </div>
    <CheckBuyingAdvertisement :id="modalId"></CheckBuyingAdvertisement>
  </section>
</template>

<script>
import LeftMenu from "@/components/User/LeftMenu";
import authService from "@/services/authService";
import CheckBuyingAdvertisement from "@/components/Modals/CheckBuyingAdvertisement";
  export default {
    name: "AddsOrders",
    data() {
      return  {
        startDate: "",
        endDate: "",
        status: "",
        isLoading: true,
        page: "addsorders",
        dataError: false,
        modalId: 0,
        fields: [
          { key: 'id',label: "Sipariş ID" },
          { key: 'title',label: "İlan Başlığı" },
          { key: 'game',label: "Oyun" },
          { key: 'price',label: "Tutar" },
          { key: 'date',label: "Tarih" },
          { key: 'status',label: "Durum" },
          { key: 'details', label: "#"}
        ],
        items: []
      }
    },
    components:{
      LeftMenu,
      CheckBuyingAdvertisement
    },
    methods:{
      checkAdvertisement(id) {
        this.modalId = id
        this.$bvModal.show('confirmBuyingAdvertisement')
      },
      search() {
        this.isLoading = true
        this.getAddsOrders()
      },
      getAddsOrders(){
        authService.getAddsOrders({startDate: this.startDate, endDate: this.endDate, status: this.status})
            .then((response) => {
              this.isLoading = false
              if(response.data.state){
                this.items = response.data.list
                this.dataError = false

              }else
                this.dataError = true

            })
      }
    },
    async created() {
      this.getAddsOrders()
    },
    metaInfo() {
      return {
        title: 'Aldığım İlanlar - Hesabım',
      }
    }


  }
</script>

<style scoped>
/deep/.table-dark {
  background-color: #1E1622;
  color: #fff;
  border-radius: 10px;
}
.user-filters {
  background-color: #1E1622;
  color: #fff;
  border-radius: 10px;
  margin-bottom: 10px;
  height: 65px;
  padding: 14px;
}
</style>