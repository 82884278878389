<template>
  <div>
    <Header></Header>
    <section class="login-page">
      <div class="page-title">
        <div class="container">
          <div class="bg-blur"></div>
          <h1>Giriş Yap</h1>
        </div>
      </div>
      <div class="login-content">
        <div class="container">

          <div class="login-right">
            <!-- <div class="login-socials">
              <label>SOSYAL AĞLAR ARACILIĞIYLA GİRİŞ YAPABİLİRSİNİZ</label>
              <section id="firebaseui-auth-container"></section>
            </div>
            <div class="login-and">
              <label>veya</label>
            </div> -->
            <div class="login-form">

              <form class="needs-validation" novalidate @submit.prevent="signIn">
                <div class="logfor-group">
                  <label>E-Posta Adresiniz</label>
                  <input type="email" class="form-control" v-model.lazy="$v.email.$model"
                         :class="{'is-invalid' : $v.email.$error , 'is-valid' : $v.email.$model.length > 0 && $v.email.email}">
                  <div  v-if="!$v.email.required && $v.email.$anyError"  class="invalid-feedback feedback-pos">
                    Bu alan zorunludur.
                  </div>
                  <div v-if="!$v.email.email"  class="invalid-feedback feedback-pos" >
                    E-Posta adresiniz geçersiz.
                  </div>
                </div>
                <div class="logfor-group">
                  <label>Parolanız</label>
                  <input type="password" class="form-control"  v-model="$v.password.$model"
                         :class="{'is-invalid' : $v.password.$error , 'is-valid' : $v.password.$model.length > 0 && !$v.password.$anyError}"
                  >
                  <div class="invalid-feedback feedback-pos" v-if="!$v.password.required && $v.password.$anyError">
                    Bu alan zorunludur.
                  </div>
                  <div class="invalid-feedback feedback-pos" v-if="!$v.password.minLength">
                    Parolanız en az 6 harf ve karakterden oluşmalı
                  </div>

                </div>

                <div class="logfor-group">
                  <div class="remember">
                    <router-link to="/forgot-password">
                      <a>Parolamı Unuttum</a>
                    </router-link>
                  </div>
                  <div class="">
                    <div v-if="!isWait">
                      <div class="disabled-button " v-if="$v.$invalid">
                        <button class="btn btn-hover" ><span><img :src="require('@/assets/images/lr.png')"/> GİRİŞ YAP</span></button>
                      </div>
                      <div class="register-button" v-if="!$v.$invalid">
                        <button class="btn-hover" ><span><img :src="require('@/assets/images/lr.png')"/> GİRİŞ YAP</span></button>
                      </div>
                    </div>
                    <div v-if="isWait" class="register-button">
                      <button class="btn-hover " > <span>  <i class="fas fa-spinner fa-spin"></i></span> </button>
                    </div>
                  </div>
                </div>
                <p class="text-white">  </p>
                <div class="logfor-group reg-bottom">
                  <label class="register-link">
                    <router-link to="/register">
                      <a>ÜYE DEĞİL MİSİN? KAYIT OL</a>
                    </router-link>
                  </label>
                </div>
              </form>
            </div>
          </div>
          <div class="login-left">
            <div class="login-left-item">
              <div class="lole-img"><img :src="require('@/assets/images/l1.png')"/></div>
              <div class="lole-text">
                <h4>Güvenli Platform</h4>
                <p>
                  Kasagame çatısı altında üyelik bilgileriniz ve buna bağlı kişisel/banka/telefon gibi bilgileriniz güven altındadır, Hiçbir şekilde üçüncü parti servislerle paylaşılmaz. Siz alışverişinizi tamamlamadan paranız karşı tarafa gönderilmez, Kasagame Havuz Sistemi’nde bekletilir.
                </p>
              </div>
              <div class="clearfix"></div>
            </div>
            <div class="login-left-item">
              <div class="lole-img"><img :src="require('@/assets/images/l2.png')"/></div>
              <div class="lole-text">
                <h4>Kolay ve Avantajlı Alışveriş</h4>
                <p>
                  Dilediğiniz ürünleri rahatlıkla alabilir ve satabilirsiniz. Ödemenizi güvenli bir şekilde hesabınıza aktarabilirsiniz. Kampanyalar, indirimler ya da özel fırsatlar içeride sizi bekliyor.
                </p>
              </div>
              <div class="clearfix"></div>
            </div>
            <div class="login-left-item">
              <div class="lole-img"><img :src="require('@/assets/images/l3.png')"/></div>
              <div class="lole-text">
                <h4>İçerik Üret Para Kazan</h4>
                <p>
                  Kasagame’in Haberler kategorisine güncel ve kopya olmayan içerikler üreterek Kasa Kredi kazanabilir, bu kredileri dilediğiniz gibi sistem içerisinde harcayabilirsiniz.
                </p>
              </div>
              <div class="clearfix"></div>
            </div>
            <div class="login-left-item">
              <div class="lole-img"><img :src="require('@/assets/images/l4.png')"/></div>
              <div class="lole-text">
                <h4>Hızlı Bağış Al/Yap</h4>
                <p>
                  Siz de YouTube, Twitch, Mixer ya da Dlive gibi platformlarda yayın yapıyorsanız rahatlıkla bağış alabilirsiniz.
                </p>
              </div>
              <div class="clearfix"></div>
            </div>
            <div class="login-left-item">
              <div class="lole-img"><img :src="require('@/assets/images/l5.png')"/></div>
              <div class="lole-text">
                <h4>Düşük Komisyon</h4>
                <p>
                  İnternet sitemiz üzerinden yapılan her işlem için düşük komisyon ücreti ödersiniz.
                </p>
              </div>
              <div class="clearfix"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Header from "@/components/Header";
import Footer from "@/components/Footer";

import { required, minLength, email } from 'vuelidate/lib/validators';
import authService from "@/services/authService";
import AuthService from "@/services/authService";
//import firebase from "firebase";
//import * as firebaseui from 'firebaseui'
//import {store} from "@/store";

export default {
  name: "Login",
  computed: {
    ...mapGetters({
      siteSettings: 'siteInfo/siteSettings'
    })
  },
  data() {
    return {
      isValidForm : true,
      isWait: false,
      email: "",
      password: ""
    }
  },
  components: {
    Header,
    Footer
  },
  validations: {
    email: {
      required,
      email
    },
    password: {
      required,
      minLength: minLength(6)
    }
  },
  methods: {
    loginRedirect() {
      //this.$router.push({path: '/main'})
      if(this.$router.history.current.query.page)
        this.$router.push({path: this.$router.history.current.query.page})
      else
        this.$router.push({path: '/main'})
    },
    /*
        socialLogin(){
      let uiConfig = {
        signInOptions: [
          firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          firebase.auth.FacebookAuthProvider.PROVIDER_ID
        ],
        signInFlow: 'popup',
        callbacks: {
          signInSuccessWithAuthResult:  ((authResult) => {
            let accessToken = ""
            if(authResult.credential.providerId == "facebook.com"){
               accessToken = authResult.credential.accessToken
            }
            else if (authResult.credential.providerId == "google.com"){
              accessToken = authResult.credential.idToken
            }
            authService.loginWithSocials({token: accessToken, providerId: authResult.credential.providerId})
                .then((res) => {
                  if(res.state){
                    this.$swal({
                      toast: true,
                      position: 'top-end',
                      showConfirmButton: false,
                      timer: 3000,
                      icon: 'success',
                      title: 'Doğrulama Başarılı',
                      text: 'yönlendiriliyorsunuz..',
                    });
                    if(res.needRegister){
                      this.$store.dispatch('User/registerSocials', res)

                      setTimeout(() => {
                        this.$router.push({path: '/register'})
                      }, 2000)

                    }else {
                      this.$store.dispatch('User/registerSocials', {
                        facebook: {
                          accessToken: "",
                          needRegister: false,
                          providerId: "",
                          state: false,
                          user: {}
                        }
                      })
                      store.dispatch('User/userLogin', res)
                      store.dispatch('userId', res.user.id)

                      setTimeout(() => {
                        this.loginRedirect()
                      }, 2000)

                    }
                  }else {
                    this.$swal({
                      toast: true,
                      position: 'top-end',
                      showConfirmButton: false,
                      timer: 3000,
                      icon: 'error',
                      title: '',
                      text: 'Doğrulama başarısız..',
                    });

                  }
                })
          })
        }
      }
      let ui = firebaseui.auth.AuthUI.getInstance();
      if (!ui) {
        ui = new firebaseui.auth.AuthUI(firebase.auth())
      }
      ui.start('#firebaseui-auth-container', uiConfig);

    },
     */
    signIn() {
      authService.userLogin({email: this.email, password: this.password})
          .then((res) => {
            if(res.state){
              this.$swal({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                icon: 'success',
                title: 'Başarıyla giriş yaptınız',
                text: 'yönlendiriliyorsunuz..',
              });
              setTimeout(() => {
                this.loginRedirect()
              }, 2000)
            }else {
              this.$swal({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                icon: 'error',
                title: '',
                text: res.message,
              });

            }
          })
    },
    walletToken(){
      this.$store.dispatch('User/userLogin', {token: this.$route.query.token})
      setTimeout(() => {
        const response = AuthService.getSecretContent()
        response.then((result) => {
          if(!result.state){
            this.$store.dispatch('User/logout')
            this.$store.dispatch('userId')
          }else {
            this.$store.dispatch('User/userLogin', result)
            this.$store.dispatch('userId', result.user.id)
            this.$router.push('/main?action=balance')

          }
        })

      }, 200)

    }
  },
  metaInfo () {
    return {
      title: "Giriş Yap | " + this.siteSettings[0].title,
      meta: [
        { name: 'description', content: "Giriş Yap | " + this.siteSettings[0].description},
        { name: 'keywords', content: "Giriş Yap | " + this.siteSettings[0].keywords},
        { charset: 'utf-8' }
      ]
    }
  },
  /*
  beforeRouteEnter(to, from, next) {
    next(vm => {
      //vm.facebookLogin()
      vm.socialLogin()
    })
  }
  */
  created() {
    if(this.$route.query.token){
      this.walletToken()
    }
  }
}
</script>

<style scoped>
/*
/deep/.firebaseui-idp-text{
  display: none;
}
 */
.disabled-button button {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  height: 48px;
  border-radius: 6px;
  color: #fff;
  border: none;
  width: calc(100% - 310px);
  float: right;
}
</style>