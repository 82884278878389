<template>
  <section class="login-page">
    <div class="page-title">
      <div class="container">
        <div class="bg-blur"></div>
        <h1>Kayıt Ol</h1>
      </div>
    </div>
    <div class="login-content">
      <div class="container">
        <div class="login-right">
          <div class="login-form">
            <form class="needs-validation" novalidate @submit.prevent="signUp">
              <div class="logfor-group">
                <label>Kullanıcı Adınız</label>
                <input type="text" class="form-control" v-model.lazy="$v.username.$model"
                       :class="{'is-invalid' : $v.username.$error , 'is-valid' : $v.username.$model.length > 0 && !$v.username.$anyError}"
                >
                <div v-if="!$v.username.required && $v.username.$anyError" class="invalid-feedback feedback-pos" >
                  Bu alan zorunludur, lütfen doldurun.
                </div>
                <div v-if="!$v.username.uniq" class="invalid-feedback feedback-pos">
                  Bu kullanıcı adı daha önce alınmış.
                </div>
              </div>
              <div class="logfor-group">
                <div class="col-md-6">
                  <div class="logfor-group">
                    <label>Adınız</label>
                    <input type="text" class="form-control"  v-model.lazy="$v.name.$model"
                           :class="{'is-invalid' : $v.name.$error , 'is-valid' : $v.name.$model.length > 0 && !$v.name.$anyError}"
                    >
                    <div v-if="!$v.name.required && $v.name.$anyError" class="invalid-feedback feedback-pos" >
                      Bu alan zorunludur, lütfen doldurun.
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="logfor-group">
                    <label>Soyadınız</label>
                    <input type="text" class="form-control"  v-model.lazy="$v.surname.$model"
                           :class="{'is-invalid' : $v.surname.$error , 'is-valid' : $v.surname.$model.length > 0 && !$v.surname.$anyError}"
                    >
                    <div v-if="!$v.surname.required && $v.surname.$anyError" class="invalid-feedback feedback-pos" >
                      Bu alan zorunludur, lütfen doldurun.
                    </div>
                  </div>
                </div>
              </div>
              <div class="logfor-group">
                <label >E-Posta Adresiniz</label>
                <input type="email" class="form-control" v-model.lazy="$v.email.$model"
                       :class="{'is-invalid' : $v.email.$error , 'is-valid' : $v.email.$model.length > 0 && $v.email.email}">
                <div  v-if="!$v.email.required && $v.email.$anyError"  class="invalid-feedback feedback-pos">
                  Bu alan zorunludur.
                </div>
                <div v-if="!$v.email.email"  class="invalid-feedback feedback-pos" >
                  Email adresiniz geçersiz.
                </div>
                <div  v-if="!$v.email.uniq" class="invalid-feedback feedback-pos" >
                  Bu epostayla kayıt oluşturulmuş
                </div>
              </div>
              <div class="logfor-group">
                <label >Telefon Numaranız </label>
                <vue-tel-input :placeholder="'Telefon Numaranızı Girin'" @input="onInput" v-model.lazy="$v.phone.$model"
                               :inputClasses="phoneClass"
                ></vue-tel-input>
                <div class="invalid-feedback feedback-pos" v-if="!phoneValid">
                  Lütfen telefon numaranızı doğru girin.
                </div>
                <div class="invalid-feedback feedback-pos" v-if="!$v.phone.required && $v.phone.$anyError">
                  Bu alan zorunludur.
                </div>
              </div>
              <div class="logfor-group">
                <div class="col-md-6">
                  <label>Parolanız</label>
                  <input type="password" class="form-control"  v-model.lazy="$v.password.$model"
                         :class="{'is-invalid' : $v.password.$error , 'is-valid' : $v.password.$model.length > 0 && !$v.password.$anyError}"
                  >
                  <div class="invalid-feedback feedback-pos" v-if="!$v.password.required && $v.password.$anyError">
                    Bu alan zorunludur.
                  </div>
                  <div class="invalid-feedback feedback-pos" v-if="!$v.password.minLength">
                    arolanız en az 6 harf ve karakterden oluşmalıdır
                  </div>

                </div>
                <div class="col-md-6">
                  <div class="logfor-group">
                    <label>Parola tekrarı</label>
                    <input type="password" class="form-control"   v-model.lazy="$v.retryPassword.$model"
                           :class="{'is-invalid' : $v.retryPassword.$error , 'is-valid' : $v.retryPassword.$model.length > 0 && !$v.retryPassword.$anyError}"
                    >
                    <div class="invalid-feedback feedback-pos" v-if="!$v.retryPassword.minLength">
                      Parolanız en az 6 harf ve karakterden oluşmalıdır
                    </div>
                    <div class="invalid-feedback feedback-pos" v-if="!$v.retryPassword.sameAs">
                      Parolalar uyuşmuyor.</div>

                  </div>
                </div>
              </div>
              <div class="logfor-group">
                <div class="membership custom-control custom-checkbox mb-3">
                  <input type="checkbox" class="custom-control-input" id="terms" required v-model.lazy="$v.terms.$model"
                         :class="{'is-invalid' : !$v.terms.$model }"
                  >
                  <label class="custom-control-label" for="terms">
                    <router-link to="/satis-sozlesmesi"><a>Üyelik koşullarını</a></router-link> okudum,
                    kabul ediyorum.
                  </label>
                  <div class="invalid-feedback feedback-pos" v-if="!$v.terms.sameAs">
                    Üyelik koşullarını onaylamanız gerek.
                  </div>
                </div>
                <div class="">
                  <div v-if="!isWait">
                    <div class="register-button" v-if="$v.$invalid">
                      <button  class="btn-hover" disabled ><span><img :src="require('@/assets/images/lr.png')"/> KAYIT OL</span></button>

                    </div>
                    <div class="register-button" v-if="!$v.$invalid">
                      <button class="btn-hover" ><span><img :src="require('@/assets/images/lr.png')"/> KAYIT OL</span></button>
                    </div>
                  </div>
                  <div v-if="isWait" class="register-button">
                    <button class="btn-hover " > <span>  <i class="fas fa-spinner fa-spin"></i></span> </button>
                  </div>
                </div>
              </div>
              <div class="logfor-bottom">
                <router-link to="/login">
                  <a>ÜYE MİSİN? GİRİŞ YAP</a>
                </router-link>
              </div>
            </form>


          </div>
        </div>
        <div class="login-left">
          <div class="login-left-item">
            <div class="lole-img"><img :src="require('@/assets/images/l1.png')"/></div>
            <div class="lole-text">
              <h4>Güvenli Platform</h4>
              <p>
                Kasagame çatısı altında üyelik bilgileriniz ve buna bağlı kişisel/banka/telefon gibi bilgileriniz güven altındadır, Hiçbir şekilde üçüncü parti servislerle paylaşılmaz. Siz alışverişinizi tamamlamadan paranız karşı tarafa gönderilmez, Kasagame Havuz Sistemi’nde bekletilir.
              </p>
            </div>
            <div class="clearfix"></div>
          </div>
          <div class="login-left-item">
            <div class="lole-img"><img :src="require('@/assets/images/l2.png')"/></div>
            <div class="lole-text">
              <h4>Kolay ve Avantajlı Alışveriş</h4>
              <p>
                Dilediğiniz ürünleri rahatlıkla alabilir ve satabilirsiniz. Ödemenizi güvenli bir şekilde hesabınıza aktarabilirsiniz. Kampanyalar, indirimler ya da özel fırsatlar içeride sizi bekliyor.
              </p>
            </div>
            <div class="clearfix"></div>
          </div>
          <div class="login-left-item">
            <div class="lole-img"><img :src="require('@/assets/images/l3.png')"/></div>
            <div class="lole-text">
              <h4>İçerik Üret Para Kazan</h4>
              <p>
                Kasagame'in Haberler kategorisine güncel ve kopya olmayan içerikler üreterek Kasa Kredi kazanabilir, bu kredileri dilediğiniz gibi sistem içerisinde harcayabilirsiniz.
              </p>
            </div>
            <div class="clearfix"></div>
          </div>
          <div class="login-left-item">
            <div class="lole-img"><img :src="require('@/assets/images/l4.png')"/></div>
            <div class="lole-text">
              <h4>Hızlı Bağış Al/Yap</h4>
              <p>
                Siz de YouTube, Twitch, Mixer ya da Dlive gibi platformlarda yayın yapıyorsanız rahatlıkla bağış alabilirsiniz.
              </p>
            </div>
            <div class="clearfix"></div>
          </div>
          <div class="login-left-item">
            <div class="lole-img"><img :src="require('@/assets/images/l5.png')"/></div>
            <div class="lole-text">
              <h4>Düşük Komisyon</h4>
              <p>
                İnternet sitemiz üzerinden yapılan her işlem için düşük komisyon ücreti ödersiniz.
              </p>
            </div>
            <div class="clearfix"></div>
          </div>
        </div>

      </div>
    </div>
    <div class="clearfix"></div>
  </section>
</template>

<script>
import { VueTelInput } from 'vue-tel-input';
import { required, email, minLength, sameAs } from 'vuelidate/lib/validators';
import authService from "@/services/authService";

export default {
  name: "Normally",
  data() {
    return {
      isValidForm : true,
      isWait: false,
      phoneValid: false,
      phoneClass: "",
      username: "",
      email: "",
      password: "",
      retryPassword: "",
      phone: "",
      name: "",
      surname: "",
      terms: true,
    }
  },
  methods: {
    onInput(formattedNumber, {  valid }) {
      this.phoneValid = valid;
      if(this.phoneValid)
        this.phoneClass = "is-valid"
      else
        this.phoneClass = "is-invalid"
    },
    signUp() {
      this.isWait = true
          /
          authService.userRegister({username: this.username, email: this.email, password: this.password, phone: this.phone, name: this.name, surname: this.surname})
              .then((res) => {
                this.isWait = false
                if(res.state) {
                  this.$swal({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    icon: 'success',
                    title: 'Kaydınız başarıyla oluşturuldu',
                    text: 'yönlendiriliyorsunuz..',
                  });
                  authService.userLogin({email: this.email, password: this.password})
                      .then((res) => {
                        if(res.state)
                          setTimeout(() => {
                            this.$router.push("/main")
                          }, 2000)
                      })
                }
                else {
                  this.$swal({
                    icon: 'error',
                    title: 'Oops...',
                    text: res.message,
                  });
                }
              })
    }

  },
  components: {
    VueTelInput,
  },
  validations: {
    terms: {
      sameAs: sameAs(() => true)
    },
    email: {
      required,
      email,
      uniq(value) {
        if (value === '') return true
        return authService.userCheck({type: 'email', value: value})
      }
    },
    username: {
      required,
      uniq(value) {
        if (value === '') return true
        return authService.userCheck({type: 'username', value: value})
      }
    },
    password: {
      required,
      minLength: minLength(6)
    },
    retryPassword: {
      required,
      minLength: minLength(6),
      sameAs: sameAs('password')
    },
    name: {
      required
    },
    surname: {
      required
    },
    phone: {
      required,
    }
  }
}
</script>

<style scoped>

</style>