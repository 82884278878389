export default {
    siteSettings: state => state.siteSettings,
    homeSlider: state => state.homeSlider,
    gamesSlider: state => state.gamesSlider,
    storeSlider: state => state.storeSlider,
    commissions: state => state.commissions,
    advertisements: state => state.advertisements,
    advertisement_games: state => state.advertisement_games,
    adds: state => state.adds,
    giftCards: state => state.giftCards
}