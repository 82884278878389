<template>
<div>
  <Header></Header>
  <section class="news-page detail">
    <div class="page-title">
      <div class="container">
        <div class="bg-blur"></div>
        <div class="title-prev"><router-link :to="{path: '/main/articles'}"><img :src="require('@/assets/images/title-prev.png')"> Geri Dön</router-link></div>

        <h1 v-if="!loading">{{ data.title }}</h1>
        <h1 v-if="loading">
          <b-skeleton v-if="loading" class="bg-dark" width="50%" animation="throb"></b-skeleton>
        </h1>
      </div>
    </div>
    <div class="news-content" >
      <div class="container">
        <div class="news-list">
          <div class="news-left">
            <div class="detail-top" v-if="!loading">
              <div class="avatar">
                <router-link :to="{path: '/profile/'+data.user.username}">
                  <img :src="data.user.profilePic">
                </router-link>
              </div>
              <div class="avatar-text">
                <label><router-link :to="{path: '/profile/'+data.user.username}">{{ data.user.username }} </router-link> tarafından yazıldı.</label>
                <ul>
                  <li><img :src="require('@/assets/images/nd.png')"> {{ data.date  | formatDate}}</li>
                  <li><img :src="require('@/assets/images/no.png')"> Okuma Süresi: {{ data.stats.text }}</li>
                </ul>
              </div>
              <div class="clearfix"></div>
            </div>
            <div class="detail-top" v-else>
              <b-skeleton type="avatar" class="bg-dark"></b-skeleton>
              <div class="avatar-text">
                <label><b-skeleton animation="throb" width="50%" class="bg-dark"></b-skeleton></label>
              </div>
              <div class="clearfix"></div>
            </div>
            <div class="news-detail" v-if="!loading">
              <img :src="data.image">
              <div class="text" >
                <p v-html="data.content"></p>
                <div class="row">
                  <div class="col-md-6">

                    <ul >
                      <li v-for="(item, index) in tags" :key="index"><router-link :to="{path: '/article-search/'+item}">{{ item }}</router-link></li>
                    </ul>
                  </div>
                  <div class="col-md-6 text-right">
                    <b-button-group>


                    </b-button-group>
                  </div>
                </div>
              </div>
            </div>
          </div >
          <div class="news-right">
            <h2>ÇOK OKUNANLAR</h2>
            <div class="best">
              <div v-for="(item, index) in top5Data" :key="index">
                <router-link :to="{path: '/article/'+item.id}" class="item">
                  <span class="left">{{index+1}}.</span>
                  <span class="center">{{item.title}}</span>
                  <span class="right"><img :src="item.image" /></span>
                </router-link>
              </div>

            </div>
            <div class="banner">
              <Adds container="article_detail_one"></Adds>
            </div>
            <div class="sponsor-right">
              <label>SPONSORLU</label>
              <Adds container="article_detail_two"></Adds>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
    <Adds container="article_detail_three"></Adds>

  </section>
  <Footer></Footer>
</div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import articlesServices from "@/services/articlesServices";
import Adds from "@/components/Adds/Adds";
export default {
  name: "ArticleDetail",
  data() {
    return {
      data: { title : ''},
      loading: true,
      top5Data: [],
      top5loading: true,
      id: this.$route.params.id,
      timeSecond: 0,
      tags: []
    }
  },
  components: {
    Header, Footer, Adds,
  },
  methods: {
    timer() {
      this.time = setInterval(() => {
        this.timeSecond ++
      }, 1000)
    },
    getPreview() {
      articlesServices.getArticlesDetail({id: this.id})
      .then((res) =>{
        if(res.state) {
          this.loading = false
          this.data = res.data
          this.timer()
        }else{
          this.$router.push('/news')
        }
      })
    },
    getTop5(){
      articlesServices.articlesTop5()
          .then((rest) => {
            this.top5loading = false
            if(rest.state){
              this.top5Data = rest.data
            }
          })
    },

  },
  created() {
    this.getPreview();
    this.getTop5()
  },
  watch: {
    timeSecond: function () {
      //this.$store.getters['siteInfo/commissions'].article_read_seconds
      let articleReadingSecond = this.data.stats.time / 1000
      if(articleReadingSecond >= this.$store.getters['siteInfo/commissions'].article_read_seconds){
        if(this.timeSecond >= this.$store.getters['siteInfo/commissions'].article_read_seconds){
          articlesServices.readingArticle({id: this.id})
          .then(() => {
            clearInterval(this.time);
          })
        }
      }else{
        clearInterval(this.time);
      }
    },
    $route(to, from) {
      if(to !== from){ location.reload(); }
    },
    loading: function () {
      if (!this.loading) {
        this.tags  = this.data.tags.split(',')
      }
    }

  },
  metaInfo() {
    return {
      title: this.data.title ,
      meta: [
        {name: 'description', content: this.data.title },
        {name: 'keywords', content: this.data.title },
        {charset: 'utf-8'}
      ]
    }
  },
  beforeRouteLeave (to, from, next) {
    this.timeSecond = 0
    if(this.time > 0)
      clearInterval(this.time);
    next();
  },


}
</script>

<style scoped>
.detail {
  background: #130E17;
}
.title-prev {
  position: absolute;
  top: -30px;
}

</style>