<template>
  <b-modal id="password"  modal-class=" message-modal"  hide-footer hide-header >
      <div class="balmod-title">
        ŞİFREMİ DEĞİŞTİR
      </div>
    <div class="item">
      <h4>Mevcut Şifreniz</h4>
      <b-input v-model="password" type="password" placeholder="Mevcut şifreniz"></b-input>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="item">
          <h4>Yeni Şifre</h4>
          <b-input v-model="password_new" type="password" placeholder="Yeni şifre"></b-input>
        </div>
      </div>
      <div class="col-md-6">
        <div class="item">
          <h4>Yeni Şifre (Tekrar)</h4>
          <b-input v-model="password_newa" type="password" placeholder="Yeni şifre (Tekrar)"></b-input>
        </div>
      </div>
    </div>

      <div class="item text-right">
        <button class="btn-hover" @click="sendMessage" v-if="!loading">
                        <span>
                        <img :src="require('@/assets/images/paper.png')"> Gönder
                        </span>
        </button>
        <button class="btn-hover" v-if="loading">
          <span>  <i class="fas fa-spinner fa-spin"></i></span>
        </button>
      </div>
  </b-modal>
</template>

<script>
import authService from "@/services/authService";

export default {
  name: "changePassword",
  data() {
    return {
      loading: false,
      password: '',
      password_new: '',
      password_newa: '',
    }
  },
  methods: {
    clientAnswer(message) {
      this.$swal({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        icon: message.icon,
        title: '',
        text: message.message,
      });
    },
    sendMessage() {
      this.loading = true
      authService.changePassword({password: this.password, password_new: this.password_new, password_newa: this.password_newa})
      .then((res) => {
        this.loading = false
        if(res.state) {
          this.clientAnswer({icon: 'success', message: 'Şifreniz başarıyla değiştirildi.'})
          this.password = ''
          this.password_new = ''
          this.password_newa = ''
          this.$bvModal.hide('password')
        }
        else
          this.clientAnswer({icon: 'error', message: res.message})
      })
    }
  },
}
</script>

<style scoped>

</style>