<template>
<div>
  <Header></Header>
  <section class="games-page">
    <div class="page-title">
      <div class="container">
        <div class="bg-blur"></div>
        <h1>Mağazalar
          <div class="title-search">
            <div class="input-group mb-3">
              <input type="text" v-model="search" @keyup.enter="getSearch()"  class="form-control" placeholder="Oyun adı giriniz"  aria-describedby="button-addon2">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary" type="button" id="button-addon2"><img :src="require('@/assets/images/title-search.png')"/></button>
              </div>
            </div>
          </div>
        </h1>
        <div class="to-sort">
          <div class="dropdown">
            <b-navbar class="">
              <b-navbar-nav>

                <!-- Navbar dropdowns -->
                <b-nav-item-dropdown text="SIRALA" right toggle-class="btn btn-secondary">
                  <b-dropdown-item @click="sortByTitle()">A'dan Z'ye</b-dropdown-item>
                  <b-dropdown-item @click="sortByNews()">Önce En Yeniler</b-dropdown-item>
                </b-nav-item-dropdown>

              </b-navbar-nav>
            </b-navbar>
          </div>
        </div>
      </div>
    </div>
    <div class="malls-content">
      <div class="container">
        <div class="malls-sidebar">
          <div class="setting-active nav-sidebar">
            Kategoriler
            <i class="fa fa-angle-right hidden-xs"></i> <i class="fa fa-angle-down d-lg-none"></i>
          </div>
          <ul class="nav-content">
            <li :class="{'active': !category_id}"><a @click="setCategory()"><img :src="require('@/assets/images/ar.png')"/> Tüm Oyunlar</a></li>
            <li v-for="(category,index ) in categories" :key="index" :class="{'active': category_id == category.id}">
              <a @click="setCategory(category.id)"><img :src="require('@/assets/images/ar.png')"/> {{ category.title }} </a>
            </li>
          </ul>
        </div>
        <div class="malls-right" id="results">
          <div class="row-fluid" >
            <b-alert show variant="warning" v-if="stores.length == 0 && !loading"> Kayıtlı mağaza bulunamadı. </b-alert>
            <div v-if="loading">
              <div class="col-md-4" v-for="(index) in 6" :key="index">
                <div class="item">
                    <a>
                      <b-skeleton-img animation="throb"></b-skeleton-img>
                      <strong><b-skeleton animation="throb" class="bg-dark "></b-skeleton></strong>
                    </a>
                </div>
              </div>
            </div>
            <div v-if="!loading">
              <div class="col-md-4" v-for="(store,index) in stores" :key="index">
                <div class="item">
                  <router-link :to="{path: 'store/'+store.link}">
                    <a>
                      <img :src="store.image" />
                      <strong>{{ store.title }}</strong>
                    </a>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div class="clearfix"></div>
          <div class="more-bottom" id="moreButton" >
            <div class="game-more" v-if="page < lastPage && stores.length > 0">
              <div class="more-btn text-white" @click="getMore"><a>DAHA FAZLA</a></div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="clearfix"></div>
  </section>
  <Footer></Footer>
</div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import gameService from "@/services/gameService";
import { mapGetters } from "vuex"
export default {
  name: "Malls",
  data() {
    return {
      stores: [],
      categories: [],
      page: 1,
      lastPage: 1,
      search: "",
      category_id: "",
      loading: true
    }
  },
    computed: {
    ...mapGetters({
      siteSettings: 'siteInfo/siteSettings'
    })
  },
  components:{
    Header,
    Footer
  },
  methods: {
    setCategory(id) {
      this.category_id = id
      this.loading = true
      gameService.getStores({page: 1, search: this.search, sort: '',category_id: this.category_id})
          .then((response) => {
            this.loading = false
            this.stores = response.games
            this.page = response.page
            this.lastPage = response.lastPage
          })
    },
    sortByNews() {
      this.loading = true
      gameService.getStores({page: 1, search: this.search, sort: 'news',category_id: this.category_id})
          .then((response) => {
            this.loading = false
            this.stores = response.games
            this.page = response.page
            this.lastPage = response.lastPage
          })
    },
    getSearch() {
      this.loading = false
      gameService.getStores({page: 1, search: this.search, category_id: this.category_id})
          .then((response) => {
            this.stores = response.games
            this.page = response.page
            this.lastPage = response.lastPage
          })
    },
    sortByTitle() {
      this.loading = true
      gameService.getStores({page: 1, search: this.search, sort: '',category_id: this.category_id})
          .then((response) => {
            this.loading = false
            this.stores = response.games
            this.page = response.page
            this.lastPage = response.lastPage
          })
    },
    getStores() {
      this.loading = true
      gameService.getStores({page: this.page})
          .then((response) => {
            this.loading = false
            this.stores = response.games
            this.categories = response.categories
            this.page = response.page
            this.lastPage = response.lastPage
          })
    },
    getMore() {
      this.loading = false
      this.page++
      gameService.getStores({page: this.page, search: this.search, category_id: this.category_id})
          .then((response) => {
            this.stores.push(...response.games)
            this.page = response.page
            this.lastPage = response.lastPage
          })
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getStores()
    })
  },
  watch:
      {
        stores: function () {
          if (this.page < this.lastPage)
            setTimeout(function() {document.getElementById('moreButton').scrollIntoView();}, 1000);
                    }
      },
  metaInfo() {
    return {
      title: "Mağazalar - " + this.siteSettings[0].title,
      meta: [
        {name: 'description', content: "Mağazalar - " + this.siteSettings[0].description},
        {name: 'keywords', content: "Mağazalar - " + this.siteSettings[0].keywords},
        {charset: 'utf-8'}
      ]
    }
  },

}
</script>

<style scoped>
/deep/ body {
  background: #130e17;
  font-family: Montserrat, sans-serif;
}
 .games-page {
  background:#130E17;
}
.form-control, .form-control:focus, .form-control:hover {
  width: 1%;
}
.games-page .title-search {
  width: 185px;
  height: 40px;
  position: absolute;
  left: 300px;
  top: 30px;
}
.page-title h1 {
  font-size: 40px;
}
.game-more  {
  background: #ccc0;
  box-shadow: 0 -23px 25px #ccc0;
  position: absolute;
  z-index: 3;
  width: 100%;
  margin-top: -20px;
  height: 0px;
}
.to-sort .dropdown {
  position: absolute;
  right: 0;
  top: -40px;
}
.malls-sidebar ul li {
  cursor: pointer;
  position: relative;
  text-decoration: none;
  color: #ffffff80;
  font-size: 16px;
  font-weight: 300;
  padding: 0 12px;
  line-height: 51px;
  width: 100%;
  display: inline-block;
}
</style>