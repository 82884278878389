<template>
<div>

  <b-navbar toggleable >
    <b-navbar-toggle target="main" style="width: 100%">
      <ul class="nav-content text-white">
        <li :class="{'active' : data == 'main'}">
          <router-link to="/main">
            Hesabım
            <i class="fas fa-user"/>
          </router-link>
        </li>
      </ul>
    </b-navbar-toggle>
    <b-navbar-toggle target="main" style="width: 100%">
      <ul class="nav-content text-white">
        <li >
          <a :href="'https://merchant.kasagame.com/#/?token='+token" target="_blank">
            Mağaza Paneli
            <i class="fas fa-shopping-basket"/>
          </a>
        </li>
      </ul>
    </b-navbar-toggle>
    <b-navbar-toggle target="messages" style="width: 100%">
      <ul class="nav-content text-white">
        <li >
          <router-link to="/main/messages">
            Mesajlarım
            <i class="fal fa-envelope text-danger Blink" v-if="$store.getters['User/user'].statics.sendingNewMessagesCount > 0 || $store.getters['User/user'].statics.newMessagesCount > 0"></i>
            <i class="fal fa-envelope" v-else/>
          </router-link>
        </li>
      </ul>
    </b-navbar-toggle>

    <b-navbar-toggle target="balance" style="width: 100%">
      <ul class="nav-content text-white">
        <li :class="{'active' : data == 'balance' || data== 'withdraw' || data=='balancelogs'}"><a>Bakiye işlemleri <i class="fas fa-wallet"/></a></li>
      </ul>
    </b-navbar-toggle>

    <b-collapse id="balance" is-nav :visible="data == 'balance' || data== 'withdraw' || data=='balancelogs'">
      <b-navbar-nav class="ml-auto nav-content text-white" style="width: 90%;">
        <b-nav-item to="/main/balance" :class="{'active' : data == 'balance'}">Ödeme Geçmişi <i class="fas fa-arrow-right"/></b-nav-item>
        <b-nav-item to="/main/withdraw" :class="{'active' : data == 'withdraw'}">Para Çekme Geçmişi  <i class="fas fa-arrow-right"/></b-nav-item>
        <b-nav-item to="/main/balancelogs" :class="{'active' : data == 'balancelogs'}">Bakiye Kayıtları  <i class="fas fa-arrow-right"/></b-nav-item>
      </b-navbar-nav>
    </b-collapse>

    <b-navbar-toggle target="orders" style="width: 100%">
      <ul class="nav-content text-white">
        <li :class="{'active' : data == 'orders' || data== 'addsorders' }">
          <a>Satın Aldıklarım
          <i class="fas fa-shopping-cart text-danger Blink" v-if="$store.getters['User/user'].statics.buyAdvertisementCount > 0"/>
          <i class="fas fa-shopping-cart" v-else/>
          </a>
        </li>
      </ul>
    </b-navbar-toggle>
    <b-collapse id="orders" is-nav :visible="data == 'orders' || data== 'addsorders' ">
      <b-navbar-nav class="ml-auto nav-content text-white" style="width: 90%;">
        <b-nav-item to="/main/orders" :class="{'active': data == 'orders'}">Aldığım Ürünler <i class="fas fa-arrow-right"/></b-nav-item>
        <b-nav-item to="/main/addsorders"  :class="{'active': data == 'addsorders'}">
          Aldığım İlanlar
          <i class="fas fa-arrow-right text-danger Blink" v-if="$store.getters['User/user'].statics.buyAdvertisementCount > 0"/>
          <i class="fas fa-arrow-right" v-else/>

        </b-nav-item>
      </b-navbar-nav>
    </b-collapse>

    <b-navbar-toggle target="advertisements" style="width: 100%">
      <ul class="nav-content text-white">
        <li :class="{'active' : data == 'advertisements'}">
          <router-link to="/main/advertisements">
            İlanlarım
            <i class="fal fa-box text-danger Blink" v-if="$store.getters['User/user'].statics.saleAdvertisementCount > 0"/>
            <i class="fal fa-box" v-else/>
          </router-link></li>
      </ul>
    </b-navbar-toggle>
    <b-navbar-toggle target="articles" style="width: 100%">
      <ul class="nav-content text-white">
        <li :class="{'active' : data == 'articles'}">
          <router-link to="/main/articles">
            Makalelerim
            <i class="fal fa-newspaper" />
          </router-link>
        </li>
      </ul>
    </b-navbar-toggle>
    <b-navbar-toggle target="streamers" style="width: 100%">
      <ul class="nav-content text-white">
        <li :class="{'active' : data == 'streaming'}">
          <router-link to="/main/streaming">
            Yayıncı Paneli
            <i class="fas fa-video" />
          </router-link>
        </li>
      </ul>
    </b-navbar-toggle>

  </b-navbar>


  <div class="clearfix"></div>
</div>
</template>

<script>
export default {
  name: "LeftMenu",
  data() {
    return {
      token : this.$store.getters["User/token"]
    }
  },
  props: ['data']
}
</script>

<style scoped>
/deep/.navbar-toggler {
  padding: 0px;
}
/deep/.navbar {
  padding: 0px;
}
.nav-content {
  width: 100%;
}
.navbar-light .navbar-nav .nav-link {
  position: relative;
  text-decoration: none;
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  padding: 0 12px;
  line-height: 51px;
  width: 100%;
  display: inline-block;
}
.navbar-light .navbar-nav .nav-link:hover {
  color: #fff;
}
.navbar-light .navbar-nav .show > .nav-link, .navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .nav-link.active {
  color: #ffffff;
}
</style>