<template>
  <div>
    <Header></Header>

    <section class="live-page">

      <div class="page-title">
        <div class="bg-blur"></div>

        <div class="container">

          <div class="live-title">
            <h2 class="text-white"> ALIŞVERİŞ SEPETİ </h2>
          </div>

        </div>
      </div>
      <div class="live-content">
        <div class="container">
          <div class="content-left">
              <div class="table-responsive">
                <b-alert show variant="info" v-if="!cart.length > 0">
                  Sepetinizde ürün bulunmamaktadır
                </b-alert>
                <table class="table">
                  <tbody>
                  <tr class="cart-item " v-for="(product, index) in cart" :key="index">
                    <th scope="row" class="border-0">
                      <div class="p-2">
                        <img :src="product.image" alt="" width="70" class="img-fluid rounded shadow-sm" />
                        <div class="ml-3 d-inline-block align-middle">
                          <h5 class="mb-0">
                            <router-link :to="{path: '/game/'+product.gameLink}">
                            <a class="text-white d-inline-block align-middle">{{ product.title }}</a>
                            </router-link>
                          </h5>
                          <span class="text-muted font-weight-normal font-italic d-block">{{ product.gameTitle }}</span>
                        </div>
                      </div>
                    </th>
                    <td class="border-0 align-middle"><strong class="text-white">{{ product.price * product.count | currency}} TL</strong></td>
                    <td class="border-0 align-middle">
                      <div class="quantity">
                        <button class="qtyMinus" @click="minus(index)"><i class="fas fa-minus"/></button>
                        <input type="text" class="text-center" :value="product.count">
                        <button class="qtyPlus" @click="plus(index)"><i class="fas fa-plus"/></button>
                      </div>
                    </td>
                    <td class="border-0 align-middle"><a @click="removeItem(product.id)" class="text-white"><i class="fa fa-trash"></i></a></td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>

          <div class="content-right">
            <div class="support-form">
              <h3><i class="far fa-box-usd"/> SİPARİŞ ÖZETİ</h3>
                <div class="item" style="">
                  <label class="text-white">ÖDENECEK TUTAR</label> <br>
                  <div class="btn-group btn-group-toggle" data-toggle="buttons">
                    <label class="btn btn-info active">
                      {{ totalPrice | currency}} TL
                    </label>
                  </div>
                </div>

                <div class="item">
                  <button class="btn btn-dark " v-if="buyBottonWait"> <span>  <i class="fas fa-spinner fa-spin"></i></span> </button>
                  <button v-if="cart.length > 0 && !buyBottonWait"  @click="buyProducts()" class="btn btn-success"><span> <i class="far fa-credit-card"/> Alışverişi Tamamla </span></button>
                </div>
            </div>
            <br>
            <div class="support-form">
              <h3><i class="far fa-barcode"/> KUPON KODU</h3>
              <form>
                <div class="item" style="">
                  <div class="item">
                    <input type="text" placeholder="Hediye Çeki Kodunuzu Girin"/>
                  </div>
                  <div class="item">
                    <button v-if="cart.length > 0" class="btn btn-info"><i class="fas fa-check"/> Kodu Kullan</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import gameService from "@/services/gameService";
import {mapGetters} from 'vuex'

export default {
  name: "Cart",
  data() {
    return {
      products : [],
      totalPrice : 1,
      buyBottonWait: false
    }
  },
  components: {
    Header,
    Footer
  },
  computed: {
    ...mapGetters({
      siteSettings: 'siteInfo/siteSettings',
      cart: 'Cart/cart'
    })
  },
  methods:{
    removeItem(val) {
      this.$store.dispatch('Cart/removeProduct', {id: val})
      this.successMessage("Ürün, Alışveriş sepetinden çıkarıldı.")
      this.getTotalPrice()
    },
    buyProducts() {
      this.buyBottonWait = true
      if (this.$store.getters["User/isLoggedIn"]){
        gameService.buyProducts(this.cart)
        .then((response) =>{
          this.buyBottonWait = false
          if(response.state)
          {
            this.successMessage(response.message)
            this.$store.dispatch('Cart/clearCart')
            setTimeout(() =>{
             this.$router.push({path: '/main/orders'})
            },1500)
          }else {
            if(response.balanceError)
            {
            this.$store.dispatch('depositDefault', {
              depositError: true,
              depositMessage: 'Bakiyeniz yetersiz, lütfen bakiye yükleyin.'
            })
            this.$bvModal.show('deposit')
            }else{
              this.errorMessage(response.message)
            }
          }
        })
      }
      else{
        this.errorMessage("Alışverişi tamamlamak için giriş yapmanız gerekmekte, yönlendiriliyorsunuz..")
        setTimeout(() => {
          this.$router.push({path : '/login?page=/cart'}
        )},3500)
      }
    },
    getTotalPrice() {
        this.totalPrice = this.cart.map(item => item.price * item.count).reduce((total, item) => parseFloat(total) + parseFloat(item), 0)
    },
    successMessage(val) {
      this.$swal({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        icon: 'success',
        title: val,
        text: '',
      });
      this.getTotalPrice()
    },
    errorMessage(val) {
      this.$swal({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        icon: 'error',
        title: val,
        text: '',
      });
      this.getTotalPrice()
    },
    minus(value) {
      let data = this.cart[value]
      if(data.count > 1) {
        data.count--
        this.$store.dispatch('Cart/updateProduct', data)
        this.successMessage("Ürün adeti güncellendi.")
      }
    },
    plus(value) {
      let id = this.cart[value].id
      let count = this.cart[value].count + 1
      gameService.getProduct({id: id})
      .then((res) => {
        if(count > res.product.count) {
          this.errorMessage("Bu ürün için yeterli stok yok.")
        }else {
          this.$store.dispatch('Cart/updateProduct', {
            title: res.product.title,
            count: count,
            price: res.product.price,
            description: res.product.description,
            id: res.product.id,
            image: this.cart[value].image,
            gameLink: this.cart[value].gameLink,
            gameTitle: this.cart[value].gameTitle
          }).then(this.successMessage("Ürün Alışveriş Sepetinize Eklendi!"))
        }
      })
    }
  },
  metaInfo() {
    return {
      title: "Alışveriş Sepeti - " + this.siteSettings[0].title,
      meta: [
        {name: 'description', content: "Alışveriş Sepeti - " + this.siteSettings[0].description},
        {name: 'keywords', content: "Alışveriş Sepeti - " + this.siteSettings[0].keywords},
        {charset: 'utf-8'}
      ]
    }
  },
  created() {
    this.getTotalPrice()
  }
}
</script>

<style scoped>
.live-page {
  background: #130E17;
}
.quickOption {
  width: 100%;
  float: left;
  padding: 0 0;
}

.quickOption ul {
  float: left;
  width: 100%;
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
}
.quickOption ul > li {
  float: left;
  width: 100%;
  padding: 15px 15px;
  font-size: 14px;
  margin-top: 10px;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  color: #ffffff;
}
.img-thumbnail {
  max-width: 73% !important;
  height: auto;
}
.balance-list .item{
  height: 100%;
}
.cart-item {
  width: 100%;
  background: #1e1622;
  padding: 22px;
  border-radius: 8px;
}
.quantity {
  width: 110px;
  height: 31px;
  padding: 0 0;
  background-color: #d9d9d9;
  float: left;
  text-align: center;
  border-radius: 4px;
  overflow: hidden;
}
.quantity button {
  font-size: 14px;
  float: left;
  width: 31%;
  height: 100%;
  background-color: transparent;
  border: none;
  color: #939393;
}
.quantity input {
  padding: 3px 4px;
  float: left;
  width: 38%;
  height: 100%;
  border-radius: 0;
  border: none;
  margin: 0 !important;
  border-top: 1px solid #d9d9d9;
  font-size: 15px;
  border-bottom: 1px solid #d9d9d9;
}
.qtyMinus, .qtyPlus {
  margin: 0 !important;
}
.bg-blur {
  opacity: .15;
}
.page-title {
  margin-top: 50px
}
.bg-blur{
  height: 50px;
}
</style>