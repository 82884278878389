import axios from 'axios'
import { config } from './apiServiceConfig'
import { store } from '@/store'
const apiUrl = config.$api_url

export default  {
    getSiteInfo() {
        axios.defaults.headers.common['x-access-token'] = `${store.getters["User/token"]}`
        return axios
            .get(apiUrl+ '/general')
            .then((response) => {
                store.dispatch('siteInfo/updateSiteSettings', response.data.siteSettings)
                store.dispatch('siteInfo/updateGamesSlider', response.data.games)
                store.dispatch('siteInfo/updateHomeSlider', response.data.homeSlider)
                store.dispatch('siteInfo/updateStoreSlider', response.data.stores)
                store.dispatch('siteInfo/updateComissions', response.data.commissions)
                store.dispatch('siteInfo/advertisementGames', response.data.advertisement_games)
                store.dispatch('siteInfo/advertisements', response.data.advertisements)
                store.dispatch('siteInfo/adds', response.data.adds)
                store.dispatch('siteInfo/giftCards', response.data.giftCards)
                store.dispatch('homeLoader', false)
            })
    },
    search(data){
        return axios.post(apiUrl+'/search', data)
            .then(response => response.data)
    },
    pageDetail(data){
        return axios.get(apiUrl+'/page/'+data.link, data)
            .then(response => response.data)
    },

}