<template>
<div>
  <Header></Header>
  <section class="advertisements-page">
    <div class="page-title">
      <div class="container">
        <div class="bg-blur"></div>
        <h1>İlanlar
          <div class="title-search">
            <div class="input-group mb-3">
              <input type="text" v-model="search" @keyup.enter="getSearch()"  class="form-control" placeholder="İlan Ara"  aria-describedby="button-addon2">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary" type="button" id="button-addon2"><img :src="require('@/assets/images/title-search.png')"/></button>
              </div>
            </div>
          </div>
        </h1>
        <div class="to-sort">
          <div class="dropdown">
            <b-navbar class="">
              <b-navbar-nav>

                <!-- Navbar dropdowns -->
                <b-nav-item-dropdown text="SIRALA" right toggle-class="btn btn-secondary">
                  <b-dropdown-item @click="sortByTitle()">A'dan Z'ye</b-dropdown-item>
                  <b-dropdown-item @click="sortByNews()">Önce En Yeniler</b-dropdown-item>
                </b-nav-item-dropdown>

              </b-navbar-nav>
            </b-navbar>
          </div>
        </div>
      </div>
    </div>
    <div class="advertisements-content">
      <div class="container">
        <div class="malls-sidebar">
          <div class="setting-active nav-sidebar">
            Kategoriler
            <i class="fa fa-angle-right hidden-xs"></i> <i class="fa fa-angle-down d-lg-none"></i>
          </div>
          <ul class="nav-content">
            <li :class="{'active': !category_id}"><a @click="setCategory()"><img :src="require('@/assets/images/ar.png')"/> Tüm Oyunlar</a></li>
            <li v-for="(category,index ) in categories" :key="index" :class="{'active': category_id == category.id}">
              <a @click="setCategory(category.id)"><img :src="require('@/assets/images/ar.png')"/> {{ category.title }} </a>
            </li>
          </ul>
        </div>
        <div class="page-right" >
          <div class="row-fluid" >
            <b-alert show variant="warning" v-if="advertisements.length == 0 && !loading"> İlan bulunamadı. </b-alert>
            <div v-if="loading" class="row">
              <div class="col-md-3 item" v-for="(index) in 8" :key="index">
                <a>
                  <figure>
                    <b-skeleton-img animation="throb"></b-skeleton-img>
                  </figure>
                  <strong><b-skeleton animation="throb" class="bg-dark "></b-skeleton></strong>
                  <span><b-skeleton animation="throb" class="bg-dark "></b-skeleton></span>
                </a>
              </div>

            </div>
            <div v-if="!loading" class="row">
              <div class="col-md-3 item" v-for="(advertisements,index) in advertisements" :key="index">
                <router-link :to="{path: '/advertisement/'+advertisements.id}">
                  <figure>
                    <img :src="advertisements.image" />
                    <figcaption>{{ advertisements.price | currency}} TL</figcaption>
                  </figure>
                  <strong>{{advertisements.gameTitle}}</strong>
                  <span>{{ advertisements.title }}</span>
                </router-link>
              </div>
            </div>

            <div class="clearfix"></div>
            <br><br>
            <div class="more-bottom" id="moreButton" >
              <div class="game-more" v-if="page < lastPage">
                <div class="more-btn text-white" @click="getMore"><a>DAHA FAZLA</a></div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="clearfix"></div>
  </section>
  <Footer></Footer>
</div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import advertisementsService from "@/services/advertisementsService";
import { mapGetters } from "vuex"
export default {
  name: "Advertisements",
  data() {
    return {
      advertisements: [],
      categories: [],
      page: 1,
      lastPage: 1,
      search: "",
      category_id: this.$route.query.id,
      loading: true,
      moreClick: false,
    }
  },
    computed: {
    ...mapGetters({
      siteSettings: 'siteInfo/siteSettings'
    })
  },
  components:{
    Header,
    Footer
  },
  methods: {
    setCategory(id) {
      this.category_id = id
      this.loading = true
      advertisementsService.getAdvertisementsList({page: 1, search: this.search, sort: '',game_id: this.category_id})
          .then((response) => {
            document.getElementById('app').scrollIntoView();
            this.loading = false
            this.advertisements = response.list
            this.page = response.page
            this.lastPage = response.lastPage
          })
    },
    sortByNews() {
      this.loading = true
      advertisementsService.getAdvertisementsList({page: 1, search: this.search, sort: 'news',category_id: this.category_id})
          .then((response) => {
            this.loading = false
            this.advertisements = response.list
            this.page = response.page
            this.lastPage = response.lastPage
          })
    },
    getSearch() {
      this.loading = false
      advertisementsService.getAdvertisementsList({page: 1, search: this.search, category_id: this.category_id})
          .then((response) => {
            this.advertisements = response.list
            this.page = response.page
            this.lastPage = response.lastPage
          })
    },
    sortByTitle() {
      this.loading = true
      advertisementsService.getAdvertisementsList({page: 1, search: this.search, sort: '',category_id: this.category_id})
          .then((response) => {
            this.loading = false
            this.advertisements = response.list
            this.page = response.page
            this.lastPage = response.lastPage
          })
    },
    getAdvertisements() {
      this.loading = true
      advertisementsService.getAdvertisementsList({page: this.page, game_id: this.$route.query.id})
          .then((response) => {
            this.loading = false
            this.advertisements = response.list
            this.page = response.page
            this.lastPage = response.lastPage
          })
    },
    getCategories() {
      advertisementsService.getAdvertisementGames()
          .then((response) => {
            this.categories = response.data
          })
    },
    getMore() {
      this.loading = false
      this.page++
      this.moreClick = true
      advertisementsService.getAdvertisementsList({page: this.page, search: this.search, category_id: this.category_id})
          .then((response) => {
            this.advertisements.push(...response.list)
            this.page = response.page
            this.lastPage = response.lastPage
          })
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getAdvertisements()
      vm.getCategories()
    })
  },
  watch:
      {
        moreClick: function () {
          if (this.moreClick)
              setTimeout(function () {
                document.getElementById('moreButton').scrollIntoView();
                this.moreClick = false
              }, 1000);

        }
      },
  metaInfo() {
    return {
      title: "İlanlar - " + this.siteSettings[0].title,
      meta: [
        {name: 'description', content: "İlanlar - " + this.siteSettings[0].description},
        {name: 'keywords', content: "İlanlar - " + this.siteSettings[0].keywords},
        {charset: 'utf-8'}
      ]
    }
  },

}
</script>

<style scoped>
/deep/ body {
  background: #130e17;
  font-family: Montserrat, sans-serif;
}
 .advertisements-page {
  background:#130E17;
}
.form-control, .form-control:focus, .form-control:hover {
  width: 1%;
}
.advertisements-page .title-search {
  width: 185px;
  height: 40px;
  position: absolute;
  left: 300px;
  top: 30px;
}
.page-title h1 {
  font-size: 40px;
}
.game-more  {
  background: #ccc0;
  box-shadow: 0 -23px 25px #ccc0;
  position: absolute;
  z-index: 3;
  width: 100%;
  padding: 10px;
  margin-top: -80px;
  height: 10px;
}
.to-sort .dropdown {
  position: absolute;
  right: 0;
  top: -40px;
}
.malls-sidebar ul li {
  cursor: pointer;
  position: relative;
  text-decoration: none;
  color: #ffffff80;
  font-size: 16px;
  font-weight: 300;
  padding: 0 12px;
  line-height: 51px;
  width: 100%;
  display: inline-block;
}
</style>