<template>
  <section class="pubpanel">
    <div class="pubpanel-title">
      <div class="container">
        <div class="bg-blur"></div>
        <h1>Makalelerim</h1>
      </div>
    </div>
    <div class="container">
      <div class="sidebar-one">
        <LeftMenu :data="page"></LeftMenu>
      </div>
      <div class="article-right">
        <div class="article-add">
          <router-link to="/main/add-article">
            <button type="button"><img :src="require('@/assets/images/add.png')"> YENİ MAKALE EKLE</button>
          </router-link>
        </div>
        <br>
        <div class="text-center text-white my-2" v-if="isLoading">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Yükleniyor...</strong>
        </div>
        <b-alert variant="warning" show v-if="errorMessage && !isLoading">
          Henüz kayıt yok..
        </b-alert>

        <div class="item"  v-for="(item,index) in logs" :key="index">
          <div class="item-img">
            <router-link :to="{path: '/article/'+item.id}"><img :src="item.image"></router-link>
          </div>
          <div class="item-text">
            <h4><router-link :to="{path: '/article/'+item.id}">{{ item.title }}</router-link></h4>
            <ul>
              <li><img :src="require('@/assets/images/aeye.png')"> {{ item.view }} Görüntülenme</li>
              <li><img :src="require('@/assets/images/adate.png')"> {{ item.date | formatDate}}</li>
              <li><img :src="require('@/assets/images/awallet.png')"> Toplam Kazanç <label>{{ item.total_earning | currency }} TL</label></li>
              <li><img :src="require('@/assets/images/awallet.png')"> Bekleyen Kazanç <label class="bg-warning text-dark"><strong>{{ item.waiting_earning | currency }} TL</strong></label></li>
            </ul>
            <div v-if="item.draft">
              <b-badge variant="primary"> TASLAK </b-badge>
            </div>
            <div v-else>
              <b-badge variant="warning" v-if="!item.status"> Yönetim Onayı Bekleniyor. </b-badge>
              <b-badge variant="success" v-if="item.status"> YAYINDA </b-badge>
            </div>
            <br>
            <label><router-link :to="{path: '/article-preview/'+ item.id}"><i class="fas fa-window-restore"/> ÖN İZLEME</router-link></label> &nbsp;&nbsp;
            <label><router-link :to="{path: '/edit-article/'+item.id}"><img :src="require('@/assets/images/acog.png')"> DÜZENLE</router-link></label>
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
      <div class="clearfix"></div>
    </div>
  </section>
</template>

<script>
import LeftMenu from "@/components/User/LeftMenu";
import authService from "@/services/authService";

export default {
  name: "Articles",
  data() {
    return  {
      page: "articles",
      logs: [],
      isLoading: false,
      lastPage: 1,
      logsPage: 1,
      errorMessage: false
    }
  },
  components: {LeftMenu},
  methods: {
    getLogs() {
      this.isLoading = true
      authService.getArticles({page: this.logsPage})
          .then((res) => {
            this.isLoading = false
            if(res.data.state) {
              this.lastPage = res.data.lastPage
              this.logsPage = res.data.page
              this.logs = res.data.data
              this.errorMessage = false
              if(res.data.data.length === 0)
                this.errorMessage = true
            } else {
              this.errorMessage = true
            }
          })

    },
    getMore() {
      this.logsPage++
      authService.getArticles({page: this.logsPage})
          .then((res) => {
            this.isLoading = false
            if(res.data.state) {
              this.lastPage = res.data.lastPage
              this.logsPage = res.data.page
              this.logs.push(...res.data.data)
              this.errorMessage = false
            } else {
              this.errorMessage = true
              this.logs = []
            }
          })
    }

  },
  async created() {
    this.getLogs()
  },
  watch:
      {
        logs: function () {
          if (this.logsPage < this.lastPage)
            document.getElementById('moreButton').scrollIntoView();
        }
      },
  metaInfo() {
    return {
      title: 'Makalelerim - Hesabım',
    }
  }
}
</script>

<style scoped>

</style>