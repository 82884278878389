import Vue from 'vue'
import App from './App.vue'
import router from "@/router";
import  { store }  from './store'
import VueMeta from 'vue-meta'
import Vuelidate from "vuelidate";
import VueSweetalert2 from 'vue-sweetalert2';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import moment from 'moment'
import vSelect from 'vue-select'
import VueSocketIOExt from 'vue-socket.io-extended';
import { io } from 'socket.io-client';
import { config } from '@/services/apiServiceConfig'
const socketUrl = config.$socket_url
const socket = io(socketUrl);

Vue.config.productionTip = false
Vue.use(VueSocketIOExt, socket);
Vue.use(VueMeta)
Vue.use(Vuelidate)
Vue.use(VueSweetalert2)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.component('v-select', vSelect)

Vue.filter("currency",(value) => {
  return parseFloat(value).toLocaleString(undefined, {minimumFractionDigits : 2})
});

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('DD.MM.YYYY HH:mm')
  }
})

// styles
require('./assets/css/bootstrap-select.css')
require('./assets/css/style.css')
require('./assets/css/fontawesome.min.css')
require('./assets/css/swiper.css')

import 'sweetalert2/dist/sweetalert2.min.css';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-select/dist/vue-select.css';

new Vue({

  render: h => h(App),
  router,
  store
}).$mount('#app')
