<template>
<div>
  <section class="ads-step">
    <div class="page-title">
      <div class="container">
        <div class="bg-blur"></div>
        <div class="title-prev"><router-link to="/main/advertisements"><img :src="require('@/assets/images/title-prev.png')"/> Geri Dön</router-link></div>
        <h1>İlan Ekle</h1>
      </div>
    </div>

    <div class="container mt50">
      <div class="row mobile">
        <div class="col-md-3 left">
          <ul class="step">
            <li @click="getStep1" :class="{'active': step_1}"><a><i class="fa fa-circle"></i> <span>Oyun Bilgileri</span></a></li>
            <li :class="{'active': step_2}" @click="getStep2Form"><a ><i class="fa fa-circle"></i>  <span>İlan Bilgileri</span></a></li>
            <li :class="{'active': step_3}"><a ><i class="fa fa-circle"></i>  <span>Yayınlandı</span></a></li>
          </ul>
        </div>
        <div class="col-md-6 center bg tab-content">
          <div class="tab-pane tab1 active" v-if="show=='step_1'">
            <div class="item">
              <label>İlan Eklemek İSTEDİĞİNİZ Oyunu SEÇİNİZ </label>
              <v-select
                  :options="games"
                  v-model.lazy="$v.selectedGame.$model"
              />
              <div  v-if="!selectedGame.code"  class="text-danger">
                <small>Bu alan zorunludur. </small>
              </div>

            </div>
            <div class="item">
              <label>Satış Türünü SEÇİNİZ</label>
              <v-select
                  v-model="$v.saleType.$model"
                  :options="[{label:'İtem Satışı',code:'1'},{label:'Hesap (Account) Satışı',code:'0'}]"
              />
              <div  v-if="!$v.saleType.required"  class="text-danger">
                <small>Bu alan zorunludur.</small>
              </div>

            </div>
            <div class="item" v-if="$v.selectedGame.$model.category_id == '2'">
              <div class="row item">
                <h4>DİĞER ÖZELLİKLER</h4>
                <div class="col-md-3">
                  <div class="custom-control custom-checkbox">
                    <b-form-checkbox
                        id="reserve"
                        v-model="reserve"
                        name="reserve"
                        value="true"
                        unchecked-value="false"
                    >
                      Reserve
                    </b-form-checkbox>

                  </div>
                </div>
                <div class="col-md-3">
                  <div class="custom-control custom-checkbox">
                    <b-form-checkbox
                        id="dual"
                        v-model="dual"
                        name="dual"
                        value="true"
                        unchecked-value="false"
                    >
                      Dual
                    </b-form-checkbox>

                  </div>
                </div>

                <div class="col-md-3">
                  <div class="custom-control custom-checkbox">
                    <b-form-checkbox
                        id="unique"
                        v-model="unique"
                        name="unique"
                        value="true"
                        unchecked-value="false"
                    >
                      Unique
                    </b-form-checkbox>

                  </div>
                </div>
                <div class="col-md-3">
                  <div class="custom-control custom-checkbox">
                    <b-form-checkbox
                        id="set"
                        v-model="set"
                        name="set"
                        value="true"
                        unchecked-value="false"
                    >
                      Set
                    </b-form-checkbox>

                  </div>
                </div>



              </div>
            </div>

            <button v-if="!$v.saleType.required || !selectedGame.code" type="button" class="btn add-btn btn-dark" disabled ><span><img :src="require('@/assets/images/lr.png')"/> SONRAKİ ADIM</span></button>
            <button v-else type="button" class="add-btn btn-hover" @click="getStep2()"><span><img :src="require('@/assets/images/lr.png')"/> SONRAKİ ADIM</span></button>


          </div>

          <div class="tab-pane tab3 active"  v-else-if="show=='step_2'">
            <form class="form" method="post" action="" onsubmit="return false">
              <div class="item">
                <label>İLAN BAŞLIĞI</label>
                <input type="text" class="form-control" v-model.lazy="$v.title.$model"/>
                <div  v-if="!$v.title.required"  class="text-danger">
                  <small>Bu alan zorunludur.</small>
                </div>

              </div>
              <div class="item">
                <label>İLAN AÇIKLAMASI</label>
                <textarea class="form-control"  placeholder="Ürün Açıklamanız" v-model.lazy="$v.description.$model"></textarea>
                <div  v-if="!$v.description.required"  class="text-danger">
                  <small>Bu alan zorunludur.</small>
                </div>

              </div>
              <div class="item" v-if="!is_account">
                <label>ÜRÜN TESLİMAT BİLGİSİ:</label>
                <textarea class="form-control"  placeholder="Ch, teslim edecek karakter, oyun içindeki bölge vs" v-model.lazy="$v.itemDescription.$model"></textarea>
                <div  v-if="!$v.itemDescription.required"  class="text-danger">
                  <small>Teslim edecek karakter, ch , yer vb. oyun içi bilgiler, Bu alan zorunludur.</small>
                </div>

              </div>
              <div class="item">
                <label>RESİM YÜKLE</label>
                <div class="row">
                  <div class="col-md-6">

                    <label for="file" class="custom-file-upload file-type" v-if="!imageSelected">
                      Gözat <i class="fal fa-cloud-upload"></i>
                    </label>
                    <input type="file" multiple id="file" accept="image/jpeg, image/png, image/gif" v-on:change="onFileChange" v-if="!imageSelected"/>
                    <div class="text-success" v-if="imageSelected"> <small>{{files.length}} adet görsel seçildi.</small> </div>
                    <div  v-if="imageLenghtError"  class="text-danger">
                      <small>En fazla 4 adet görsel yükleyebilirsiniz.</small>
                    </div>

                  </div>
                  <div class="col-md-6">
                    <span style="display:block;color:#FFFFFF;font-size:13px;line-height: 17px;">Görsel belirtmek ilanınızın daha çok incelenmesine yönelik fayda sağlar.</span>
                  </div>
                </div>
                <div class="photos row">

                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="item">
                    <label>FİYAT</label>
                    <div class="addon">
                      <input  class="form-control" v-model="$v.price.$model" />
                      <i class="fa fa-lira-sign"></i>
                    </div>
                    <div  v-if="!$v.price.required"  class="text-danger">
                      <small>Bu alan zorunludur.</small>
                    </div>
                    <div  class="text-danger" v-if="!$v.price.decimal">
                      <small>Yanlış format girdiniz, lütfen kontrol ediniz.</small>
                    </div>

                  </div>
                </div>
                <p class="text-white"></p>
                <div class="col-md-6">
                  <div class="item">
                    <label>KAZANCINIZ</label>
                    <div class="addon">
                      <input type="text" class="form-control" disabled v-model="sum" />
                      <i class="fa fa-lira-sign"></i>
                    </div>
                  </div>
                </div>
                <span class="text-grey"><img :src="require('@/assets/images/inf1x.png')"> İlan verdiğiniz kategoride komisyon oranı <strong> </strong>% {{ $store.getters["siteInfo/commissions"].advertisements }} olarak satış tutarından düşürülecektir.</span>
              </div><br/>
              <div class="item" style="margin-bottom:35px;">
                <div class="membership custom-control custom-checkbox full">
                  <input type="checkbox" class="custom-control-input" id="radio" required v-model.lazy="$v.terms.$model"
                         :class="{'is-invalid' : !$v.terms.$model }"/>
                  <label class="custom-control-label" for="radio">
                    <a href="">İlan kurallarını </a> okudum, kabul ediyorum. {{advertisement_id}}
                  </label>
                </div>
              </div>

                <div class="row item">
                <div class="col-md-6">
                </div>
                  <div class="col-md-6" v-if="!isWait">
                    <button  class="add-btn btn btn-dark no-margin" disabled v-if="$v.$invalid || advertisement_id"><span><img :src="require('@/assets/images/lr.png')"/> İLANI GÖNDER</span></button>
                    <button @click="createAdvertisement" class="add-btn btn-hover no-margin" v-if="!$v.$invalid && !advertisement_id"><span><img :src="require('@/assets/images/lr.png')"/> İLANI GÖNDER</span></button>
                  </div>
                  <div class="col-md-6" v-if="isWait">
                    <button class="add-btn btn-hover no-margin" > <span>  <i class="fas fa-spinner fa-spin"></i></span> </button>
                  </div>
              </div>

            </form>
          </div>
          <div class="tab-pane tab4 active"  v-else-if="show=='step_3'">
            <div class="step-last">
              <img :src="require('@/assets/images/step-tick.png')"/>
              <p>
                İlanınız başarıyla gönderildi.
              </p>
              <p>
                Onay işlemi tamamlandıktan sonra yayınlanacaktır.
              </p>
            </div>
            <div class="step-last-bottom">
              <p>
                İlanına doping yaparak daha hızlı satılmasını ister misin?
              </p>
              <p>
                Aşağıda tekliflerimizi görebilir, en uygun doping seçeneklerini satın alabilirsiniz.
              </p>
            </div>
            <div class="step-last-list">
              <h4>DOPİNG ÖZELLİKLERİ</h4>
              <div class="item">
                <div class="custom-control custom-checkbox">
                  <input type="checkbox" class="custom-control-input" id="a1" v-model="doping_homepage">
                  <label class="custom-control-label" for="a1">
                    Anasayfada göster ({{ $store.getters["siteInfo/commissions"].doping_homepage | currency }} TL)
                  </label>
                </div>


              </div>
              <div class="item">
                <div class="custom-control custom-checkbox">
                  <input type="checkbox" class="custom-control-input" id="a2" v-model="doping_boldtitle">
                  <label class="custom-control-label" for="a2">
                    Başlığı kalın yap ({{ $store.getters["siteInfo/commissions"].doping_boldtitle | currency }} TL)
                  </label>
                </div>


              </div>
              <div class="item">
                <div class="custom-control custom-checkbox">
                  <input type="checkbox" class="custom-control-input" id="a3" v-model="doping_colorframe">
                  <label class="custom-control-label" for="a3">
                    Çerçeveyi renklendir ({{ $store.getters["siteInfo/commissions"].doping_colorframe | currency }} TL)
                  </label>
                </div>


              </div>
              <div class="item">
                <div class="custom-control custom-checkbox">
                  <input type="checkbox" class="custom-control-input" id="a4" v-model="doping_adds">
                  <label class="custom-control-label" for="a4">
                    Reklam alanlarında göster ({{ $store.getters["siteInfo/commissions"].doping_adds | currency }} TL)
                  </label>
                </div>

              </div>
              <div class="last-step-footer">
                <div class="col-md-7">
                  <label>Toplam Tutar: <span>{{ totalDopingPrice | currency}} TL</span></label>
                </div>
                <div class="col-md-5">
                  <button @click="getDoping" class="add-btn btn-hover" v-if="!waitingDoping"><span><img :src="require('@/assets/images/lr.png')"/> DOPİNG YAP</span></button>
                  <button class="add-btn btn-hover no-margin" v-if="waitingDoping"> <span>  <i class="fas fa-spinner fa-spin"></i></span> </button>


                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 right">
          <div class="info">
            <h6><img :src="require('@/assets/images/inf-icon.png')"/> İLAN SİSTEMİ NASIL ÇALIŞIR ?</h6>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris sagittis tristique pretium. Ut lectus velit, vulputate sit amet lectus eget, pretium bibendum felis. Donec sagittis tellus sit
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
</template>

<script>
import advertisementsService from "@/services/advertisementsService";
import { required, decimal, sameAs} from 'vuelidate/lib/validators';

export default {
  name: "addAdvertisements",
  data() {
    return {
      games: [],
      selectedGame : {
        category_id : 0
      },
      saleType: null,
      imageLenghtError: false,
      is_account: false,
      step_1 : true,
      step_2: false,
      step_3: false,
      step_4: false,
      show: 'step_1',
      reserve: false,
      unique: false,
      dual: false,
      set: false,
      files: [],
      price: null,
      sum: null,
      title: "",
      description: "",
      imageSelected: false,
      terms: false,
      isWait: false,
      advertisement_id: null,
      doping_homepage: false,
      doping_adds: false,
      doping_colorframe: false,
      doping_boldtitle: false,
      totalDopingPrice: 0,
      waitingDoping: false,
      itemDescription: ""

    }
  },
  methods: {
    createAdvertisement() {
      this.isWait = true
      advertisementsService.createAdvertisement({data:{set: this.set, dual:this.dual, unique:this.unique, reserve:this.reserve, game_id: this.selectedGame.code, is_account: this.is_account,title: this.title,description: this.description, price: this.price, itemDescription: this.itemDescription}, files: this.files})
      .then((res) => {
        this.isWait = false
        if(res.state)
        {
          this.step_1 = false
          this.step_2 = false
          this.step_3 = true
          this.show = 'step_3'
          this.advertisement_id = res.id
          this.successMessage(res.message)
        }
        else{
          this.errorMessage(res.message)
        }
      })
    },
    getGames() {
      advertisementsService.getGame()
      .then((res => {
        if(res.state) {
          this.games = res.games
        }
      }))
    },
    getDoping() {
      this.waitingDoping = true
      advertisementsService.getDoping( {advertisement_id: this.advertisement_id, doping_homepage: this.doping_homepage, doping_adds: this.doping_adds, doping_colorframe: this.doping_colorframe, doping_boldtitle: this.doping_boldtitle})
          .then((response) => {
            this.waitingDoping = false
            if(response.state)
            {
              this.successMessage(response.message)
              setTimeout(() =>{
                this.$router.push({path: '/main/advertisements'})
              },1500)
            }else {
              if(response.balanceError)
              {
                this.$store.dispatch('depositDefault', {
                  depositError: true,
                  depositMessage: 'Bakiyeniz yetersiz, lütfen bakiye yükleyin.'
                })
                this.$bvModal.show('deposit')
              }else{
                this.errorMessage(response.message)
              }
            }
          })
    },
    getStep1() {
    this.step_1= true
    this.show ='step_1'
    },
    getStep2() {
      this.step_2 = true
      this.show = 'step_2'
      if(this.is_account) {
        this.itemDescription = 'Hesap Satışı'
      }
    },
    getStep2Form() {
      if(!this.$v.saleType.required || !this.selectedGame.code) {
        this.show = 'step_1'
        this.step_2 = false
        this.step_3 = false
      }else {
        this.show = 'step_2'
        this.step_2 = true
      }
    },
    errorMessage(message) {
      this.$swal({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        icon: 'error',
        title: 'Hata!',
        text: message,
      });
    },
    successMessage(message) {
      this.$swal({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        icon: 'success',
        title: 'Tebrikler!',
        text: message,
      });

    },
    onFileChange(e) {
      if (e.target.files.length > 4) {
        this.imageLenghtError = true
        this.files = null
      } else {
        let files = e.target.files || e.dataTransfer.files;
        if (!files.length)
          return;
        this.files = files
        this.imageLenghtError = false
        this.imageSelected = true
      }
    }
  },
  validations: {
    saleType: {
      required,
    },
    selectedGame: {
      required,
    },
    price: {
      required,
      decimal
    },
    title: {
      required
    },
    description: {
      required
    },
    terms: {
      sameAs: sameAs(() => true)
    },
    itemDescription: {
      required
    }
  },
  watch: {
    saleType: function () {
      if (this.saleType.code == "0") {
        this.is_account = true
      } else {
        this.is_account = false
      }
    },
    selectedGame: function () {
      if(this.selectedGame == null) {
        this.selectedGame = {
          category_id: 0
        }
      }
    },
    price: function () {
      this.sum = this.price - (parseFloat(this.price) * parseFloat(this.$store.getters["siteInfo/commissions"].advertisements) / 100)
    },
    sum: function () {
      if(!this.sum){
        this.sum = 0
      }
    },
    doping_homepage: function () {
      if(this.doping_homepage) {
        this.totalDopingPrice = parseFloat(this.totalDopingPrice) + parseFloat(this.$store.getters["siteInfo/commissions"].doping_homepage)
      }else {
        this.totalDopingPrice = parseFloat(this.totalDopingPrice) - parseFloat(this.$store.getters["siteInfo/commissions"].doping_homepage)
      }
    },
    doping_adds: function () {
      if(this.doping_adds) {
        this.totalDopingPrice = parseFloat(this.totalDopingPrice) + parseFloat(this.$store.getters["siteInfo/commissions"].doping_adds)
      }else {
        this.totalDopingPrice = parseFloat(this.totalDopingPrice) - parseFloat(this.$store.getters["siteInfo/commissions"].doping_adds)
      }
    },
    doping_boldtitle: function () {
      if(this.doping_boldtitle) {
        this.totalDopingPrice = parseFloat(this.totalDopingPrice) + parseFloat(this.$store.getters["siteInfo/commissions"].doping_boldtitle)
      }else {
        this.totalDopingPrice = parseFloat(this.totalDopingPrice) - parseFloat(this.$store.getters["siteInfo/commissions"].doping_boldtitle)
      }
    },
    doping_colorframe: function () {
      if(this.doping_colorframe) {
        this.totalDopingPrice = parseFloat(this.totalDopingPrice) + parseFloat(this.$store.getters["siteInfo/commissions"].doping_colorframe)
      }else {
        this.totalDopingPrice = parseFloat(this.totalDopingPrice) - parseFloat(this.$store.getters["siteInfo/commissions"].doping_colorframe)
      }
    },
    totalDopingPrice: function () {
      if(this.totalDopingPrice < 0) {
        //this.totalDopingPrice = 0
      }
    }
  },
  async created() {
    this.getGames()
  },
  metaInfo() {
    return {
      title: 'İlan Ekle - Hesabım',
    }
  }
}
</script>

<style scoped>
input,textarea {
  background: 0 0!important;
  border: 1px solid #fff!important;
  color: #fff!important;
  transition: .5s all!important;
}
</style>