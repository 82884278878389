<template>
<div>
  <Header />
  <section class="publishers-page">
    <div class="page-title">
      <div class="container">
        <div class="bg-blur"></div>
        <h1>Yayıncılar
          <div class="title-search">
            <div class="input-group mb-3">
              <input type="text" class="form-control"  placeholder="Yayıncı adı giriniz" v-model="search" @keyup.enter="getStreamers">
              <div class="input-group-append">
                <button @click="getStreamers" class="btn btn-outline-secondary" type="button" id="button-addon2"><img :src="require('@/assets/images/title-search.png')"/></button>
              </div>
            </div>
          </div>
        </h1>
        <div class="publisher-add"><button class="btn-hover"><span><img :src="require('@/assets/images/padd.png')"/> YAYINCI OL</span></button></div>
        <div class="popup-placement">
          <div class="popup-placement-social-item popup-placement-social-whatsapp">
            <a href="#"><img :src="require('@/assets/images/add-user.png')"/></a>
          </div>
        </div>
        <div class="to-sort">

          <div class="dropdown">
            <b-navbar>
              <b-navbar-nav>

                <!-- Navbar dropdowns -->
                <b-nav-item-dropdown text="SIRALA" right toggle-class="btn btn-secondary">
                  <b-dropdown-item @click="getSort('')">A'dan Z'ye</b-dropdown-item>
                  <b-dropdown-item @click="getSort('news')">Önce En Yeniler</b-dropdown-item>
                </b-nav-item-dropdown>

              </b-navbar-nav>
            </b-navbar>
          </div>
        </div>
      </div>
    </div>

    <div class="publishers-content">
      <div class="container">

        <ul class="publish-filt">
          <li><a :class="{'active' : !platform}" @click="getPlatform('')">Tüm Platformlar</a></li>
          <li><a :class="{'active' : platform === 'twitch'}" @click="getPlatform('twitch')"><img :src="require('@/assets/images/filt1.png')"/></a></li>
          <li><a :class="{'active' : platform === 'youtube'}" @click="getPlatform('youtube')"><img :src="require('@/assets/images/filt2.png')"/></a></li>
        </ul>

        <div class="row" v-if="loading">
          <div class="col-md-3" v-for="item in 12" :key="item">
            <a href="#" class="item ">
              <figure><b-skeleton type="avatar" width="100%" height="100%"></b-skeleton></figure>
              <br>
              <strong><b-skeleton width="100%"  type="dark"></b-skeleton></strong>
            </a>
          </div>
        </div>
        <div class="row" v-else>
          <div class="col-md-3" v-for="(item, index) in streamers" :key="index">
            <router-link :to="{path: '/donate/'+ item.platform_username}" class="item " :class="{'active' : item.is_online}">
              <figure><img :src="item.profile_pic"/></figure>
              <strong>{{ item.platform_username }}</strong>
              <span v-if="item.is_online"><img :src="require('@/assets/images/pi.png')"/> YAYINDA</span>
            </router-link>
          </div>

        </div>
        <div class="clearfix"></div>
        <div class="more-bottom" id="moreButton" >
          <div class="game-more" v-if="page < lastPage && streamers.length > 0">
            <div class="more-btn text-white" @click="getMore"><a>DAHA FAZLA</a></div>
          </div>
        </div>


      </div>
      <div class="publish-banner">
        <Adds container="streamers_one"></Adds>
        <Adds container="streamers_two"></Adds>
      </div>
    </div>
    <div class="clearfix"></div>

  </section>
  <Footer />
</div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import {mapGetters} from "vuex";
import streamService from "@/services/streamService";
import Adds from "@/components/Adds/Adds";

export default {
  name: "Streamers",
  data() {
    return {
      loading: true,
      page: 1,
      lastPage: 1,
      search: "",
      sort: 'news',
      streamers: [],
      platform: ''
    }
  },
  components: {Header,Footer,Adds},
  methods: {
    getPlatform(val) {
      this.platform = val
      this.getStreamers()
    },
    getSort(val) {
      this.sort = val
      this.getStreamers()
    },
    getStreamers() {
      this.loading = true
      streamService.streamers({page: this.page, search: this.search, sort: this.sort, platform: this.platform})
      .then((res) => {
        if(res.state) {
          this.loading = false
          this.lastPage = res.lastPage
          this.page  = res.page
          this.streamers = res.streamers
        }
      })
    },
    getMore() {
      this.page++
      streamService.streamers({page: this.page, search: this.search, sort: this.sort, platform: this.platform})
          .then((response) => {
            this.streamers.push(...response.streamers)
            this.page = response.page
            this.lastPage = response.lastPage
          })
    }
  },
  computed: {
    ...mapGetters({
      siteSettings: 'siteInfo/siteSettings'
    })
  },
  created() {
    this.getStreamers()
  },
  metaInfo() {
    return {
      title: "Yayıncılar - " + this.siteSettings[0].title,
      meta: [
        {name: 'description', content: "Yayıncılar - " + this.siteSettings[0].description},
        {name: 'keywords', content: "Yayıncılar - " + this.siteSettings[0].keywords},
        {charset: 'utf-8'}
      ]
    }
  },
}
</script>
<style scoped>
.publishers-page {
  background:#130E17;
}
.game-more  {
  background: #ccc0;
  box-shadow: 0 -23px 25px #ccc0;
  position: absolute;
  z-index: 3;
  width: 100%;
  margin-top: -20px;
  height: 0px;
}
.to-sort .dropdown {
  position: absolute;
  right: 0;
  top: -40px;
}
.malls-sidebar ul li {
  cursor: pointer;
  position: relative;
  text-decoration: none;
  color: #ffffff80;
  font-size: 16px;
  font-weight: 300;
  padding: 0 12px;
  line-height: 51px;
  width: 100%;
  display: inline-block;
}

</style>