<template>
  <section class="stores">
    <div class="container">
      <h1>Hediye Kartları</h1>
      <div class="row">
        <div class="col-md-3" v-for="(game, index) in games" :key="index">
          <div class="item">
            <router-link :to="{path: 'game/'+game.link}">
              <a>
                <img :src="game.image" />
                <strong>{{game.title}}</strong>
              </a>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="more">
      <router-link to="/gift-cards">DAHA FAZLA</router-link>
    </div>
  </section>

</template>

<script>
export default {
  name: "GiftCards",
  data() {
    return {
      loading: this.$store.getters.homeLoading,
      games: this.$store.getters['siteInfo/giftCards']
    }
  },
  watch: {
    '$store.getters.homeLoading': function() {
      this.loading = this.$store.getters.homeLoading
    }
  }
}
</script>

<style scoped>
.VueCarousel-slide {
  margin-right: 10px;
  max-width: 270px;
}
.VueCarousel-slide img {
  max-width: 270px;
}



</style>