<template>
  <section class="settings">
    <div class="pubpanel-title">
      <div class="container">
        <div class="bg-blur"></div>
        <h1>Hesabım</h1>
      </div>
    </div>
    <div class="container">
      <div class="sidebar-one">
        <LeftMenu :data="page"></LeftMenu>
      </div>
      <div class="settings-content">
        <h3>KULLANICI BİLGİLERİNİZ</h3>
        <div class="row settings-item">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-4">
              </div>
              <div class="col-md-4">
                <b-img v-bind="profilePicSettings" :src="profilePic" rounded="circle" alt="Profil"></b-img>
              </div>
              <div class="col-md-4">
                <b-button @click="chooseFiles()" variant="info" for="fileUpload">
                 Fotoğrafı Güncelle <i class="fas fa-edit"></i>
                </b-button>
                <input  type="file" style="display: none" id="fileUpload" accept="image/jpeg, image/png, image/gif" v-on:change="onFileChange" v-if="!imageSelected"/>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <label>ADINIZ </label>
            <input type="text" v-model="name">
          </div>
          <div class="col-md-6">
            <label>SOYADINIZ</label>
            <input type="text" v-model="surname">
          </div>
        </div>
        <div class="row settings-item">
          <div class="col-md-6">
            <label>E-POSTA ADRESİNİZ</label>
            <input type="email" :value="$store.getters['User/user'].email" disabled>
          </div>
          <div class="col-md-6">
            <label>TELEFON NUMARANIZ</label>
            <input type="text" :value="$store.getters['User/user'].phone" disabled>
          </div>
        </div>
        <div class="row settings-item">
          <div class="col-md-6">
            <label>T.C. KİMLİK NUMARANIZ</label>
            <input type="text" :value="$store.getters['User/user'].identity" disabled>
          </div>
          <div class="col-md-6">
            <label>ADRESİNİZ</label>
            <input type="text" v-model="address">
          </div>
        </div>
        <div class="row settings-item hr">
          <div class="col-md-6">
            <label>ŞİFRENİZ</label>
            <input type="password" value="deneme">
          </div>
          <div class="col-md-6">
            <label class="setlink"><a href="">Şifremi değiştirmek istiyorum.</a></label>
          </div>
        </div>
        <h3>BANKA BİLGİLERİNİZ</h3>
        <div class="row settings-item">
          <div class="col-md-6">
            <label>BANKA ADI</label>
            <input type="text" v-model="bank_name">
          </div>
          <div class="col-md-6">
            <label>İBAN</label>
            <input type="text" v-model="iban">
          </div>
        </div>
        <button class="btn-hover" v-if="!loadingButton" @click="submit"><span>GÜNCELLE</span></button><br>
        <button class="btn-hover" v-if="loadingButton"><span> <i class="fas fa-spinner fa-spin"></i> </span></button><br>

      </div>
      <div class="clearfix"></div>
    </div>
  </section>
</template>

<script>
import LeftMenu from "@/components/User/LeftMenu";
import authService from "@/services/authService";

export default {
  name: "Settings",
  data() {
    return  {
      name: this.$store.getters['User/user'].name,
      surname: this.$store.getters['User/user'].surname,
      address: this.$store.getters['User/user'].address,
      page: "main",
      logs: [],
      isLoading: false,
      lastPage: 1,
      logsPage: 1,
      errorMessage: false,
      loadingButton: false,
      username: '',
      profilePicSettings:   {  width: 75, height: 75, class: 'm1' },
      profilePic: this.$store.getters['User/user'].profilePic,
      point: '',
      imageLenghtError: false,
      imageSelected: false,
      files: [],
      imageLoading: false,
    }
  },
  components: {LeftMenu},
  methods: {
    onFileChange(e) {
      if (e.target.files.length > 1) {
        this.imageLenghtError = true
        this.files = null
      } else {
        let files = e.target.files || e.dataTransfer.files;
        if (!files.length)
          return;
        this.imageLoading = true
        authService.uploadImage({files: files})
            .then((res) => {
              this.imageLoading = false
              if(res.state){
                this.imageLenghtError = false
                this.imageSelected = true
                this.profilePic = res.url
              }else{
                this.$store.dispatch('getConfirmMessage', {show: true, icon: 'error', title: 'Ops!', message: res.message})
              }
            })
      }
    },
    chooseFiles: function() {
      document.getElementById("fileUpload").click()
    },
    submit() {
      this.loadingButton = true
      authService.updateInformation({
        name: this.name,
        surname: this.surname,
        bank_name: this.bank_name,
        address: this.address,
        iban: this.iban,
        profilePic: this.profilePic
      })
      .then((res) => {
        this.loadingButton = false
        if(res.state){
          this.successMessage('Bilgileriniz başarıyla güncellendi.')
        }else{
          this.errorMessages('Hata oluştu, lütfen daha sonra tekrar deneyiniz.')
        }
      })
    },
  successMessage(val) {
    this.$swal({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      icon: 'success',
      title: val,
      text: '',
    });
  },
  errorMessages(val) {
    this.$swal({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      icon: 'error',
      title: val,
      text: '',
    });
  },
  },
  async created() {
    if(!this.bank_name){
      this.bank_name = this.$store.getters["User/user"].bank_details.bank_name
      this.iban = this.$store.getters['User/user'].bank_details.iban
    }
  },
  metaInfo() {
    return {
      title: 'Hesap Bilgilerim',
    }
  }
}
</script>

<style scoped>
/deep/.v-select-toggle {
  background: #0B0A0B;
}
</style>