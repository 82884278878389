import axios from 'axios'
import { config } from './apiServiceConfig'
import {store} from "@/store";
//import {store} from "@/store";
//import { store } from '@/store'
const apiUrl = config.$api_url
axios.defaults.headers.common['x-access-token'] = `${store.getters["User/token"]}`

export default {
    getCategories() {
        return axios.get(apiUrl + '/adds/categories')
            .then(response => response.data)
    },
}