import axios from 'axios'
import { config } from './apiServiceConfig'
import { store } from '@/store'

const apiUrl = config.$api_url
const apiCdnUrl = config.$api_url_cdn

export default  {
    userCheck(data) {
        return axios
            .post(apiUrl+ '/user/usercheck', data)
            .then(response => {
                if (response.data.state == true)
                    return false
                else
                    return true
            })
    },
    userRegister(data) {
            return axios
                .post(apiUrl+'/user/register', data)
                .then(response => response.data)
    },
    getSecretContent () {
        axios.defaults.headers.common['x-access-token'] = `${store.getters["User/token"]}`
        return axios.get(apiUrl + '/user/getToken').then(response => response.data)
    },
    forgotPassword(data) {
      return axios
          .post(apiUrl+'/user/forgotPassword', data)
          .then((response) => {
              return response
          })
    },
    userLogin(data) {
        return axios
            .post(apiUrl+'/user/login', data)
            .then((response) =>{
                if(response.data.state) {

                    store.dispatch('User/userLogin', response.data)
                    store.dispatch('userId', response.data.user.id)
                    return {state:true, message: "Başarıyla giriş yaptınız"}
                }else {
                    return {state:false, message: response.data.message}
                }
            })
    },
    loginWithSocials(data) {
        return axios
            .post(apiUrl+'/user/loginWithSocials', data)
            .then((response) => {
                if(response.data.state) {
                    return response.data
                }else {
                    return {state:false, message: "Doğrulama Başarısız"}
                }
            })
    },
    registerWithSocial(data) {
        return axios
            .post(apiUrl+'/user/registerWithSocials', data)
            .then((response) => {
                return response.data
            })
    },
    getDeposits(data) {
        axios.defaults.headers.common['x-access-token'] = `${store.getters["User/token"]}`
        return axios
            .get(apiUrl+'/user/deposits', {
                params: data
            })
            .then((response) => {
                return response
            })
    },
    logout() {
        axios.defaults.headers.common['x-access-token'] = `${store.getters["User/token"]}`
        return axios
            .get(apiUrl+'/user/logout')
            .then((response) => {
                return response
            })
    },
    changePassword(data) {
        axios.defaults.headers.common['x-access-token'] = `${store.getters["User/token"]}`
        return axios
            .post(apiUrl+'/user/change-password', data)
            .then((response) => {
                return response.data
            })
    },
    getWithdraws(data) {
        axios.defaults.headers.common['x-access-token'] = `${store.getters["User/token"]}`
        return axios
            .get(apiUrl+'/user/withdraws', {
                params: data
            })
            .then((response) => {
                return response
            })
    },
    getBalanceLogs(data) {
        axios.defaults.headers.common['x-access-token'] = `${store.getters["User/token"]}`
        return axios
            .get(apiUrl+'/user/balancelogs', {
                params: data
            })
            .then((response) => {
                return response
            })
    },
    getOrders(data) {

        axios.defaults.headers.common['x-access-token'] = `${store.getters["User/token"]}`
        return axios
            .get(apiUrl+'/user/orders', {
                params: data
            })
            .then((response) => {
                return response
            })
    },
    getAddsOrders(data) {
        axios.defaults.headers.common['x-access-token'] = `${store.getters["User/token"]}`
        return axios
            .get(apiUrl+'/user/adds-orders', {
                params: data
            })
            .then((response) => {
                return response
            })
    },
    getAddsOrderDetail(data) {
        axios.defaults.headers.common['x-access-token'] = `${store.getters["User/token"]}`
        return axios
            .get(apiUrl+'/user/adds-order-detail/'+data.id)
            .then((response) => {
                return response.data
            })
    },
    confirmAddsOrder(data) {
        axios.defaults.headers.common['x-access-token'] = `${store.getters["User/token"]}`
        return axios
            .get(apiUrl+'/user/confirm-buy-advertisement/'+data.id)
            .then((response) => {
                return response.data
            })
    },
    userImageUpload(data) {
        let formData = new FormData();
        formData.append("files", data);
        return axios.post(apiCdnUrl+'/upload',formData,
            {
                headers: {
                    'Content-Type': 'undefined'
                }
            }
        )
            .then(result => {
                return result.data
            })
    },
    createArticle(data) {
        axios.defaults.headers.common['x-access-token'] = `${store.getters["User/token"]}`
        const formData = new FormData();
        for (let i = 0; i < data.files.length; i++) {
            let file = data.files[i];

            formData.append('files', file);
        }

        formData.append('data', JSON.stringify(data.data));

        return axios.post(apiUrl+'/user/add-article',formData,
            {
                headers: {
                    'Content-Type': 'undefined'
                }
            }
        )
            .then(response => response.data)
    },
    getArticles(data) {
        axios.defaults.headers.common['x-access-token'] = `${store.getters["User/token"]}`
        return axios
            .get(apiUrl+'/user/articles', {
                params: data
            })
            .then((response) => {
                return response
            })
    },
    articlePreview(data) {
        return axios.get(apiUrl+'/articles/preview/'+data.id)
            .then(response => response.data)
    },
    editArticle(data) {
        axios.defaults.headers.common['x-access-token'] = `${store.getters["User/token"]}`
        const formData = new FormData();
        for (let i = 0; i < data.files.length; i++) {
            let file = data.files[i];

            formData.append('files', file);
        }

        formData.append('data', JSON.stringify(data.data));

        return axios.post(apiUrl+'/user/edit-article',formData,
            {
                headers: {
                    'Content-Type': 'undefined'
                }
            }
        )
            .then(response => response.data)
    },
    profile(data) {
        return axios.get(apiUrl+'/user/profile/'+data.username)
            .then((response) => {
                return response.data
            })
    },
    createChat(data) {
        axios.defaults.headers.common['x-access-token'] = `${store.getters["User/token"]}`
        return axios.post(apiUrl+'/user/send-message', data)
            .then((res) => {
                return res.data
            })
    },
    getChats() {
        axios.defaults.headers.common['x-access-token'] = `${store.getters["User/token"]}`
        return axios.get(apiUrl+'/user/chats')
            .then((res) => {
                return res.data
            })
    },
    getMessageDetail(data) {
        return axios.get(apiUrl+'/user/chat-messages/'+data.id)
            .then((res) => {
                return res.data
            })
    },
    answerMessage(data) {
        return axios.post(apiUrl+'/user/answer-message', data)
            .then((res) => {
                return res.data
            })
    },
    updateInformation(data) {
        return axios.post(apiUrl+'/user/update', data)
            .then((res) => {
                return res.data
            })
    },
    uploadImage(data) {
        axios.defaults.headers.common['x-access-token'] = `${store.getters["User/token"]}`
        const formData = new FormData();
        for (let i = 0; i < data.files.length; i++) {
            let file = data.files[i];

            formData.append('files', file);
        }

        return axios.post(apiCdnUrl + '/upload', formData)
            .then(response => response.data)
    },

}