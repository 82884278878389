<template>
<div>
  <Header></Header>
  <section class="aboutus">
    <div class="page-title">
      <div class="container">
        <div class="bg-blur"></div>
        <h1>Ödeme Yap</h1>
      </div>
    </div>
    <div class="content">
      <div class="container">
        <b-alert show variant="info">
          <b-badge>
            <i class="fas fa-info"></i>
          </b-badge>
          Lütfen ödeme tamamlanana kadar sayfadan ayrılmayınız, ödemeyi yaptıktan sonra
          <router-link :to="{path: '/main/balance'}"> Hesabım / Ödemeler </router-link> kısmından bakiyenizi kontrol edebilirsiniz.
        </b-alert>

        <b-alert show variant="warning" v-if="!isIFrame">
          Ödeme ekranına yönlendiriliyorsunuz, lütfen bekleyin...
        </b-alert>

        <iframe :src="paymentLink" frameborder="0" style="height:910px;width:100%;bottom:0px" v-if="isIFrame"></iframe>
      </div>
    </div>
  </section>
  <Footer></Footer>
</div>
</template>

<script>
import Header from '@/components/Header';
import Footer from '@/components/Footer';

export default {
  name: "Pay",
  data() {
    return {
      paymentLink : '',
      isIFrame: true
    }
  },
  components: {Header, Footer},
  created() {
    if(!this.$store.getters.payDetail.paymentActive){
      this.$router.push({path: '/main'})
    }else{
      this.paymentLink = this.$store.getters.payDetail.link
      this.isIFrame = this.$store.getters.payDetail.isIframe
      this.$store.dispatch('Pay', {link: '', isIframe: true, paymentActive: false})
      if(!this.isIFrame){
        window.location.href=this.paymentLink
      }
    }
  },
  metaInfo() {
    return {
      title: "Ödeme Yap",
      meta: [
        {name: 'description', content: "Ödeme Yap"},
        {name: 'keywords', content: "Ödeme Yap"},
        {charset: 'utf-8'}
      ]
    }
  }

}
</script>

<style scoped>
.aboutus{
  background: #130E17;
}

</style>