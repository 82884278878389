import axios from 'axios'
import { config } from './apiServiceConfig'
import {store} from "@/store";
//import { store } from '@/store'
axios.defaults.headers.common['x-access-token'] = `${store.getters["User/token"]}`

const apiUrl = config.$api_url

export default {
    getExchange() {
        return axios.get(apiUrl+'/payments/currencies')
            .then(response => response.data)
    },
    getChannels() {
        return axios.get(apiUrl+'/payments/methods')
            .then(response => response.data)
    },
    getPaymentLink(data) {
        return axios.post(apiUrl+'/payments/get-link', data)
            .then(response => response.data)
    },
    withdrawal(data) {
         return axios.post(apiUrl+ '/payments/withdrawal', data)
             .then(response => response.data)
    },
    westernUnion(data){
        return axios.post(apiUrl+'/payments/western-union-form-site', data)
            .then(response => response.data)
    },
    westernInformation(){
        return axios
            .get(apiUrl+'/api/western-union')
            .then((response) => {
                return response.data
            })

    },
    swiftBankDetail(data){
        return axios
            .post(apiUrl+'/api/swift-bank-detail', data)
            .then((response) => {
                return response.data
            })

    },
    sendSwiftForm(data){
        return axios.post(apiUrl+'/payments/swift-request-form', data)
            .then(response => response.data)
    },

}