<template>
  <section class="message-page">
    <div class="page-title">
      <div class="container">
        <div class="bg-blur"></div>
        <h1> Mesajlarım </h1>
      </div>
    </div>
    <div class="message-content">
      <div class="container">
        <div class="sidebar-one">
          <LeftMenu :data="page"></LeftMenu>
        </div>
        <div class="message-right">
          <div class="message-list" v-if="isLoading" >
            <div class="item">
              <div class="images"><b-skeleton type="avatar" variant="dark" animation="throb"/></div>
              <div class="text">
                <h5><b-skeleton variant="dark" animation="throb" /></h5>
                <label><b-skeleton variant="dark" animation="throb" width="5200%"/></label>
              </div>
              <div class="cog dropdown">
                <a href="#" class="dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i class="fa fa-ellipsis-v-alt"></i></a>
                <div class="dropdown-menu" style="">
                  <a class="dropdown-item" href="#">Sil</a>
                  <a class="dropdown-item" href="#">Okundu Olarak İşaretle</a>
                  <a class="dropdown-item" href="#">Arşivle</a>
                </div>
              </div>
              <div class="clearfix"></div>
            </div>
            <div class="messages-list">
              <div class="message incoming">
                <div class="text">
                  <b-skeleton variant="dark" animation="throb" width="70%" />
                  <b-skeleton variant="dark" animation="throb" width="60%"/>
                  <b-skeleton variant="dark" animation="throb" width="60%"/>
                  <b-skeleton variant="dark" animation="throb" width="40%"/>
                </div>
                <div class="date">...</div>
              </div>
              <div class="message outgoing">
                <div class="text">
                  <b-skeleton variant="dark" animation="throb" width="70%" />
                  <b-skeleton variant="dark" animation="throb" width="60%"/>
                  <b-skeleton variant="dark" animation="throb" width="60%"/>
                  <b-skeleton variant="dark" animation="throb" width="40%"/>
                </div>
                <div class="date">...</div>
              </div>
            </div>
            <div class="message-form">
              <label>Mesaja yanıt yaz</label>
              <textarea></textarea>
              <button class="btn-hover">
                            <span>
                                <img :src="require('@/assets/images/paper.png')"> GÖNDER
                            </span>
              </button>
            </div>
          </div>

          <div class="message-list" v-if="!isLoading">
            <div class="item">
              <div class="images" v-if="logs.iam_sender"><img :src="logs.user[0].profilePic"></div>
              <div class="images" v-else><img :src="logs.sender[0].profilePic"></div>
              <div class="text">
                <h5>{{ logs.subject }}</h5>
                <label v-if="logs.iam_sender"><router-link :to="{path : '/profile/'+logs.user[0].username}" class="text-white"> {{ logs.user[0].username }} </router-link></label>
                <label v-else><router-link :to="{path : '/profile/'+logs.sender[0].username}" class="text-white"> {{ logs.sender[0].username }} </router-link></label>
              </div>
              <div class="cog dropdown">
                <a href="#" class="dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i class="fa fa-ellipsis-v-alt"></i></a>
                <div class="dropdown-menu" style="">
                  <a class="dropdown-item" href="#">Sil</a>
                  <a class="dropdown-item" href="#">Okundu Olarak İşaretle</a>
                  <a class="dropdown-item" href="#">Arşivle</a>
                </div>
              </div>
              <div class="clearfix"></div>
            </div>
            <div class="messages-list"  style="height: 500px;overflow-y: auto;">
              <div v-for="(item,index) in logs.messages" :key="index">
                <div class="message" :class="{'incoming': item.user_id === $store.getters['User/user'].id, 'outgoing': item.user_id != $store.getters['User/user'].id}">
                  <div class="text">
                    {{item.message}}
                  </div>
                  <div class="date">{{ item.date | formatDate }}</div>
                </div>
              </div>
            </div>
            <div class="message-form">
              <label>Mesaja yanıt yaz </label>
              <textarea v-model="message"></textarea>
              <button class="btn-hover" v-if="buttonLoading">
                            <span>
                                              <i class="fas fa-spinner fa-spin"></i>
                            </span>
              </button>
              <button class="btn-hover" v-else @click="sendMessage">
                            <span>
                                <img :src="require('@/assets/images/paper.png')"> GÖNDER
                            </span>
              </button>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
    <SendMessage></SendMessage>
  </section>
</template>

<script>
import LeftMenu from "@/components/User/LeftMenu";
import authService from "@/services/authService";
import SendMessage from "@/components/Modals/SendMessage";

export default {
  name: "MessageDetail",
  data() {
    return  {
      page: "messages",
      logs: [],
      isLoading: false,
      lastPage: 1,
      logsPage: 1,
      errorMessage: false,
      buttonLoading : false,
      message: '',
      id: this.$route.params.id
    }
  },
  components: {LeftMenu, SendMessage},
  methods: {
    clientAnswer(message) {
      this.$swal({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        icon: message.icon,
        title: '',
        text: message.message,
      });
    },
    getMessages() {
      this.isLoading = true
      authService.getMessageDetail({id: this.id})
          .then((res) => {
            this.isLoading = false
            if(res.state) {
              this.logs = res.data[0]
              this.errorMessage = false
            } else {
              this.$router.push('/main/messages')
            }
          })
    },
    sendMessage() {
      this.buttonLoading = true
      authService.answerMessage({message: this.message, chat_id: this.id})
      .then((res) => {
        this.buttonLoading = false
        if(res.state){
          this.logs.messages.push({user_id: this.$store.getters['User/user'].id, message: this.message, desk:false})
          this.clientAnswer({icon: 'success', message: 'Mesajınız gönderildi.'})
          this.message = ''

        }else{
          this.clientAnswer({icon: 'error', message: res.message})
        }
      })
    }

  },
   created() {
    this.getMessages()
  },
  metaInfo() {
    return {
      title: 'Mesajlarım - Hesabım',
    }
  }
}
</script>

<style scoped>

</style>