<template>
  <div>
    <div class="pubpanel-content">
      <div class="stepullah">
        <div class="top active" v-if="loading">
          <div class="left">
            <figure class="avatar"> <b-skeleton type="avatar"/>  </figure>
            <div class="title"> <b-skeleton  width="800%"></b-skeleton></div>
          </div>
          <div class="right">
            <a href="#" class="elm" >
              <b-skeleton-img />
            </a>
            <a href="#" class="elm" >
              <b-skeleton-img />
            </a>
          </div>
          <div class="actions"><a href="#"><i class="fa fa-ellipsis-v"></i></a></div>
        </div>
        <div v-if="!loading">
          <b-alert show variant="warning" v-if="!data[0].status"> Hesabınız halen incelemede. </b-alert>
        </div>
        <div class="top active" v-if="!loading">
          <div class="left">
            <figure class="avatar"><img :src="data[0].profile_pic"></figure>
            <div class="title">{{ data[0].platform_username }}
              <i class="fa fa-check-circle text-success small" v-b-tooltip.hover title="Hesabınız aktif" v-if="data[0].status"/>
              <i class="fas fa-stopwatch text-warning small" v-b-tooltip.hover title="Halen inceleniyor." v-if="!data[0].status"/>
              <br><small>https://kasagame.com/donate/{{data[0].platform_username}}</small></div>
          </div>
          <div class="right">
            <a href="#" class="elm" data-value="filt1">
              <img :src="require('@/assets/images/filt5.png')" v-if="data[0].obs_server === 'streamlabs'">
              <img :src="require('@/assets/images/filt6.png')" v-if="data[0].obs_server === 'streamelements'">
            </a>
            <a href="#" class="elm" data-value="filt5">
              <img :src="require('@/assets/images/filt1.png')" v-if="data[0].platform === 'twitch'">
              <img :src="require('@/assets/images/filt2.png')" v-if="data[0].platform === 'youtube'">
            </a>
          </div>
          <div class="actions"><a href="#"><i class="fa fa-ellipsis-v"></i></a></div>
        </div>
        <b-container >
          <b-row lg="12" class="user-filters">
            <b-col md="3">
              <b-form-datepicker v-model="startDate" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" class="mb-2" size="sm" label-no-date-selected="Bşlgç. Tarihi" dark></b-form-datepicker>
            </b-col>
            <b-col md="3">
              <b-form-datepicker v-model="endDate" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" class="mb-2" size="sm" label-no-date-selected="Bitiş Tarihi" dark></b-form-datepicker>
            </b-col>
            <b-col md="3">
              <b-button variant="info"  size="sm" @click="search">Sorgula</b-button>
            </b-col>
          </b-row>
          <b-row lg="12">
            <b-table
                :items="items"
                :fields="fields"
                :busy="tableLoading"
                hover
                dark
                striped
                borderless
                responsive
                show-empty
            >
              <template v-slot:table-busy>
                <div class="text-center text-white my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Yükleniyor...</strong>
                </div>
              </template>
              <template v-slot:empty="">
                <b-alert show variant="warning">
                  Şuan bağış yok.
                </b-alert>
              </template>
              <template v-slot:cell(name)="data">
                {{ data.item.name }} <br>
                <router-link :to="{path: '/profile/'+data.item.user.username}" style="">
                  <b-badge variant="info">
                    <i class="fa fa-user"/> {{data.item.user.username}}
                  </b-badge>
                </router-link>
              </template>
              <template v-slot:cell(price)="data">
                {{ data.item.price | currency}} TL
              </template>
              <template v-slot:cell(date)="data">
                {{ data.item.date  | formatDate }}
              </template>
            </b-table>
          </b-row>
        </b-container>
      </div>
    </div>
    <div class="clearfix"></div>

  </div>
</template>

<script>
import streamService from "@/services/streamService";
export default {
  name: "StreamerDetail",
  data() {
    return {
      loading: true,
      data: '',
      startDate: '',
      endDate: '',
      items: [],
      tableLoading: true,
      fields: [
        { key: 'id',label: "ID" },
        { key: 'name',label: "İsim " },
        { key: 'message',label: "Mesaj " },
        { key: 'price',label: "Tutar" },
        { key: 'date',label: "Tarih" },
      ],
    }
  },
  methods: {
    search() {
      this.tableLoading = true
      this.getDonates()
    },
    sendMessage(msg) {
      this.$swal({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        icon: msg.icon,
        title: msg.title,
        text: msg.text,
      });

    },
    getDonates() {
      streamService.donates({startDate: this.startDate, endDate: this.endDate})
      .then((res) => {
        this.tableLoading = false
        this.items = res.data
      })
    },
    getDetails() {
      streamService.checkMyDetails()
      .then((response) => {
        this.loading = false
        if(response.state) {
          this.data = response.data
          this.getDonates()
        }
        else
          this.sendMessage({icon: 'error', title: 'Ops!', text: response.message})

      })
    }
  },
  created() {
    this.getDetails()
  }
}
</script>

<style scoped>
.avatar img {
   height: 100%;
}
/deep/.table-dark {
  background-color: #1E1622;
  color: #fff;
  border-radius: 10px;
}
.user-filters {
  background-color: #1E1622;
  color: #fff;
  border-radius: 10px;
  margin-bottom: 10px;
  height: 65px;
  padding: 14px;
}

</style>