<template>
  <b-modal id="withdrawal" @show="show()" modal-class="balance-modal"  hide-footer hide-header>
    <div class="balmod-title">
      Para Çek
    </div>
    <b-alert show variant="warning" v-if="!bankError">
      Sadece kendi banka hesabınıza para çekebilirsiniz.
    </b-alert>
    <b-alert show variant="warning" v-if="bankError">
      Henüz banka hesabı tanımlamadınız. Banka hesabı tanımlamak için <router-link :to="{path: '/main/settings'}"> Tıklayın. </router-link>
    </b-alert>

    <div class="row item"  v-if="!bankError">
      <h4>Banka Bilgileriniz</h4>
        <div class="col-md-6">
          <label for="">{{ bank_name }}</label>
        </div>
        <div class="col-md-6">
          <label for="">{{ iban }}</label>

        </div>
    </div>
    <div class="row item"  v-if="!bankError">
      <h4>ÇEKİM TUTARI</h4>
      <div class="col-md-6">
        <div class="input-group">
          <label>Çekilebilir Maksimum Tutar : <strong class="text-white">{{ $store.getters["User/user"].earning_balance }} TL</strong></label>
        </div>
      </div>
      <div class="col-md-6 balance-try">
        <div class="input-group">
          <input v-model="amount" type="number" min="0" class="form-control" placeholder="Tutar" >
          <div class="input-group-append">
            <span class="input-group-text" id="basic-addon2">TL</span>
          </div>
        </div>
      </div>
    </div>
    <div class="item text-right"  v-if="!bankError">
      <button class="btn-hover" v-if="!btnLoading && !error" @click="submit"><span><img :src="require('@/assets/images/ar.png')"/> SONRAKİ ADIM</span></button>
      <button class="btn-hover" v-if="btnLoading"><span><i class="fa fa-spinner fa-spin"/></span></button>
      <button class="btn btn-dark " v-if="error" disabled><span><i class="fas fa-minus-circle"/></span></button>
    </div>

  </b-modal>
</template>

<script>
import paymentService from "@/services/paymentService";
export default {
  name: "WithDrawal",
  data() {
    return {
      channelsLoading : true,
      channels: [],
      methodId: null,
      amount: '',
      btnLoading: false,
      error: true,
      bank_name: null,
      iban: null,
      bankError: false
    }
  },
  methods:  {
    clientMessage(msg) {
      this.$swal({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        icon: msg.icon,
        title: msg.title,
        text: msg.text,
      });
    },
    submit() {
      this.btnLoading = true
      paymentService.withdrawal({amount: this.amount})
      .then((res) => {
        this.btnLoading = false
        if(res.state){
          this.clientMessage({icon: 'success', title: 'Tebrikler!', text: 'Para Çekme işleminiz gerçekleştirildi, yönlendiriliyorsunuz..'})
          setTimeout(() => {
            this.$router.push({path : '/main/withdraw'})
          }, 2000)
        }else{
          this.clientMessage({icon: 'error', title: 'Ops!', text: res.message})

        }
      })
    },
    show() {
      if(this.$store.getters["User/user"].bank_details){
        this.bank_name = this.$store.getters["User/user"].bank_details.bank_name
        this.iban = this.$store.getters["User/user"].bank_details.iban
        this.bankError = false
      }else{
        this.bankError = true
      }
    }
  },
  watch: {
    amount: function (){
      if(this.amount > 0){
        this.error = false
      }else{
        this.error = true
      }
    }
  }
}
</script>

<style scoped>
input, textarea {
  background: 0 0!important;
  border: 1px solid #fff!important;
  color: #fff!important;
  transition: .5s all!important;
}
</style>