export default {
    updateSiteSettings (context, payLoad) {
        context.commit('setSiteSettings', payLoad)
    },
    updateHomeSlider (context, payLoad) {
        context.commit('setHomeSlider', payLoad)
    },
    updateGamesSlider (context, payLoad) {
        context.commit('setGamesSlider', payLoad)
    },
    updateStoreSlider (context, payLoad) {
        context.commit('setStoreSlider', payLoad)
    },
    updateComissions (context, payLoad) {
        context.commit('setCommissions', payLoad)
    },
    advertisementGames (context, payLoad) {
        context.commit('setAdvertisementGames', payLoad)
    },
    advertisements (context, payLoad) {
        context.commit('setAdvertisements', payLoad)
    },
    adds (context, payLoad) {
        context.commit('setAdds', payLoad)
    },
    giftCards (context, payLoad) {
        context.commit('setGiftCards', payLoad)
    }

}