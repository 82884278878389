export default {
    userLogin (context, payLoad) {
        context.commit('setLogIn', payLoad)
    },
    logout(context) {
       context.commit('logout')
    },
    registerSocials(context, payLoad){
        context.commit('setRegisterSocial', payLoad)
    }
}