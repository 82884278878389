<template>
  <div>
    <Header></Header>
    <Social v-if="socialRegister.needRegister"></Social>
    <Normally v-else></Normally>
    <Footer></Footer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Header from "@/components/Header";
import Footer from "@/components/Footer";
// Register Types
import Normally from "@/views/User/Register/Normally";
import Social from "@/views/User/Register/Social";

export default {
  name: "Register",
  computed: {
    ...mapGetters({
      siteSettings: 'siteInfo/siteSettings',
      socialRegister: 'User/socialRegister'
    })
  },
  components:{
    Header,
    Footer,
    Normally,
    Social
  },
  metaInfo () {
    return {
      title: "Kayıt Ol | " + this.siteSettings[0].title,
      meta: [
        { name: 'description', content: this.siteSettings[0].description+" Kayıt Ol" },
        { name: 'keywords', content: this.siteSettings[0].keywords+ " Kayıt Ol" },
        { charset: 'utf-8' }
      ]
    }
  }
}
</script>

<style scoped>
.login-page {
  background-color: #140f17;
}
.login-and {
  height: 90px;
}
.vue-tel-input {
  border: 0px;
}
.invalid-feedback {
  display: block;
}
</style>