<template>
  <div>
    <Header></Header>
    <section class="aboutus">
      <div class="page-title">
        <div class="container">
          <div class="bg-blur"></div>
          <h1>Üyelik Sözleşmesi</h1>
        </div>
      </div>
      <div class="content">
        <div class="container">
          <p><br>
            <b>Üye olmadan alışveriş yapabilir miyim?</b><br>
            Kasagame.com'a üye olmadan alışveriş yapabilirsiniz. Beğendiğiniz ürünleri sepetinize atıp ödeme ekranından
            ödeme yapabilir, siparişinizi tamamlayabilirsiniz. Not: Eğer sitemize üye olup alışverişinizi üyelik
            üzerinden devam ettirirseniz yapılacak kampanyalardan, hediye çeklerinden faydalanabilirsiniz. Üye olan
            herkes SMS ile kampamyalar hakkında bilgi almayı kabul etmiş sayılır.<br><br>

            <b>Neden üye olmalıyım?</b><br>
            Kasagame.com'de düzenlenecek promosyonlardan, yeniliklerden ve yalnızca üyelerimize özel avantajlardan
            haberdar olabilir ve faydalanabilirsiniz. Sitemizde beğendiğiniz ürünleri sepetinize ekleyebilir, satın
            aldığınız ürünler ile ilgili yorumlar yapabilirsiniz. <br><br>

            <b>Nasıl üye olabilirim?</b><br>
            Anasayfa da, üst menüde yer alan Oturum Aç butonuna tıklayın. Kasagame.com üyelik formunu doldurup Ücretsiz
            Kayıt Ol butonuna bastıktan sonra üyelik işleminiz tamamlanmış olacaktır.<br><br>

            www.kasagame.com'ye üye olan herkes bu sözleşmeyi okumuş, anlamış ve kabul etmiş sayılır.<br>
            Taraflar <br>
            Bu sözleşme, www.kasagame.com ile www.kasagame.com üyesi olan kişi arasında düzenlenmiş, elektronik ortamda
            üye tarafından onaylanması anında akdedilmiş ve karşılıklı olarak yürürlüğe girmiştir.<br><br>

            <b>Tanımlar</b><br><br>
            www.kasagame.com: Decade Bilişim LTD'nin sahini olduğu web sitesidir <br>
            Üye: www.kasagame.com'de yer alan üyelik formu doldurmuş, "Üyelik ve Gizlilik Sözleşmesini okudum, kabul
            ediyorum" ifadesinin başındaki kutuyu işaretlemiş ve Ücretsiz Kayıt Ol butonuna basarak üye olma sürecini
            tamamlamış kişidir.<br><br>
            <b>1-</b> www.kasagame.com üyelik sözleşmesinden doğan her türlü anlaşmazlıkta İstanbul Mahkemeleri
            yetkilidir.<br><br>
            <b>2-</b> Üyeler, www.kasagame.com'ye ekledikleri bilgilerin ve içeriklerin doğru, hukuka ve Türkiye
            Cumhuriyeti yasalarına uygun ve halka açık olduğunu kabul ve taahhüt etmektedirler. Üye aksi durumda
            oluşabilecek her türlü zararı karşılamayı kabul etmektedir.<br><br>
            <b>3-</b> Üyelerin, www.kasagame.com tarafından sunulan hizmetlerden yararlanabilmek amacıyla kullandıkları
            sisteme erişim araçlarının (eposta, parola v.b) güvenliği, saklanması, üçüncü kişilerin bilgisinden uzak
            tutulması, kullanılması durumlarıyla ilgili hususlar tamamen üyelerin sorumluluğundadır.<br><br>
            <b>4-</b> Üye, www.kasagame.com üyeliğini kısmen veya tamamen herhangi bir üçüncü kişiye devredemez; üyelik
            üyenin kendisinden başka kişilerin kullanımına açılamaz.<br><br>
            <b>5-</b> www.kasagame.com satışa sunduğu kitap v.b'nin yasalara uygun (orijinal) olduğunu kabul
            eder.<br><br>
            <b>6-</b> www.kasagame.com neden belirtmeksizin üyelere verdiği hizmeti sınırlandırabilir, değiştirebilir
            veya üyenin üyeliğine son verebilir.<br><br>
            <b>7-</b> www.kasagame.com bu kullanım sözleşmesinde zaman zaman değişiklikler yapabilir, üyelerin bu üyelik
            sözleşmesi değişikliklerini takip ederek uygun görmemeleri halinde üyelikten çıkmaları kendi
            sorumluluğundadır.<br><br>
            <b>8-</b> Üye, yukarıdaki yer alan maddelere aykırı hareket ederek uğrayabileceği her türlü zarar sebebiyle
            www.kasagame.com'den herhangi bir hak talep edilemeyeceğini kabul eder.<br><br>
          </p>
        </div>
      </div>
      <div class="page-title">
        <div class="container">
          <div class="bg-blur"></div>
          <h1>KVKK Sözleşmesi</h1>
        </div>
      </div>
      <div class="content">
        <div class="container">
          <p>
            <b>MADDE 1- TARAFLAR</b><br>
            1.1. SATICI <br>
            Ünvanı: Decade Bilişim LTD.<br>
            Adresi: Serbest Liman ve Bölge , Posta Kutusu No : 568 Gazimağusa / Kıbrıs<br>
            E-Posta: iletisim@kasagame.com<br><br>

            1.2- ALICI:<br>
            Adı/Soyadı/Ünvanı : [[uyeAdi]]<br>
            E-Posta : [[uyeEposta]]<br><br>

            <b>MADDE 2- KONU</b><br>
            İşbu sözleşmenin konusu, ALICI'nın SATICI'ya ait kasagame.com internet sitesinden elektronik ortamda
            siparişini yaptığı aşağıda nitelikleri ve satış fiyatı belirtilen ürünün satışı ve teslimi ile ilgili olarak
            4077 sayılı Tüketicilerin Korunması Hakkındaki Kanun ve Mesafeli Sözleşmeleri Uygulama Esas ve Usulleri
            Hakkında Yönetmelik hükümleri gereğince tarafların hak ve yükümlülüklerinin saptanmasıdır. <br><br>

            <b>MADDE 3- SÖZLEŞME KONUSU ÜRÜN</b><br>
            3.1- Ürünlerin cinsi ve türü, miktarı, marka/modeli, rengi, vergiler dahil satış bedeli (birim x birim fiyat
            olarak) aşağıda belirtildiği gibidir.
            [[sozlesme.Siparis_Urunler]] <br><br>

            3.2- Ödeme Şekli : [[sozlesme.Odeme_Tipi]] <br>
            Eğer kredi kartı ile taksili işlem ile sipariş veriyorsanız: <br>
            Bankanız kampanyalar düzenleyerek sizin seçtiğiniz taksit adedinin daha üstünde bir taksit adedi
            uygulayabilir, taksit öteleme gibi hizmetler sunulabilir. Bu tür kampanyalar bankanızın inisiyatifindedir ve
            şirketimizin bilgisi dâhilinde olması durumunda sayfalarımızda kampanyalar hakkında bilgi verilmektedir.
            <br><br>
            Kredi kartınızın hesap kesim tarihinden itibaren sipariş toplamı taksit adedine bölünerek kredi kartı
            özetinize bankanız tarafından yansıtılacaktır. Banka taksit tutarlarını küsurat farklarını dikkate alarak
            aylara eşit olarak dağıtmayabilir. Detaylı ödeme planınızın oluşturulması bankanız inisiyatifindedir.
            <br><br>

            3.3- Diğer yandan vadeli satışların sadece Bankalara ait kredi kartları ile yapılması nedeniyle, ALICI,
            ilgili faiz oranlarını ve temerrüt faizi ile ilgili bilgileri bankasından ayrıca teyit edeceğini, yürürlükte
            bulunan mevzuat hükümleri gereğince faiz ve temerrüt faizi ile ilgili hükümlerin Banka ve ALICI arasındaki
            kredi kartı sözleşmesi kapsamında uygulanacağını kabul, beyan ve taahhüt eder. <br>
            İade Prosedürü: <br><br>

            <b>A) KREDİ KARTINA İADE PROSEDÜRÜ</b><br>
            ALICI'nın cayma hakkını kullandığı durumlarda ya da siparişe konu olan ürünün çeşitli sebeplerle tedarik
            edilememesi veya Hakem heyeti kararları ile Tüketiciye bedel iadesine karar verilen durumlarda ,alışveriş
            kredi kartı ile ve taksitli olarak yapılmışsa, kredi kartına iade prosedürü aşağıda belirtilmiştir:
            Müşterimiz ürünü kaç taksit ile aldıysa Banka müşteriye geri ödemesini taksitle yapmaktadır. <br><br> SATICI
            bankaya ürün bedelinin tamamını tek seferde ödedikten sonra ,Banka poslarından yapılan taksitli harcamaların
            Müşterimizin kredi kartına iadesi durumunda,konuya müdahil tarafların mağdur duruma düşmemesi için talep
            edilen iade tutarları,yine taksitli olarak hamil taraf hesaplarına Banka tarafından aktarılır.Müşterinin
            satış iptaline kadar ödemiş olduğu taksit tutarları ,eğer iade tarihi ile kartın hesap kesim tarihleri
            çakışmazsa her ay karta 1 iade yansıyacak ve müşteri iade öncesinde ödemiş olduğu taksitleri satışın
            taksitleri bittikten sonra , iade öncesinde ödemiş olduğu taksitleri sayısı kadar ay daha alacak ve mevcut
            borçlarından düşmüş olacaktır. <br><br>

            Kart ile alınmış mal ve hizmetin iadesi durumunda SATICI,Banka ile yapmış olduğu sözleşme gereği Müşteriye
            nakit para ile ödeme yapamaz.Üye işyeri yani SATICI,bir iade işlemi sözkonusu olduğunda ilgili yazılım
            aracılığı ile iadesini yapacak olup,Üye işyeri yani SATICI ilgili tutarı Bankaya nakden veya mahsuben
            ödemekle yükümlü olduğundan yukarıda anlatmış olduğumuz prosedür gereğince Müşteriye nakit olarak ödeme
            yapılamamaktadır. Kredi kartına iade ,SATICI Bankaya bedeli tek seferde ödemesinden sonra ,Banka tarafından
            yukarıdaki prosedür gereğince yapılacaktır. <br><br>

            ALICI, bu prosedürü okuduğunu ve kabul ettiğini kabul ve taahhüd eder. <br><br>

            <b>B) KAPIDAN ÖDEME İLE HAVALE/EFT ÖDEME SEÇENEKLERİNDE İADE PROSÜDÜRÜ</b><br>
            Kapıdan ödeme ile Havale/EFT ödeme seçeneklerinde iade Tüketiciden banka hesap bilgileri
            istenerek,Tüketicinin belirttiği hesaba (hesabın fatura adresindeki kişinin adına veya kullanıcı üyenin
            adına olması şarttır) Havale veya EFT şeklinde yapılacaktır. <br><br>

            3.4- Teslimat Şekli ve Adresi : <br>
            Teslim Edilecek Kişi : [[sozlesme.Teslimat_AdSoyad]] <br>
            Teslim Edilecek Kişinin İletişim Bilgileri : [[sozlesme.Teslimat_TelefonGsm]] <br>
            Teslimat Adresi : [[sozlesme.Teslimat_Adres]] <br>
            Fatura Adresi : [[sozlesme.Fatura_Adres]] <br><br>

            <b>MADDE 4- GENEL HÜKÜMLER</b><br>
            4.1- ALICI, [[sozlesme.SiteDomain]] internet sitesinde sözleşme konusu ürünün temel nitelikleri, satış
            fiyatı ve ödeme şekli ile teslimata ilişkin ön bilgileri okuyup bilgi sahibi olduğunu ve elektronik ortamda
            gerekli teyidi verdiğini beyan eder. <br><br>
            4.2- Sözleşme konusu ürün, yasal 30 günlük süreyi aşmamak koşulu ile her bir ürün için ALICI'nın yerleşim
            yerinin uzaklığına bağlı olarak internet sitesinde ön bilgiler içinde açıklanan süre içinde ALICI veya
            gösterdiği adresteki kişi/kuruluşa teslim edilir.<br><br>
            4.3- Sözleşme konusu ürün, ALICI'dan başka bir kişi/kuruluşa teslim edilecek ise, teslim edilecek
            kişi/kuruluşun teslimatı kabul etmemesinden SATICI sorumlu tutulamaz.<br><br>
            4.4- SATICI, sözleşme konusu ürünün sağlam, eksiksiz, siparişte belirtilen niteliklere uygun ve varsa
            garanti belgeleri ve kullanım kılavuzları ile teslim edilmesinden sorumludur.<br><br>
            4.5- Sözleşme konusu ürünün teslimatı için işbu sözleşmenin imzalı nüshasının SATICI'ya ulaştırılmış olması
            ve bedelinin ALICI'nın tercih ettiği ödeme şekli ile ödenmiş olması şarttır. Herhangi bir nedenle ürün
            bedeli ödenmez veya banka kayıtlarında iptal edilir ise, SATICI ürünün teslimi yükümlülüğünden kurtulmuş
            kabul edilir.<br><br>
            4.6- Ürünün tesliminden sonra ALICI'ya ait kredi kartının ALICI'nın kusurundan kaynaklanmayan bir şekilde
            yetkisiz kişilerce haksız veya hukuka aykırı olarak kullanılması nedeni ile ilgili banka veya finans
            kuruluşun ürün bedelini SATICI'ya ödememesi halinde, ALICI'nın kendisine teslim edilmiş olması kaydıyla
            ürünün 3 gün içinde SATICI'ya gönderilmesi zorunludur.<br><br>
            4.7- Garanti belgesi ile satılan ürünlerden olan veya olmayan ürünlerin arızalı veya bozuk olanlar, (ayıplı)
            garanti şartları içinde gerekli onarımın yetkili servise yaptırılması için SATICI'ya gönderilebilir, bu
            takdirde kargo giderleri SATICI tarafından karşılanacaktır.<br><br>

            <b>MADDE 5- CAYMA HAKKI</b><br>
            ALICI, sözleşme konusu ürünün kendisine veya gösterdiği adresteki kişi/kuruluşa tesliminden itibaren 7 gün
            içinde malı redederek cayma hakkına sahiptir. Tüketicinin cayma bildiriminin SATICI'ya ulaştığı tarihten
            itibaren 10 gün içinde ürün bedeli ALICI'ya iade edilir.(bkz Mesafeli Sözleşmeler Hakkında Yönetmelik madde
            9/2) Cayma hakkı nedeni ile iade edilen ürünün kargo bedeli SATICI tarafından karşılanır.<br><br>
            Tüketicinin hiçbir hukuki ve cezai sorumluluk üstlenmeksizin ve hiçbir gerekçe göstermeksizin malı teslim
            aldığı veya sözleşmenin imzalandığı tarihten itibaren yedi gün içerisinde malı veya hizmeti reddederek
            sözleşmeden cayma hakkının var olduğunu ve cayma bildiriminin SATICI veya sağlayıcıya ulaşması tarihinden
            itibaren malı geri almayı taahhüt ederiz.<br><br>

            395 sayılı vergi usul kanunu genel tebliği uyarınca iade işlemlerinin yapılabilmesi için tarafınıza
            göndermiş olduğumuz iade bölümü bulunan faturada ilgili bölümlerin eksiksiz olarak doldurulması ve
            imzalandıktan sonra tarafımıza ürün ile birlikte geri gönderilmesi gerekmektedir.<br><br>
            Ancak, KKTC ve yurtdışı gönderilerinde Müşterilerimiz siparişlerinde KDV ödemediği için, gümrükte almış
            olduğu ürünlerin vergilerini ödemekle yükümlüdür.Gümrükten her ne şekilde olursa olsun teslim alınmayan
            ürünler için Müşterilerin cayma hakkı bulunmamaktadır. Çünkü MESAFELİ SÖZLEŞMELER UYGULAMA USUL VE ESASLARI
            HAKKINDA YÖNETMELİĞİN 8.maddesi uyarınca Tüketici mal satışına ilişkin mesafeli sözleşmelerde, teslim aldığı
            tarihten itibaren yedi gün içerisinde hiçbir hukuki ve cezai sorumluluk üstlenmeksizin ve hiçbir gerekçe
            göstermeksizin malı reddederek sözleşmeden cayma hakkına sahiptir.<br><br>

            <b>MADDE 6- CAYMA HAKKI KULLANILAMAYACAK ÜRÜNLER</b><br>
            Niteliği itibarıyla iade edilemeyecek ürünler, tek kullanımlık ve hızlı tüketim ürünleri, kopyalanabilir
            yazılım ve programlar, elektronik kod, ekod, steam key, origin key, pvp server key<br>
            -Elektronik Kod, Steam Key, Oyun Kodları<br>
            -Hediye Kodları<br><br>

            <b>MADDE 7- TEMERRÜT HÜKÜMLERİ</b><br>
            Tarafların işbu sözleşmeden kaynaklarından edimlerini yerine getirmemesi durumunda Borçlar Kanunu'nun
            106-108.maddesinde yer alan Borçlunun Temerrüdü hükümleri uygulanacaktır. Temerrüt durumlarında, herhangi
            bir tarafın edimlerini süresi içinde haklı bir sebebi olmaksızın yerine getirmemesi durumunda diğer taraf
            söz konusu edimin yerine getirilmesi için edimini yerine getirmeyen tarafa 7 günlük süre verecektir. Bu süre
            zarfında da yerine getirilmesi durumunda edimini yerine getirmeyen taraf mütemerrit olarak addolunacak ve
            alacaklı edimin ifasını talep etmek suretiyle malın teslimini, ve/veya sözleşmenin feshini ve bedelin
            iadesini talep etme hakkına sahiptir.<br><br>

            Mesafeli Sözleşmeler Hakkında Yönetmeliğin 9.maddesinin son fıkrası gereğince SATICI
            [[sozlesme.SiteDomain]],sipariş konusu mal veye hizmetin yerine getirilmesinin imkansızlaştığı ileri sürerek
            (tedarikçi Firmadan ürünün hiçbir şekilde tedarik edilememesi durumu) ,sözleşme konusu yükümlülüklerini
            yerine getiremiyorsa ,bu durumu sözleşmeden doğan ifa yükümlülüğünün süresi dolmadan tüketiciye bildirmeyi
            taahhüd eder.Bu durumda , XXX sözleşmeyi derhal fesih etme hakkına haiz olup, Müşterinin sipariş verdiği
            ürünün bedelini ve varsa borç altına sokan tüm belgeleri geri iade edeceğini taahhüd eder.<br><br>

            [[sozlesme.SiteDomain]] işbu yükümlülüğünü yerine getirmesini engelleyebilecek mücbir sebepler veya
            nakliyeyi engelleyen hava muhalefetleri,ulaşımın kesilmesi,yangın ,deprem,sel baskını gibi olağanüstü
            olaylar nedeni ile sözleşme konusu ürünü süresi içerisinde teslim edemez ise , Bu tip durumlarda
            ALICI,[[sozlesme.SiteDomain]] un hiçbir sorumluluğu olmadığını,siparişin iptal edilmesini veya teslimat
            süresinin engelleyici durumunun ortadan kalkmasına kadar ertelenmesi haklarından birini kullanabilir.
            ALICInın siparişi iptal etmesi halinde ödediği tutar 10 gün içerisinde kendisine ödenir.(kredi kartı ile
            yapılan taksitli alışverişlerde ise kredi kartına iade için yukarıdaki prosedür ALICI tarafından kabul
            edilir)<br><br>

            <b>MADDE 8- ABONELİK</b><br>
            8.1- Abonelik işlemi [[sozlesme.Alici_AdSoyad]] ile [[sozlesme.SiteDomain]] arasında yapılmıştır ve
            kesinlikle üçüncü şahıslara devredilemez.<br><br>

            8.2- Abonelik işlemi yapılırken [[sozlesme.Alici_AdSoyad]] tarafından verilen adres geçerlidir ve kargo o
            adrese gönderilecektir, adres değişikliği yapılamaz.<br><br>
            8.3- [[sozlesme.SiteDomain]] 'den satın alınan abonelik dondurulamaz ancak abonelik talebini iptal etmek
            isteyenler 7 gün içerisinde tarafımıza bildirimde bulunmaları gerekir. E-Posta: iletisim@kasagame.com
            <br><br>

            8.4- Teslim alınmayan abonelik kargolarının İKİNCİ gönderim ücretini [[sozlesme.Alici_AdSoyad]]
            öder.<br><br>

            <b>MADDE 9- YETKİLİ MAHKEME</b><br>
            İşbu sözleşmenin uygulanmasında, Sanayi ve Ticaret Bakanlığınca ilan edilen değere kadar Tüketici Hakem
            Heyetleri ile ALICI'nın veya SATICI'nın yerleşim yerindeki Tüketici Mahkemeleri yetkilidir. <br><br>

            Siparişin gerçekleşmesi durumunda ALICI işbu sözleşmenin tüm koşullarını kabul etmiş sayılır. <br><br>

            <b>SATICI :</b> Decade Bilişim LTD. <br>
            <b>ALICI :</b> [[uyeAdi]] <br>
            <b>TARİH :</b> [[Tarih]] <br> <br>
          </p>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/Header';
import Footer from '@/components/Footer';

export default {
  name: "PageDetail",
  data() {
    return {
      loading: true,
    }
  },
  components: { Header, Footer },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { name: 'description', content: this.title },
        { name: 'keywords', content: this.title },
        { charset: 'utf-8' }
      ]
    }
  }

}
</script>

<style scoped>
.aboutus {
  background: #130E17;
}
</style>