let config

if (process.env.NODE_ENV === 'production') {
    config = {
        $api_url: 'https://api.kasagame.com',
        $api_url_cdn: 'https://cdn.kasagame.com',
        $socket_url : 'https://api.kasagame.com',
        timeoutDuration: 30000,
        someOtherProps: 'xyz'
    }
} else {
    config = {
        $api_url: 'http://localhost:3000',
        $api_url_cdn: 'http://localhost:3001',
        $socket_url : 'http://localhost:3000',
        timeoutDuration: 1000,
        someOtherProps: 'abc'
    }
}

export { config }
