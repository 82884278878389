<template>
  <section class="streamers" v-if="data.length > 0">
    <div class="container">
      <h1>Yayıncılar</h1>
      <div class="owl-carousel" v-if="!loading">
        <Carousel
            :perPageCustom = "[[768, 3], [1024, 6]]"
            :paginationEnabled=false
            :autoplay=true
        >
          <slide v-for="(item, index) in data" :key="index">
              <div class="owl-item active" style="width: 153.75px; margin-right: 5px;">
                <router-link :to="{path: '/donate/'+item.platform_username}" class="item" :class="{'active' : item.is_online}">
                  <figure><img :src="item.profile_pic" ></figure>
                  <strong>{{ item.platform_username }}</strong>
                  <span v-if="item.is_online">ONLİNE</span>
                </router-link>
              </div>
          </slide>
        </Carousel>
      </div>
    </div>
  </section>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';
import streamService from "@/services/streamService";

export default {
  name: "Streamers",
  data() {
    return {
      data : [],
      loading: true
    }
  },
  methods: {
    getStreamers() {
      streamService.streamers({sort: 'news'})
      .then((res) => {
        if(res.state)
          this.loading = false
          this.data = res.streamers
      })
    }
  },
  components: {
    Carousel,
    Slide
  },
  created() {
    this.getStreamers()
  }
}
</script>

<style scoped>

</style>