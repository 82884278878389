<template>
  <section class="games">
    <div class="container">
      <h1>Resmi İlan Mağazaları</h1>
      <div class="swiper-container">


        <div class="swiper-wrapper" v-if="!loading">
          <Carousel
              :perPageCustom = "[[768, 3], [1024, 6]]"
              :paginationEnabled=true
              :loop=false
              :autoplay=false
          >
            <slide v-for="(item, index) in stores" :key="index">
              <div class="swiper-slide"><div class="item">
                <router-link :to="{path: 'advertisements/?id='+item.id}">
                  <a>
                    <img :src="item.image" />
                  </a>
                </router-link>
                <div class="games-blur"></div>
                <router-link :to="{path: 'advertisements/?id='+item.id}">
                  <a>
                    <strong>{{item.title}}</strong>
                  </a>
                </router-link>

              </div>
              </div>
            </slide>

          </Carousel>
        </div>
      </div>
    </div>
  </section>

</template>

<script>
import { Carousel, Slide } from 'vue-carousel';
import { mapGetters } from 'vuex';
export default {
  name: "MallsSlider",
  data() {
    return {
      loading: this.$store.getters.homeLoading
    }
  },
  computed: {
    ...mapGetters({
      stores: 'siteInfo/storeSlider'
    })
  },
  components:{
    Carousel,
    Slide
  },
  watch: {
    '$store.getters.homeLoading': function() {
      this.loading = this.$store.getters.homeLoading
    }
  }
}
</script>

<style scoped>
.VueCarousel-slide {
  margin-right: 10px;
  max-width: 270px;
}
.VueCarousel-slide img {
  max-width: 270px;
}



</style>