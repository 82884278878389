<template>
  <section class="sales">
    <div class="container">
      <div class="title">
        <h1>Onaylı İlanlar</h1>
        <b-skeleton animation="throb" class="bg-dark nav" v-if="loading" width="20%"></b-skeleton>
        <ul class="nav" v-if="!loading">
          <li class="nav-item " ><a class="nav-link" :class="{'active' : !activeGame}" @click="getHomeAdvertisements()">Tümü</a></li>
          <li class="nav-item" v-for="(game, index) in games" :key="index" ><a class="nav-link" :class="{'active' : activeGame == game.id}" @click="getHomeAdvertisements(game.id)">{{ game.title }}</a></li>
        </ul>
      </div>
      <div class="tab-content" v-if="loading">
        <div class="tab-pane active">
          <div class="owl-carousel">
            <Carousel
                :perPageCustom = "[[768, 3], [1024, 6]]"
                :paginationEnabled=false
                :autoplay=true
            >
              <slide v-for="item in 6" :key="item">
                <div class="item">
                  <a >
                    <figure>
                      <b-skeleton-img animation="throb"></b-skeleton-img>
                    </figure>
                    <strong>
                      <b-skeleton animation="throb" class="bg-dark " width="100%"></b-skeleton>
                    </strong>
                    <span>
                      <b-skeleton animation="throb" class="bg-dark " width="100%"></b-skeleton>
                    </span>
                  </a>
                </div>
              </slide>
            </Carousel>



          </div>
        </div>
      </div>

      <div class="tab-content" v-if="!loading">
        <div class="tab-pane active">
          <div class="owl-carousel">
            <Carousel
                :perPageCustom = "[[768, 3], [1024, 6]]"
                :paginationEnabled=false
                :autoplay=true
            >
              <slide v-for="(advertisement, index) in advertisementList" :key="index">
                 <div class="item">
                   <router-link :to="{path: 'advertisement/'+advertisement.id}">
                     <figure>
                       <img :src="advertisement.image" />
                       <figcaption>{{ advertisement.price | currency}} TL </figcaption>
                      </figure>
                     <strong>{{ advertisement.gameTitle }}</strong>
                     <span>{{ advertisement.title }}</span>
                    </router-link>
                </div>
              </slide>
            </Carousel>



          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';
import { mapGetters } from 'vuex';
import advertisementsService from "@/services/advertisementsService";

export default {
  name: "Sales",
  data() {
    return {
      loading: true,
      advertisementList: this.$store.getters["siteInfo/advertisements"],
      activeGame: null
    }
  },
  methods: {
    getHomeAdvertisements(id) {
      this.loading = true
      this.activeGame = id
      advertisementsService.getAdvertisementGamesHome({game_id: id})
      .then((res) => {
        this.advertisementList = res.list
        this.loading = false
      })
    }
  },
  components:{
    Carousel,
    Slide
  },
  computed: {
    ...mapGetters({
      games: 'siteInfo/advertisement_games'
    })
  },
  created() {
    this.getHomeAdvertisements()
  }
}
</script>

<style scoped>
.sales .item img {
  min-height: 172px;
  min-width: 155px;
  max-width: 155px;
  min-height: 172px;
}
</style>