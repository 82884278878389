<template>
<b-modal id="search" modal-class="search-modal" hide-header hide-footer>
  <div class="padding-0">
    <div class="input-group mb-3 search-input">
      <input type="text" v-model="searchKey" class="form-control" placeholder="Oyun, ürün, yayıncı ya da mağaza ara" />
      <div class="input-group-append">
        <button class="input-group-text" ><img :src="require('@/assets/images/search-input.png')"/></button>
      </div>
    </div>
    <div class="search-list" v-if="searchItems.length > 0">
      <h4>ARAMA SONUÇLARI</h4>
      <ul>
        <li v-for="(data) in searchItems" :key="data">
          <router-link :to="{path: data.link}">
            <div class="list-img">
              <img :src="data.img"/>
            </div>
            <div class="list-text">
              <h5>{{ data.title }}</h5>
              <label>{{ data.description }}</label>
            </div>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</b-modal>
</template>

<script>
import siteInfo from "@/services/siteInfo";
export default {
  name: "Search",
  data() {
    return {
      searchItems: [],
      searchKey: null,
    }
  },
  methods: {
    search() {
      siteInfo.search({search: this.searchKey})
      .then((res) => {
        this.searchItems = res
      })
    }
  },
  watch: {
    searchKey: function () {
      if(this.searchKey.length > 3){
        this.search()
      }
    }
  }
}
</script>

<style scoped>
.form-control, .form-control:focus, .form-control:hover {
  width: 1%;
}
</style>