<template>
<div>
  <div class="pubpanel-content">
    <div class="stepullah">
      <div class="top">
        <div class="left">
          <figure class="avatar"><img :src="require('@/assets/images/unlost.png')"/></figure>
          <div class="title">Unlost <br/><small>kasagame.com/yayinci/unlost</small></div>
        </div>
        <div class="right"></div>
        <div class="actions"><a href="#"><i class="fa fa-ellipsis-v"></i></a></div>
      </div>
      <div v-if="!redirect">
        <div class="item" :class="{'active' : !platformSelected}">
          <div class="left">
            <span>1</span> Yayın yaptığınız platformu seçiniz

          </div>
          <div class="right">
            <button @click="selectPlatform({platform: 'twitch'})" class="elm btn btn-sm" ><img :src="require('@/assets/images/filt1.png')" /></button>
            <button @click="selectPlatform({platform: 'youtube'})" class="elm btn btn-sm" ><img :src="require('@/assets/images/filt2.png')" /></button>
          </div>
        </div>
        <div class="item" :class="{'active' : platformSelected && !obsSelected}">
          <div class="left">
            <span>2</span> OBS serverınızı seçiniz
          </div>
          <div class="right">
            <button @click="selectObs({obs: 'streamlabs'})" class="elm btn btn-sm" ><img :src="require('@/assets/images/filt5.png')" /></button>
            <button @click="selectObs({obs: 'streamelements'})" class="elm btn btn-sm" ><img :src="require('@/assets/images/filt6.png')" /></button>
          </div>
        </div>
        <div class="" v-if="platformSelected && obsSelected">
          <div class="item active">
            <div class="left">
              <span>3</span> Kanal Adınız
            </div>
            <div class="right">
              <input type="text" class="form-control" placeholder="https://www.twitch.tv/kasagame" v-model="channelUrl">
              <button class="btn btn-sm btn-dark" v-if="!btnLoading" @click="sendStreamer"><span>Başvuruyu Tamamla</span></button>
              <button class="btn btn-sm btn-dark" v-else><span><i class="fas fa-spinner fa-spin"></i></span></button>
            </div>
          </div>
        </div>
      </div>
      <div class="step-last  text-center" v-if="redirect">
        <img :src="require('@/assets/images/step-tick.png')"/>
        <p>Kaydınız aldık</p>
        <p>{{redirectSeconds}} saniye içerisinde doğrulama için yönlendirileceksiniz..</p>
        <p>Yönlendirildiğiniz sayfayı başvuru yapmadan kapatırsanız, doğrulama başarısız sayılacak ve başvurunuz silinecek.</p>
      </div>

    </div>

  </div>
  <div class="clearfix"></div>
</div>
</template>

<script>
import streamService from "@/services/streamService";

export default {
  name: "RegisterStreaming",
  data() {
    return {
      platform: '',
      obs_server: '',
      platformSelected: false,
      obsSelected: false,
      isItSuccess: false,
      btnLoading: false,
      channelUrl: '',
      redirectSeconds: 5,
      redirect: false
    }
  },
  methods: {
      selectPlatform(val){
        this.platform = val.platform
        this.platformSelected = true
      },
    selectObs(val) {
      this.obs_server = val.obs
      this.obsSelected = true
    },
    clientMessage(msg) {
      this.$swal({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        icon: msg.icon,
        title: msg.title,
        text: msg.text,
      });
    },
    sendStreamer() {
      this.btnLoading = true
      if(this.obs_server === 'streamlabs'){
        streamService.setStreamerStreamlabs({channel_url: this.channelUrl, platform: this.platform, obs: this.obs_server})
        .then((res) => {
          if(res.state){
            this.clientMessage({icon: 'success', title: 'Kaydınız alındı.', text: 'Onay için yönlendiriliyorsunuz..'})
            this.redirect = true
            setInterval(() => {
              this.redirectSeconds--
            },1000)
            setTimeout(() => {
              window.location.href  = res.url
            },3000)

          }
          else {
            this.btnLoading = false
            this.clientMessage({icon: 'error', title: 'Ops!', text: res.message})
          }
        })
      }
    }
  }
}
</script>

<style scoped>

</style>