<template>
  <b-modal @show="orderDetail" id="confirmBuyingAdvertisement" modal-class="balance-modal"  hide-footer hide-header>
    <div class="section-three">
      <h5 v-if="!success">
        <b>{{details.title}} </b> başlıklı Ürünü teslim aldığınızı onaylıyor musunuz?
        <label>
          Onay işleminizden sonra ürünün ücreti satıcıya aktarılacaktır.
        </label>
        <b-alert show variant="warning" v-if="details.is_account">
          <strong>Email/Kullanıcı adı: </strong><br>
          {{details.account_email}}
          <br />
          <strong>Şifre:</strong> <br>
            <span v-if="!seePassword">********</span>
            <span v-if="seePassword">{{ details.account_password }}</span>
          <br>
            <b class="btn-sm btn-dark" v-if="!seePassword" @click="changeSeePassword(true)"><i class="fas fa-eye"/></b>
           <b class="btn-sm btn-info" v-if="seePassword" @click="changeSeePassword(false)"><i class="fas fa-eye-slash"/></b>
        </b-alert>
      </h5>
      <div class="balance-modal-check" v-if="success">
        <img :src="require('@/assets/images/check.png')">
        <h5>
          <label>Teşekkürler.</label>
          <label>Ürünü teslim aldığınızı onayladınız.</label>
          <label>Ücret satıcı hesabına aktarılmıştır.</label>
        </h5>
      </div>
      <div class="confirmation-button" v-if="!success">
        <button class="btn-hover" v-if="!loading" @click="confirm"><span>ONAYLIYORUM</span></button>
        <button class="btn-problem" v-if="!loading">SORUN BİLDİR</button>
        <button class="btn-hover" v-if="loading"> <span>  <i class="fas fa-spinner fa-spin"></i></span> </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import authService from "@/services/authService";

export default {
  name: "CheckBuyingAdvertisement",
  data() {
    return {
      details: '',
      seePassword: false,
      loading: false,
      success : false
    }
  },
  props: ['id'],
  methods: {
    changeSeePassword(val) {
      this.seePassword = val
    },
    sendMessage(message) {
      this.$swal({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        icon: message.icon,
        title: message.title,
        text: message.text,
      });
    },
    confirm() {
      this.loading = true
      authService.confirmAddsOrder({id:this.id})
      .then((res) => {
        this.loading = false
        if(res.state) {
          this.details = ''
          this.success = true
          this.sendMessage({icon: 'success', title: 'Tebrikler!', text: 'İlan alımı başarıyla gerçekleşti.'})
        }
        else {
          this.$bvModal.hide('confirmBuyingAdvertisement')
          this.sendMessage({icon: 'error', title: 'Hata!', text: res.message})
        }
      })
    },
    orderDetail() {
      this.loading = true
      if(this.id !== 0){
        authService.getAddsOrderDetail({id: this.id})
        .then((res) => {
          this.success = false
          this.loading = false
          if(res.state){
           this.details = res.list[0]
          }else{
            this.details = ''
            this.$bvModal.hide('confirmBuyingAdvertisement')
            this.sendMessage({icon: 'error', title: 'Hata!', text: res.message})
          }
        })
      }
    }
  },
  watch:{
    id: function () {
      this.orderDetail()
    }
  },
}
</script>

<style scoped>

</style>