import axios from 'axios'
import { config } from './apiServiceConfig'
import {store} from "@/store";
//import { store } from '@/store'

const apiUrl = config.$api_url
export default {
    articlesHome(data) {
        return axios
            .get(apiUrl+ '/articles/home', data)
            .then(response => {
                return response.data
            })
    },
    articlesTop5(){
        return axios
            .get(apiUrl+ '/articles/top5')
            .then((response) => {
                return response.data
            })
    },
    articlesEditor() {
        return axios.get(apiUrl+'/articles/editor')
            .then((response) => {
                return response.data
            })
    },
    getArticles(data) {
        return axios.get(apiUrl+'/articles/list', {
            params:{
                page: data.page
            }
        })
            .then(response => response.data)
    },
    getArticlesSearch(data) {
        return axios.get(apiUrl+'/articles/search', {
            params:{
                page: data.page,
                key: data.key
            }
        })
            .then(response => response.data)
    },
    getArticlesDetail(data) {
        return axios.get(apiUrl+'/articles/detail/'+data.id, {
            params:{
                id: data.id
            }
        })
            .then(response => response.data)
    },
    readingArticle(data) {
        axios.defaults.headers.common['x-access-token'] = `${store.getters["User/token"]}`
        return axios.post(apiUrl + '/articles/reading', data).then(response => response.data)
    },
}