import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
    isLogged: false,
    token: '',
    user: {
        statics: {
            buyAdvertisementCount : 0,
            saleAdvertisementCount: 0
        }
    },
    socialRegister: {
            needRegister: false,
            accessToken: '',
            providerId: '',
            user: {}
    }
}
export default{
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}