<template>
  <b-modal id="buyAdvertisement"  modal-class=" balance-modal"  hide-footer hide-header @show="getAdvertisement">
    <div class="title">
      <div class="col-md-8">
        <h4 v-if="!loading">{{ details.title }} </h4>
        <h4 v-if="loading">
          <b-skeleton animation="throb" class="bg-dark" v-if="loading" width="100%"></b-skeleton>
        </h4>
      </div>
      <div class="col-md-4 text-right">
        <label v-if="!loading">{{details.price | currency}} <span>TL</span></label>
          <b-skeleton animation="throb" class="bg-dark" v-if="loading" width="100%"></b-skeleton>
      </div>
      <div class="clearfix"></div>
    </div>
      <div class="row item" v-if="!buyAfter && !loading">
        <div class="col-md-6" v-if="!details.is_account">
          <label>KARAKTER ADINIZ</label>
          <input type="text" placeholder="Oyun içerisindeki karakter adınız." v-model="buyerCharacterName">
        </div>
        <div class="col-md-6" v-if="details.is_account">
          <label>Devredilecek Email Adresi</label>
          <input type="text" v-model="buyerEmail" placeholder="Hesabın devriledeceği email adresi">
        </div>
        <div class="col-md-6 text-right" >
          <button v-if="buttonLoading"  class="btn-hover"><i class="fas fa-spinner fa-spin"></i></button>
          <button class="btn-hover" @click="buyAdvertisement" v-if="!buttonLoading"><span><img :src="require('@/assets/images/btn-right.png')"  /> SATIN AL</span></button>
        </div>
      </div>
    <br/>
    <!-- İŞLEM SONRASI -->
    <br/>
    <div class="row-fluid buy-after" v-if="buyAfter">
      <img :src="require('@/assets/images/tick-o.png')"/>
      <p>
        Satın alma talebiniz başarıyla gerçekleştirildi.
        Satıcıya SMS ile bilgi verildi, en kısa süre içerisinde dönüş size dönüş sağlanacak.
      </p>
      <div class="alert alert-warning" role="alert" v-if="!details.is_account && details.itemDescription">
        {{details.itemDescription}}
      </div>
      <p>
        Ödemeniz KASAGAME havuz sisteminde bekletilmektedir.
        Ürünü teslim aldığınızı onayladıktan sonra ödeme satıcıya aktarılacaktır.
      </p>
      <label><router-link to="/main/addsorders">İŞLEM GEÇMİŞİMİ GÖRÜNTÜLE</router-link></label>
      <div class="clearfix"></div>
    </div>

  </b-modal>
</template>

<script>
import advertisementsService from "@/services/advertisementsService";
export default {
  name: "BuyAdvertisement",
  data() {
    return {
      id: this.$route.params.id,
      buyAfter: false,
      details: {},
      buyerCharacterName: "",
      buyerEmail: "",
      loading: true,
      buttonLoading : false
    }
  },
  methods: {
    sendMessage(message) {
      if(message.error) {
        this.$swal({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          icon: 'error',
          title: 'Hata',
          text: message.message,
        });
      }else {
        this.$swal({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          icon: 'success',
          title: 'Tebrikler!',
          text: message.message,
        });

      }
    },
    buyAdvertisement() {
      this.buttonLoading = true
      advertisementsService.buyAdvertisement({id: this.id, buyerCharacterName: this.buyerCharacterName, buyerEmail: this.buyerEmail})
      .then((res) => {
        this.buttonLoading = false
        if(res.state) {
          this.buyAfter = true
          this.sendMessage({error:false, message: "İlan başarıyla satın alındı"})
        }else {
          this.buyAfter = false
          this.sendMessage({error:true, message: res.message})
          if(res.balanceError) {
            this.$store.dispatch('depositDefault', {
              depositError: true,
              depositMessage: 'Bakiyeniz yetersiz, lütfen bakiye yükleyin.'
            })
            this.$bvModal.hide('buyAdvertisement')
            this.$bvModal.show('deposit')

          }
        }
      })
    },
    getAdvertisement() {
      this.loading = true
      advertisementsService.getAdvertisement({id: this.id})
          .then((res) => {
            this.loading = false
            if(res.list.length > 0) {
              this.loading = false
              this.details = res.list[0]
            }else{
              this.$router.push('/')
            }
          })
    },
  }

}
</script>

<style scoped>
.balance-modal {max-width:730px !important;}
.balance-modal .modal-content {background:#1E1622; padding:30px; border-radius:8px;}
.balance-modal .modal-title {margin:0px; padding:0px; font-size:16px; font-weight:600; color:#ffffff; padding-bottom:5px; border-bottom:1px solid #ffffff10;}

.balance-modal .title .col-md-8,
.balance-modal .title .col-md-4 {padding:0px; float:left;}
.balance-modal .title h4 {margin:0px; padding:0px; margin-top:10px; font-size:28px; font-weight:400; color:#ffffff80; }
.balance-modal .title {margin-top:30px;}
.balance-modal .title .col-md-4 label {border:1px solid #ffffff10; border-radius:6px; height:48px;
  padding:0px 15px; color:#ffffff80; font-size:34px; margin:0px;
}
.balance-modal .title .col-md-4 label span {font-size:24px;}
.balance-modal form {clear:both;}
.balance-modal form .item input {
  font-weight: 200;
  border-radius: 6px;
  border: 1px solid #ffffff50;
  background: 0 0;
  width: 100%;
  height: 50px;
  padding-left: 10px;
  color: #ffffff50;
  box-shadow: none;
  outline: 0;
}
.balance-modal .modal-close {right:-200px; top:-50px;}
.balance-modal form .item label {
  font-size:12px; font-weight:400; color:#ffffff40; margin-top:30px;
}
.balance-modal form .item button.btn-hover {position:relative; width:130px; height:48px;float:right; letter-spacing:1.5px; margin-top:60px;}

.buy-after {text-align:center;}
.buy-after img {margin-bottom:20px;}
.buy-after p {color:#ffffff; font-size:18px; margin-bottom:10px; font-weight:200;}
.buy-after a {color:#ffffff50; font-size:12px; font-weight:600;}
.buy-after a:hover {color:#ffffff;  }

.item input {
  font-weight: 200;
  border-radius: 6px;
  border: 1px solid #ffffff50;
  background: 0 0;
  width: 100%;
  height: 50px;
  padding-left: 10px;
  color: #ffffff50;
  box-shadow: none;
  outline: 0;
}
</style>