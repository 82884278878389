<template>
<div>
  <section class="pubpanel">
    <div class="pubpanel-title">
      <div class="container">
        <div class="bg-blur"></div>
        <h1>Ödeme Geçmişi</h1>
      </div>
    </div>
    <div class="container">
      <div class="sidebar-one">
        <LeftMenu :data="page"></LeftMenu>
      </div>
      <div class="pubpanel-content">
        <div class="stepullah">
          <b-alert variant="danger" show v-if="dataError"> Veriler Yüklenemedi.Lütfen sayfayı yenileyin veya daha sonra tekrar deneyin.</b-alert>,
          <b-container >
              <b-row lg="12" class="user-filters">
                  <b-col md="3">
                    <b-form-datepicker v-model="startDate" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" class="mb-2" size="sm" label-no-date-selected="Bşlgç. Tarihi" dark></b-form-datepicker>
                  </b-col>
                  <b-col md="3">
                    <b-form-datepicker v-model="endDate" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" class="mb-2" size="sm" label-no-date-selected="Bitiş Tarihi" dark></b-form-datepicker>
                  </b-col>
                  <b-col md="3">
                    <b-select size="sm" v-model="status"  :options="[{value: null, text: 'Tümü', select: true},{value: 0, text: 'Onay Bekleyen'},{value: 1, text: 'Onaylanan'},{value: 2, text: 'İptal Edilen'}]"></b-select>
                  </b-col>
                  <b-col md="3">
                    <b-button variant="info"  size="sm" @click="search">Sorgula</b-button>
                  </b-col>
              </b-row>
            <b-row lg="12">

              <b-table
                  v-if="!dataError"
                  :items="items"
                  :fields="fields"
                  :busy="isLoading"
                  hover
                  dark
                  striped
                  borderless
                  responsive
                  show-empty
              >
                <template v-slot:table-busy>
                  <div class="text-center text-white my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Yükleniyor...</strong>
                  </div>
                </template>
                <template v-slot:empty="">
                  <b-alert show variant="warning">
                    Kayıt bulunamadı.
                  </b-alert>
                </template>
                <template v-slot:cell(price)="data">
                  {{ data.item.price | currency}} TL
                </template>
                <template v-slot:cell(balance)="data">
                  {{ data.item.balance | currency}} TL
                </template>
                <template v-slot:cell(status)="data">
                  <b-badge pill variant="success" v-if="data.item.status == '1'">Onaylandı</b-badge>
                  <b-badge pill variant="warning" v-if="data.item.status == '0'">Onay Bekliyor</b-badge>
                  <b-badge pill variant="danger" v-if="data.item.status == '2'">İptal Edildi</b-badge>
                </template>
                <template v-slot:cell(date)="data">
                  {{ data.item.date  | formatDate }}
                </template>


              </b-table>
            </b-row>
          </b-container>
        </div>

      </div>
      <div class="clearfix"></div>
    </div>
  </section>
</div>
</template>

<script>
import LeftMenu from "@/components/User/LeftMenu";
import authService from "@/services/authService";

export default {
name: "Balance",
  data() {
    return  {
      startDate: "",
      endDate: "",
      status: "",
      isLoading: true,
      page: "balance",
      dataError: false,
      fields: [
        { key: 'channel.title',label: "Yatırım Kanalı" },
        { key: 'price',label: "Ödenecek Tutar" },
        { key: 'balance',label: "Yüklenecek Bakiye" },
        { key: 'date',label: "Tarih" },
        { key: 'status',label: "Durum" },
      ],
      items: []
    }
  },
  components: {
    LeftMenu,
  },
  methods:{
    search() {
      this.isLoading = true
      this.getDeposits()
    },
    getDeposits(){
      authService.getDeposits({startDate: this.startDate, endDate: this.endDate, status: this.status})
          .then((response) => {
            this.isLoading = false
            if(response.data.state){
              this.items = response.data.data
            }else
              this.dataError = true
          })
    }
  },
  async created() {
    this.getDeposits()
  },
  metaInfo() {
    return {
      title: 'Ödeme Geçmişi - Hesabım',
    }
  }
}
</script>

<style scoped>
/deep/.table-dark {
  background-color: #1E1622;
  color: #fff;
  border-radius: 10px;
}
.user-filters {
  background-color: #1E1622;
  color: #fff;
  border-radius: 10px;
  margin-bottom: 10px;
  height: 65px;
  padding: 14px;
}

</style>