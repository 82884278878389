<template>
<p class="text-white">Yönlendiriliyorsunuz..</p>
</template>

<script>
import authService from "@/services/authService";

export default {
  name: "Logout",
  async created() {
      authService.logout()
        .then(() => {
          this.$store.dispatch('userId')
          return this.$store.dispatch('User/logout')
                   })
    .then(() => {
        this.$router.push('/login')
    })

  }
}
</script>

<style scoped>

</style>