<template>
  <div>
    <section class="adver-page adveradd">
      <div class="page-title">
        <div class="container">
          <div class="bg-blur"></div>
          <div class="title-prev"><router-link to="/main/adds"><img :src="require('@/assets/images/title-prev.png')"/> Geri Dön</router-link></div>
          <h1>Reklam Oluştur</h1>
        </div>
      </div>
      <div class="adveradd-content">
        <div class="container">
          <form>
            <div class="row-fluid">
              <div class="col-md-6">
                <label>KAMPANYA ADI</label>
                <input type="text">
              </div>
              <div class="col-md-6">
                <label>YÖNLENDİRİLECEK ADRES</label>
                <input type="text" value="https://knightofworld.com">
              </div>
            </div>
            <div class="row-fluid adverfile">
              <div class="col-md-6">
                <label>GÖSTERİLECEK SAYFALAR</label>

                <div class="list">
                  <ul class="listbox">
                      <li v-for="(item,index) in data" :key="index" class="sub open">
                        <div class="custom-control custom-checkbox">
                          <input type="checkbox" class="custom-control-input" id="yayincilar" >
                          <label class="custom-control-label" for="yayincilar">
                            {{ item.title }}
                          </label>
                        </div>
                        <a href="#" class="toggle"><i class="fa fa-angle-down"></i></a>
                        <ul>
                          <li v-for="(categories, cateIndex) in item.settings" :key="cateIndex">
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" id="holmes" >
                              <label class="custom-control-label" for="holmes">
                                {{ categories.title }} ~ {{categories.click_price | currency}} TL
                              </label>
                            </div>
                          </li>
                        </ul>
                      </li>
                  </ul>
                </div>
              </div>
              <div class="col-md-6">
                <div class="row-fluid">
                  <label>REKLAM GÖRSELLERİ <img src="images/alert.png" data-toggle="tooltip" data-placement="right" title="" data-original-title="Uyarı alanıdır."></label>
                  <div class="left">
                    <label for="file" class="custom-file-upload file-type">
                      Gözat <i class="fal fa-cloud-upload"></i>
                    </label>
                    <input type="file" id="file">
                  </div>
                  <div class="right">
                    <div class="photos row">
                    </div>
                  </div>
                </div>
                <div class="row-fluid">
                  <label>REKLAM BÜTÇESİ </label>
                  <div class="left">
                    <b-select class="form-control" :options="[{value: 1, text: 'abc'},{value: '2', text: 'abc'}]"></b-select>
                  </div>
                  <div class="right">
                    <div class="addon">
                      <input type="text" class="form-control" placeholder="150">
                      <i class="fa fa-lira-sign"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row-fluid text-right">
              <button class="pub-btn btn-hover" onclick="window.location.href='reklam-ekle-2.php'">
                <span><img src="images/ar.png"> YAYINLA</span>
              </button>
            </div>
          </form>
          <div class="clearfix"></div>
        </div>
      </div>
      <div class="clearfix"></div>
    </section>
  </div>
</template>

<script>

import addsServices from "@/services/addsServices";

export default {
  name: "AddAdds",
  data() {
    return {
      data : [],
      loading: false
    }
  },
  methods: {
    sendMessage(m) {
      this.$swal({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        icon: m.icon,
        title: m.title,
        text: m.message,
      });
    },
    getCategories() {
      addsServices.getCategories()
      .then((res) => {
        if(res.state){
          this.data = res.data
        }else{
          this.sendMessage({icon: 'error', title: 'Hata', message: 'Veriler yüklenemedi, lütfen daha sonra tekrar deneyin.'})
          setTimeout(() => {
            this.$router.push({path: '/main/adds'})
          }, 1200)
        }
      })
    }
  },
  created() {
    this.getCategories()
  },
  metaInfo() {
    return {
      title: 'Reklam Oluştur - Hesabım',
    }
  },
}
</script>
<style scoped>
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem
}

.custom-control-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1rem
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0
}

.custom-control-input:checked~.custom-control-label::before {
  color: #fff; width:24px; height:24px;
}



.custom-control-input:disabled~.custom-control-label,
.custom-control-input[disabled]~.custom-control-label {
  color: #6c757d
}

.custom-control-input:disabled~.custom-control-label::before,
.custom-control-input[disabled]~.custom-control-label::before {
  background-color: #e9ecef
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top
}

.custom-control-label::before {
  position: absolute;
  top: .25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color:transparent;
  border: #ffffff60 solid 1px
}

.custom-control-label::after {
  position: absolute;
  top: .25rem;
  left: -1.5rem;
  display: block;
  width: 24px;
  height: 24px;
  content: "";
  background: no-repeat 50%/50% 50%
}

.custom-checkbox .custom-control-label::before {
  border-radius: .25rem;
  width:24px; height:24px;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
  background-image: url("~@/assets/images/tick.png")
}
.advertisements-page .sidebar .filt-item ul li .custom-checkbox .custom-control-label::before {width:15px; height:15px; }

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::before {

}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e")
}

.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before {
  background-color: rgba(0, 123, 255, .5)
}

.custom-checkbox .custom-control-input:disabled:indeterminate~.custom-control-label::before {
  background-color: rgba(0, 123, 255, .5)
}
.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}

.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}

.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  -webkit-transform: translateX(0.75rem);
  transform: translateX(0.75rem);
}

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(57, 97, 141, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-file-input[disabled] ~ .custom-file-label,
.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom-range:focus {
  outline: none;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #ffffff;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #1e152200 !important;
}

.custom-checkbox .custom-control-input:checked:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(30, 21, 34, 0)
}
.custom-checkbox .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem #1e152200
}
.custom-checkbox .custom-control-input:active ~ .custom-control-label::before {
  background-color: #1e152200;
}

</style>