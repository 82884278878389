<template>
  <div>
    <Header></Header>
    <section class="aboutus">
      <div class="page-title">
        <div class="container">
          <div class="bg-blur"></div>
          <h1>Kasagame.com Hakkında</h1>
        </div>
      </div>
      <div class="content">
        <div class="container">
          <p> <br>
            Kasagame.com, 2009 yılından bu yana 20'nin üzerinde çalışanıyla oyun sektöründe hem oyuncular hem de oyun
            sahipleri için hizmet veriyoruz. 13 yıldır gelişen teknolojileri yakından takip ediyor ve ürünlerimizi
            geliştiriyoruz. <br><br>
            Dijital Kod, Elektronik Kod, Skin, Item ve Char gibi oyunlarla alakalı ürünleri bulabileceğiniz bir bir
            platform olan Kasagame, bu alanda hizmet veren ilk Türk şirketlerinden bir tanesidir. 7/24 aktif ve dinamik
            kadromuzla müşterilerimize uygun fiyatlı ürünler sunmayı hedeflemekteyiz. <br><br>
            Sahip olduğumuz geniş ürün seçenekleri sayesinde her oyuncuya hitap etmeyi hedefliyoruz. Her türlü soru,
            şikayet ve görüşleriniz için Canlı Destek ekibimiz ile iletişime geçebilirsiniz. <br><br>

            Knight Online, Metin2, PUBG, CS:GO, Valorant ve Leauge of Legends gibi popüler oyunlara ait ürünleri
            kasagame.com'dan güvenli ve kolay ödeme seçenekleri sayesinde satın alabilirsiniz.
            <br><br>
          </p>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/Header';
import Footer from '@/components/Footer';

export default {
  name: "PageDetail",
  data() {
    return {
      loading: true,
    }
  },
  components: { Header, Footer },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { name: 'description', content: this.title },
        { name: 'keywords', content: this.title },
        { charset: 'utf-8' }
      ]
    }
  }

}
</script>

<style scoped>
.aboutus {
  background: #130E17;
}
</style>