import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
    siteSettings : [],
    homeSlider: [],
    gamesSlider: [],
    storeSlider: [],
    commissions: {},
    advertisements: [],
    advertisement_games: [],
    adds: [],
    giftCards: []
}
export default{
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}