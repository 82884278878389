<template>
  <b-modal id="confirm-advertisement" modal-class="balance-modal"  hide-footer hide-header @show="getAdvertisement">
    <div class="balance-modal-check" v-if="success">
      <img :src="require('@/assets/images/check.png')">
      <h5>
        <label>Teşekkürler.</label>
        <label>Ürünü teslim ettiğinizi onayladınız.</label>
        <label>Alıcı onay işlemini tamamladığında ücret hesabınıza aktarılacaktır.</label>
      </h5>
    </div>
    <div class="balance-after" v-else>

      <h3>Teslimat formundan sonra, alıcı teslim aldığını onaylarsa bakiye hesabınıza otomatik aktarılacak.</h3>
      <div class="item" v-if="!details.is_account">
        <div class="row">
          <div class="col-md-6">
            <h5 >Teslim alacak karakter adı:</h5>
            <input type="text" class="form-control" :value="details.buyerCharacterName" disabled>
          </div>
        </div>
      </div>
      <div class="item" v-if="details.is_account">
        <div class="row">
          <div class="col-md-12">
            <h5 >Devir edilecek Alıcı Email'i:</h5>
            <input type="text" class="form-control" :value="details.buyerEmail" disabled>
          </div>
          <div class="col-md-6">
            <h5 >Hesaba ait kullanıcı adı / email'i (varsa) :</h5>
            <b-form-input v-model="account_email"></b-form-input>
          </div>
          <div class="col-md-6">
            <h5 >Hesaba ait şifre (varsa) :</h5>
            <b-form-input v-model="account_password"></b-form-input>
          </div>
        </div>
      </div>
      <div class="item">
        <h5>EKRAN GÖRÜNTÜSÜ YÜKLE</h5>
        <div class="balance-file">
          <label for="file" class="custom-file-upload file-type" v-if="!imageSelected">
            Gözat <i class="fal fa-cloud-upload"></i>
          </label>
          <input type="file" multiple id="file" accept="image/jpeg, image/png, image/gif" v-on:change="onFileChange" v-if="!imageSelected">
          <div class="text-success" v-if="imageSelected"> <small>{{files.length}} adet görsel seçildi.</small> </div>
          <div  v-if="imageLenghtError"  class="text-danger">
            <small>En fazla 4 adet görsel yükleyebilirsiniz.</small>
          </div>

        </div>
      </div>
      <div class="photos row">

      </div>
      <div class="confirmation-button">
        <button class="btn-hover" v-if="!loading" @click="confirm"><span>ONAYLIYORUM</span></button>
        <button class="btn-problem" v-if="!loading">SORUN BİLDİR</button>
        <button class="btn-hover" v-if="loading"> <span>  <i class="fas fa-spinner fa-spin"></i></span> </button>
      </div>
    </div>


  </b-modal>
</template>

<script>
import advertisementsService from "@/services/advertisementsService";

export default {
  name: "Deposit",
  data() {
    return {
      loading: false,
      imageLenghtError: false,
      imageSelected: false,
      files: [],
      account_email: null,
      account_password: null,
      success: false,
      details: {
        is_account: false
      }
    }
  },
  props: ['id'],
  methods: {
    sendMessage(message) {
      this.$swal({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        icon: message.icon,
        title: message.title,
        text: message.text,
      });
    },
    confirm() {
      this.loading = true
      advertisementsService.confirmAdvertisement({data:{id: this.id,account_email: this.account_email, account_password: this.account_password}, files: this.files})
          .then((res) => {
            this.loading = false
            if(res.state)
            {
              this.success = true
              this.sendMessage({icon: 'success', title: 'Tebrikler!', text: 'Teslimat bilgileri kaydedildi.'})
            }
            else{
              this.sendMessage({icon: 'error', title: 'Hata!', text: res.message})
            }
          })
    },
    getAdvertisement() {
      if(this.id != 0) {
        advertisementsService.getMyOneAdvertisement(this.id)
            .then((res) => {
              if (res.state && res.data.status === 'waiting_saler') {
                this.success  = false
                this.details = res.data
              } else {
                this.sendMessage({icon: 'error', title: 'Hata!', text: res.message})
                this.$bvModal.hide('confirm-advertisement')
              }
            })
      }
      },
    onFileChange(e) {
      if (e.target.files.length > 4) {
        this.imageLenghtError = true
        this.files = null
      } else {
        let files = e.target.files || e.dataTransfer.files;
        if (!files.length)
          return;
        this.files = files
        this.imageLenghtError = false
        this.imageSelected = true
      }
    }
  },
  watch:{
        id: function () {
          this.getAdvertisement()
        }
  },
}
</script>

<style scoped>
input, textarea {
  background: 0 0!important;
  border: 1px solid #fff!important;
  color: #fff!important;
  transition: .5s all!important;
}
</style>