<template>
  <div>
    <a :href="link" :title="title" target="_blank"  v-if="removeclass">
      <img :src="banner" v-if="is_image" :class="{'right-banner': container === 'right_banner', 'left-banner': container === 'left_banner'}" class="img-fluid mx-auto w-100" >
      <video loop muted autoplay v-else>
        <source :src="banner" type="video/webm">
      </video>
    </a>

    <section class="banner ads-container" v-else>
      <div class="container " v-if="found">
        <a :href="link" :title="title" target="_blank">
          <img :src="banner" v-if="is_image">
          <video loop muted autoplay v-else>
            <source :src="banner" type="video/webm">
          </video>
        </a>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  name: "Adds",
  data() {
    return {
      adds: this.$store.getters['siteInfo/adds'],
      title: '',
      banner: '',
      link: '',
      found: false,
      is_image: false,
      page: ''
    }
  },
  props: ["container", 'removeclass'],
  created() {
    let findIndex = this.adds.findIndex( e=> e.page === this.container)
    if(findIndex > -1){
      this.title = this.adds[findIndex].title
      this.link = this.adds[findIndex].link
      this.banner = this.adds[findIndex].banner
      this.is_image = this.adds[findIndex].is_image
      this.page = this.adds[findIndex].page

      this.found = true
    }
  }
}
</script>

<style scoped>
.right-banner{
  padding-left: 20px;
}
.left-banner{
  padding-right: 20px;
}
</style>