<template>
  <b-nav-item-dropdown class="login"  v-if="$store.getters['User/isLoggedIn']">
    <template v-slot:button-content>
      <div class="avatar">
        <img :src="user.profilePic" /> <span class="hidden-xs" >{{ user.name }}</span>
        <span class="badge" v-if="$store.getters['User/user'].statics.total > 0"><i class="fas fa-circle text-danger Blink" /></span>
        <i class="fa fa-ellipsis-v-alt"></i>
      </div>
    </template>
    <b-dropdown-item to="/main">
      <i class="fal fa-user"></i> Hesabım
    </b-dropdown-item>
    <b-dropdown-item to="/main/balance">
      <i class="fal fa-wallet"></i> Bakiyem <span class="wallet">{{ user.totalBalance }} TL</span>
    </b-dropdown-item>
    <b-dropdown-item  to="/main/orders">
      <i class="fas fa-shopping-cart"></i> Siparişlerim
      <span class="" v-if="$store.getters['User/user'].statics.buyAdvertisementCount > 0"><i class="fas fa-circle text-danger Blink" /></span>

    </b-dropdown-item>
    <b-dropdown-item  to="/main/messages"><i class="fal fa-envelope"></i> Mesajlarım
      <span class="" v-if="$store.getters['User/user'].statics.sendingNewMessagesCount > 0 || $store.getters['User/user'].statics.newMessagesCount > 0"><i class="fas fa-circle text-danger Blink" /></span>
    </b-dropdown-item>
    <b-dropdown-item  :href="'https://merchant.kasagame.com/#/?token='+token" target="_blank"><i class="fal fa-store"></i> Mağazalarım</b-dropdown-item>
    <b-dropdown-item to="/main/advertisements">
      <i class="fal fa-box"></i> İlanlarım
      <span class="" v-if="$store.getters['User/user'].statics.saleAdvertisementCount > 0"><i class="fas fa-circle text-danger Blink" /></span>
    </b-dropdown-item>
    <b-dropdown-item  to="/main/articles"><i class="fal fa-newspaper"></i> Makalelerim</b-dropdown-item>
    <b-dropdown-item  to="/main/streaming"><i class="fal fa-video"></i> Yayıncı Paneli</b-dropdown-item>
    <div class="dropdown-divider"></div>
    <b-dropdown-item :to="{path: '/profile/'+ user.username}"><i class="fal fa-user"></i> Profilim</b-dropdown-item>
    <b-dropdown-item :to="{path: '/logout'}"><i class="fal fa-sign-out"></i> Oturumu Kapat</b-dropdown-item>

  </b-nav-item-dropdown>
  <li class="login dropdown" v-else>
    <router-link to="/login">
      <a ><img :src="require('../../assets/images/oturum-icon.svg')" class="login-icon"/> <span class="hidden-xs">Oturum Açın</span></a>
    </router-link>
  </li>
</template>

<script>
import { mapGetters } from 'vuex';
import AuthService from "@/services/authService";
export default {
  name: "LoginHeader",
  data() {
    return {
      token: this.$store.getters["User/token"]
    }
  },
  computed: {
    ...mapGetters({
      user: 'User/user'
    })
  },
  methods: {
    loginCheck() {
      if(this.$store.getters["User/isLoggedIn"]) {
        const response = AuthService.getSecretContent()
        response.then((result) => {
          if(!result.state){
            this.$store.dispatch('User/logout')
            this.$router.push('/login')
          }else {
            this.$store.dispatch('User/userLogin', result)

          }
        })
      }
    }
  },
  created() {
    this.loginCheck()
  }
}
</script>

<style scoped lang="css">
/deep/ .nav-link {
  padding: 0em 1em;
}
/deep/ .dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
/deep/ .dropdown-item {
  padding: 10px 15px;
  line-height: 17px;
  font-size: 13px;
  font-weight: 400;
  transition: all .1s ease-in;
}
/deep/  .dropdown-menu li {
  opacity: 1;
  background: 0 0;
  margin-left: 0;
  width: 100%;
}
.avatar  img {
  width: 27px;
  height: 27px;
  border-radius: 50%;
}
</style>