<template>
  <div>
    <Header></Header>
    <section class="login-page">
      <div class="page-title">
        <div class="container">
          <div class="bg-blur"></div>
          <h1>Parolamı Unuttum</h1>
        </div>
      </div>
      <div class="login-content">
        <div class="container">

          <div class="login-right">
            <div class="login-form">

              <form class="needs-validation" novalidate @submit.prevent="getForm" v-if="!smsForm">
                <b-alert variant="dark" show>Doğrulama sonrasında yeni parolanız telefonunuza SMS olarak gönderilecektir</b-alert>

                <div class="logfor-group">
                  <label>E-Posta Adresiniz </label>
                  <input type="email" class="form-control" v-model="email">
                  <div v-if="emailError == true"  class="invalid-feedback feedback-pos" style="display: block">
                    Böyle bir kullanıcı bulunamadı.
                  </div>

                </div>

                <div class="logfor-group">
                  <div class="">
                    <div v-if="!isWait">
                      <div class="register-button">
                        <button class="btn-hover" ><span><img :src="require('@/assets/images/lr.png')"/> GÖNDER</span></button>
                      </div>
                    </div>
                    <div v-if="isWait" class="register-button">
                      <button class="btn-hover " > <span>  <i class="fas fa-spinner fa-spin"></i></span> </button>
                    </div>

                  </div>
                </div>

              </form>


              <form class="needs-validation" novalidate @submit.prevent="getSmsForm" v-else>
                <b-alert variant="success" v-if="passwordChangeState" show> {{smsErrorMessage}} </b-alert>
                <div class="logfor-group">
                  <label>SMS Kodunuz:</label>
                  <input type="email" class="form-control" v-model="smsCode">
                  <div  class="invalid-feedback feedback-pos" style="display: block" v-if="!smsError">
                    Lütfen telefonunuza gönderilen <b>doğrulama kodunu</b> girin.
                  </div>
                  <div  class="invalid-feedback feedback-pos" style="display: block" v-if="smsError">
                    {{ smsErrorMessage }}
                  </div>


                </div>


                <div class="logfor-group">
                  <div class="">

                    <div v-if="!isWait">
                      <div class="register-button">
                        <button class="btn-hover" ><span><img :src="require('@/assets/images/lr.png')"/> GÖNDER</span></button>
                      </div>
                    </div>
                    <div v-if="isWait" class="register-button">
                      <button class="btn-hover " > <span>  <i class="fas fa-spinner fa-spin"></i></span> </button>
                    </div>
                  </div>
                </div>
              </form>

            </div>
          </div>
          <div class="login-left">
            <div class="login-left-item">
              <div class="lole-img"><img :src="require('@/assets/images/l1.png')"/></div>
              <div class="lole-text">
                <h4>Güvenli Platform</h4>
                <p>
                  Kasagame çatısı altında üyelik bilgileriniz ve buna bağlı kişisel/banka/telefon gibi bilgileriniz güven altındadır, Hiçbir şekilde üçüncü parti servislerle paylaşılmaz. Siz alışverişinizi tamamlamadan paranız karşı tarafa gönderilmez, Kasagame Havuz Sistemi’nde bekletilir.
                </p>
              </div>
              <div class="clearfix"></div>
            </div>
            <div class="login-left-item">
              <div class="lole-img"><img :src="require('@/assets/images/l2.png')"/></div>
              <div class="lole-text">
                <h4>Kolay ve Avantajlı Alışveriş</h4>
                <p>
                  Dilediğiniz ürünleri rahatlıkla alabilir ve satabilirsiniz. Ödemenizi güvenli bir şekilde hesabınıza aktarabilirsiniz. Kampanyalar, indirimler ya da özel fırsatlar içeride sizi bekliyor.
                </p>
              </div>
              <div class="clearfix"></div>
            </div>
            <div class="login-left-item">
              <div class="lole-img"><img :src="require('@/assets/images/l3.png')"/></div>
              <div class="lole-text">
                <h4>İçerik Üret Para Kazan</h4>
                <p>
                  Kasagame'in Haberler kategorisine güncel ve kopya olmayan içerikler üreterek Kasa Kredi kazanabilir, bu kredileri dilediğiniz gibi sistem içerisinde harcayabilirsiniz.
                </p>
              </div>
              <div class="clearfix"></div>
            </div>
            <div class="login-left-item">
              <div class="lole-img"><img :src="require('@/assets/images/l4.png')"/></div>
              <div class="lole-text">
                <h4>Hızlı Bağış Al/Yap</h4>
                <p>
                  Siz de YouTube, Twitch, Mixer ya da Dlive gibi platformlarda yayın yapıyorsanız rahatlıkla bağış alabilirsiniz.
                </p>
              </div>
              <div class="clearfix"></div>
            </div>
            <div class="login-left-item">
              <div class="lole-img"><img :src="require('@/assets/images/l5.png')"/></div>
              <div class="lole-text">
                <h4>Düşük Komisyon</h4>
                <p>
                  İnternet sitemiz üzerinden yapılan her işlem için düşük komisyon ücreti ödersiniz.
                </p>
              </div>
              <div class="clearfix"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import authService from "@/services/authService";
export default {
  name: "Login",
  computed: {
    ...mapGetters({
      siteSettings: 'siteInfo/siteSettings'
    })
  },
  data() {
    return {
      isValidForm : true,
      smsForm: false,
      isWait: false,
      emailError: false,
      smsError: false,
      smsErrorMessage: "",
      passwordChangeState: false,
      email: "",
      smsCode: ""
    }
  },
  components: {
    Header,
    Footer
  },
  methods: {
    getSmsForm() {
      this.isWait = true
      authService.forgotPassword({value: this.email, step: 2, code: this.smsCode})
      .then((res) => {
          if(res.data.state) {
            this.isWait = false
            this.smsError = false
            this.passwordChangeState = true
            this.smsErrorMessage = res.data.message
            setTimeout(() => {
              this.$router.push({path: "/login"})
            }, 3000)

          }else {
            this.isWait = false
            this.smsError = true
            this.smsErrorMessage = res.data.message
            this.passwordChangeState = false
          }
      })
    },
    getForm() {
      this.isWait = true
      authService.userCheck({type: 'email', value: this.email})
      .then((res) =>{
        if(res) {
          this.emailError = true
          this.isWait = false
        }else {
          authService.forgotPassword( {value: this.email, step: 1})
            .then((forgotResponse) => {
              if(forgotResponse.data.state == true)
              {
                this.emailError = false
                this.smsForm = true
                this.isWait = false

              }else {
                this.emailError = true
                this.smsForm = false
                this.isWait = false

              }
            })
        }
      });

    },
    signIn() {
      authService.userLogin({email: this.email, password: this.password})
          .then((res) => {
            if(res.state){
              this.$swal({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                icon: 'success',
                title: 'Başarıyla giriş yaptınız',
                text: 'yönlendiriliyorsunuz..',
              });
            }else {
              this.$swal({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                icon: 'error',
                title: '',
                text: res.message,
              });

            }
          })
    }
  },
  metaInfo () {
    return {
      title: "Şifremi Unuttum - " + this.siteSettings[0].title,
      meta: [
        { name: 'description', content: "Şifremi Unuttum - " + this.siteSettings[0].description},
        { name: 'keywords', content: "Şifremi Unuttum - " + this.siteSettings[0].keywords},
        { charset: 'utf-8' }
      ]
    }
  }

}
</script>

<style scoped>

</style>