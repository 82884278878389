<template>
  <b-modal id="deposit" @show="getDepositError()" @hide="closeDeposit()" modal-class="balance-modal"  hide-footer hide-header>
    <div class="balmod-title">
      BAKİYE YÜKLE
    </div>
    <b-alert show variant="danger" v-if="depositError">
      {{ depositMessage }}
    </b-alert>

    <div class="row item btn-group btn-group-toggle" data-toggle="buttons">
      <h4>ÖDEME YÖNTEMİ SEÇİNİZ</h4>
      <div class="col-md-4" v-for="(item, index) in channels" :key="index">
        <label class="btn btn-secondary" :class="{'active' : methodId === index}" >
          <input type="radio" name="radio" :value="index" v-model="methodId">
          <span class="checkmark"></span>
          <strong>{{ item.title }}</strong>
        </label>
      </div>
    </div>
    <div class="row item">
      <h4>NE KADAR YÜKLEMEK İSTİYORSUNUZ ?</h4>
      <div class="col-md-12 balance-try">
        <div class="btn-group btn-group-toggle" data-toggle="buttons">
          <label class="btn btn-secondary" @click="selectAmount(20)" :class="{'active' : amount === 20}">
            <input type="radio"> 20 TL
          </label>
          <label class="btn btn-secondary" @click="selectAmount(50)" :class="{'active' : amount === 50}">
            <input type="radio"> 50 TL
          </label>
          <label class="btn btn-secondary" @click="selectAmount(100)" :class="{'active' : amount === 100}">
            <input type="radio"> 100 TL
          </label>
          <label class="btn btn-secondary" @click="selectAmount(200)" :class="{'active' : amount === 200}">
            <input type="radio"> 200 TL
          </label>
        </div>
        <div class="input-group">
          <input v-model="amount" type="text" class="form-control" placeholder="veya miktar giriniz" >
          <div class="input-group-append">
            <span class="input-group-text" id="basic-addon2">TL</span>
          </div>
        </div>
        <div class="input-group" >
        </div>
      </div>
      <div class="col-lg-12" v-if="methodId === 8">
        <b-alert show variant="warning"> <strong> <i class="fas fa-exclamation-triangle"> </i> Dikkat: </strong> Komisyon dahil ödemeniz gereken tutar <strong>{{ amount * 2 | currency}} TL</strong> olacaktır.</b-alert>
      </div>
    </div>
    <div class="item text-right">
      <button class="btn-hover" v-if="!btnLoading && !error" @click="getLink"><span><img :src="require('@/assets/images/ar.png')"/> SONRAKİ ADIM</span></button>
      <button class="btn-hover" v-if="btnLoading"><span><i class="fa fa-spinner fa-spin"/></span></button>
      <button class="btn btn-dark " v-if="error" disabled><span><i class="fas fa-minus-circle"/></span></button>
    </div>

  </b-modal>
</template>

<script>
import paymentService from "@/services/paymentService";
export default {
  name: "Deposit",
  data() {
    return {
      depositError: this.$store.getters.depositError,
      depositMessage: this.$store.getters.depositMessage,
      channelsLoading : true,
      channels: [],
      methodId: null,
      amount: '',
      btnLoading: false,
      error: true,
      currency: {
        usd: 0,
        euro: 0
      },
      exchange_checked: false,
    }
  },
  methods:  {
    selectAmount(amount) {
      this.amount = amount
    },
    clientMessage(msg) {
      this.$swal({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        icon: msg.icon,
        title: msg.title,
        text: msg.text,
      });
    },
    getLink() {
      this.btnLoading = true
      paymentService.getPaymentLink({methodId: this.channels[this.methodId].id, amount: this.amount})
      .then((res) =>{
        this.btnLoading = false
          if(res.state){
            this.clientMessage({icon: 'success', title: 'Yönlendiriliyorsunuz..', text: "Lütfen bekleyin.."})
            this.$store.dispatch('Pay', {link: res.paymentLink, isIframe: res.isIframe, paymentActive: true})
            setTimeout(() => {
              this.$router.push({path: '/pay'})
            }, 350)
          }else{
            this.clientMessage({icon: 'error', title: 'Ops!', text: res.message})
          }
      })
    },
    closeDeposit() {
      this.$store.dispatch('depositDefault', {depositError: false, depositMessage: ""})
    },
    getDepositError() {
      this.btnLoading = false
      this.depositError = this.$store.getters.depositError
      this.depositMessage = this.$store.getters.depositMessage
    },
    getChannels() {
      paymentService.getChannels()
      .then((res)=> {
        this.channelsLoading = false
        if(res.state)
          res.data.map((item) => {
            this.channels.push(item)
          })
        else
          this.clientMessage({icon: 'error', title: 'Ops!', text: res.message})
      })
    }
  },
  created() {
    this.getChannels()
  },
  watch: {
    amount: function () {
      if(!this.methodId == null)
        this.clientMessage({icon: 'info', title: '', text: 'Lütfen ödeme kanalını seçiniz.'})
      else{
        if(parseFloat(this.amount) > parseFloat(this.channels[this.methodId].maximum)){
          this.error = true
          this.clientMessage({icon: 'error', title: '', text: 'Bu ödeme kanalı ile en fazla '+this.channels[this.methodId].maximum+' TL bakiye yükleyebilirsiniz.'})
        }else if(parseFloat(this.amount) < parseFloat(this.channels[this.methodId].minimum)) {
          this.error = true
          this.clientMessage({
            icon: 'error',
            title: '',
            text: 'Bu ödeme kanalı ile en az ' + this.channels[this.methodId].minimum + ' TL bakiye yükleyebilirsiniz.'
          })
        }else {
          this.error = false
        }
      }
    },
    methodId : function () {
      if(!this.amount) {
        this.clientMessage({icon: 'info', title: '', text: 'Lütfen yüklemek istediğiniz tutarı seçiniz.'})
        this.error = true
      } else{
        if(parseFloat(this.amount) > parseFloat(this.channels[this.methodId].maximum)){
          this.error = true
          this.clientMessage({icon: 'error', title: '', text: 'Bu ödeme kanalı ile en fazla '+this.channels[this.methodId].maximum+' TL bakiye yükleyebilirsiniz.'})
        }else if(parseFloat(this.amount) < parseFloat(this.channels[this.methodId].minimum)) {
          this.error = true
          this.clientMessage({
            icon: 'error',
            title: '',
            text: 'Bu ödeme kanalı ile en az ' + this.channels[this.methodId].minimum + ' TL bakiye yükleyebilirsiniz.'
          })
        }else {
          this.error = false
        }
      }
    }
  }
}
</script>

<style scoped>
input, textarea {
  background: 0 0!important;
  border: 1px solid #fff!important;
  color: #fff!important;
  transition: .5s all!important;
}
</style>