<template>
  <div>
    <Header></Header>
    <div class="row" >
      <div class="col-md-2 d-none d-sm-block" style="background: #140f17; padding-left: 0px !important; padding-right: 0px !important; padding-top: 90px;">
        <Adds container="right_banner" :removeclass="true"></Adds>
      </div>
      <div class="col-md-8">
        <Slider :data="homeSlider" v-if="$store.getters['siteInfo/homeSlider'].length > 0" style="background: #140f17 !important; padding-block-end: 25px;"></Slider>
        <Adds container="homepage_slider"></Adds>
          <MallsSlider v-if="$store.getters['siteInfo/storeSlider'].length > 0"></MallsSlider>
          <Adds container="homepage_stores"></Adds>
          <Sales  v-if="$store.getters['siteInfo/advertisements'].length > 0"></Sales>
          <Adds container="homepage_advertisements"></Adds>
          <GamesSlider :data="gamesSlider" v-if="$store.getters['siteInfo/gamesSlider'].length > 0"></GamesSlider>
        <Adds container="homepage_games"></Adds>
        <GiftCards v-if="$store.getters['siteInfo/giftCards'].length > 0"></GiftCards>
        <Adds container="gift_cards"></Adds>
        <News ></News>
        <Adds container="homepage_news"></Adds>
        <Streamers ></Streamers>
        <Adds container="homepage_streamers"></Adds>
      </div>
      <div class="col-md-2 d-none d-sm-block"  style="background: #140f17; padding-left: 0px !important; padding-right: 0px !important; padding-top: 90px">
        <Adds container="left_banner" :removeclass="true"></Adds>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import Slider from "@/components/Home/Slider";
import GamesSlider from "@/components/Home/GamesSlider";
import Adds from "@/components/Adds/Adds";
import MallsSlider from "@/components/Home/MallsSlider";
import Sales from "@/components/Home/Sales";
import News from "@/components/Home/News";
import Streamers from "@/components/Home/Streamers";
import GiftCards from "@/components/Home/GiftCards";
import { mapGetters } from 'vuex';

export default {
  name: "Home",
  components: {
    Slider,
    GamesSlider,
    Header,
    Footer,
    Adds,
    MallsSlider,
    Sales,
    News,
    Streamers,
    GiftCards
  },
  data() {
    return {
      adds : this.$store.getters

    }
  },
  computed: {
    ...mapGetters({
      homeSlider: 'siteInfo/homeSlider',
      gamesSlider: 'siteInfo/gamesSlider',
      siteSettings: 'siteInfo/siteSettings'
    })
  },
  metaInfo () {
    return {
      title: this.siteSettings[0].title,
      meta: [
        { name: 'description', content: this.siteSettings[0].description },
        { name: 'keywords', content: this.siteSettings[0].keywords },
        { charset: 'utf-8' }
      ]
    }
  },
}
</script>

<style scoped>

</style>