<template>
  <div>
    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <div class="logo">
              <router-link to="/"><img :src="require('../assets/images/kasagame-logo.svg')" /></router-link>
            </div>
            <small class="copyright"> © 2021 kasagame.com</small>

            <ul class="socials">
              <li><a href="#"><i class="fab fa-facebook"></i></a></li>
              <li><a href="#"><i class="fab fa-twitter"></i></a></li>
              <li><a href="#"><i class="fab fa-instagram"></i></a></li>
              <li><a href="#"><i class="fab fa-youtube"></i></a></li>
            </ul>
          </div>
          <div class="col-md-3 about">
            Decade Bilişim LTD. Serbest Liman ve Bölge , Posta Kutusu No : 568 Gazimağusa / Kıbrıs
          </div>


          <div class="col-md-5 offset-md-1 ">
            <div class="row">
              <ul class="col-md-6 links">
                <li>
                  <router-link to="/games"><a>OYUNLAR</a></router-link>
                </li>
                <li>
                  <router-link to="/stores">MAĞAZALAR</router-link>
                </li>
                <li>
                  <router-link to="/advertisements">İLANLAR</router-link>
                </li>
                <li>
                  <router-link to="/streamers">YAYINCILAR</router-link>
                </li>
                <li>
                  <router-link to="/news">HABERLER</router-link>
                </li>
              </ul>
              <ul class="col-md-6 links">
                <li>
                  <router-link to="/destek">DESTEK</router-link>
                </li>
                <li>
                  <router-link to="/contact">İLETİŞİM</router-link>
                </li>
                <li>
                  <router-link to="/hakkimizda">HAKKIMIZDA</router-link>
                </li>
                <li>
                  <router-link to="/gizlilik-sozlesmesi">GİZLİLİK VE KVKK SÖZLEŞMESİ</router-link>
                </li>
                <li>
                  <router-link to="/page/iadesartlari">İADE ŞARTLARI</router-link>
                </li>
                <li>
                  <router-link to="/satis-sozlesmesi">ÜYELİK VE SATIŞ SÖZLEŞMESİ</router-link>
                </li>
              </ul>
            </div>

          </div>
        </div>
      </div>
    </footer>
    <Deposit v-if="$store.getters['User/isLoggedIn']"></Deposit>
  </div>
</template>

<script>
import Deposit from "@/components/Modals/Deposit";
export default {
  name: "Footer",
  data() {
    return {

    }
  },
  components: {
    Deposit
  },
  mounted() {
    this.runDesk()
  },
  methods: {
    runDesk() {
      let recaptchaScript = document.createElement('script')
      recaptchaScript.type = "application/javascript"
      recaptchaScript.text = this.$store.getters['siteInfo/siteSettings'][0].live_desk_code
      document.head.appendChild(recaptchaScript)
    }

  },

}
</script>

<style scoped>
.footer {
  background: #1e1622;
  padding: 50px 0px;
  color: #8e8e8e;
}

.footer a {
  color: #8e8e8e;
  transition: all .1s ease;
}

.footer a:hover {
  color: #FFFFFF;
}

.footer .logo {
  font-size: 35px;
  font-weight: bold;
  text-decoration: none;
  line-height: 27px;
}

.footer .copyright {
  display: block;
  font-size: 13px;
  font-weight: 300;
  margin-bottom: 50px;
}

.footer .socials {
  display: inline-flex;
  margin: 0;
}

.footer .socials li {
  margin-right: 10px;
}

.footer .about {
  font-size: 13px;
  font-weight: 300;
}

.footer ul.links li a {
  font-size: 13px;
  font-weight: normal;
}

.footer {
  background: #1e1622;
  padding: 50px 0px;
  color: #8e8e8e;
}

.footer a {
  color: #8e8e8e;
  transition: all .1s ease;
}

.footer a:hover {
  color: #FFFFFF;
}

.footer .logo {
  font-size: 35px;
  font-weight: bold;
  text-decoration: none;
  line-height: 27px;
}

.footer .copyright {
  display: block;
  font-size: 13px;
  font-weight: 300;
  margin-bottom: 50px;
}

.footer .socials {
  display: inline-flex;
  margin: 0;
}

.footer .socials li {
  margin-right: 10px;
}

.footer .about {
  font-size: 13px;
  font-weight: 300;
}

.footer ul.links li a {
  font-size: 13px;
  font-weight: normal;
}
</style>