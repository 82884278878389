<template>
  <section class="article-page">
    <div class="page-title">
      <div class="container">
        <div class="bg-blur"></div>
        <div class="title-prev"><router-link :to="{path: '/main/articles'}"><img :src="require('@/assets/images/title-prev.png')"> Geri Dön</router-link></div>
        <h1>Makale Ekle</h1>

      </div>

    </div>
    <div class="article-content">
      <div class="container">
        <div class="article-form">
            <div class="row">
              <div class="col-md-6">
                <label>MAKALE BAŞLIĞI</label>
                <input type="text" v-model="title">
              </div>
              <div class="col-md-6">
                <label>KATEGORİ</label>
                <b-select :options="[{value:'1',text:'Genel'}]" v-model="category" >
                </b-select>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 text-white">
                <label>İÇERİK</label>
                <vue-editor id="editor" useCustomImageHandler @image-added="handleImageAdded" v-model="htmlForEditor"></vue-editor>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <label>ÖNE ÇIKAN GÖRSEL <img :src="require('@/assets/images/asoru.png')"></label>
                <label for="file" class="custom-file-upload file-type" v-if="!imageSelected">
                  Gözat <i class="fal fa-cloud-upload"></i>
                </label>
                <input type="file" multiple id="file" accept="image/jpeg, image/png, image/gif" v-on:change="onFileChange" v-if="!imageSelected"/>
                <div class="text-success" v-if="imageSelected"> <small>{{files.length}} adet görsel seçildi.</small> </div>
                <div  v-if="imageLengthError"  class="text-danger">
                  <small>En fazla 1 adet görsel yükleyebilirsiniz.</small>
                </div>
              </div>
              <div class="col-md-6">
                <label>ETİKETLER</label>
                <input type="text" placeholder="Etiketleri virgül ile ayırarak giriniz." v-model="tags">
              </div>
            </div>
            <div class="row form-footer  ">
              <div class="col-md-12 ">
                <div class="col-md-6 float-right">
                  <ul class="">
                    <li v-if="!loading"><button class="btn-save" @click="saveDraft">TASLAK OLARAK KAYDET</button></li>
                    <li v-if="!loading"><button class="btn-hover" @click="saveArticle"><span><img :src="require('@/assets/images/ar.png')"> YAYINLA</span></button></li>
                    <li v-if="loading">
                      <button class=" btn-hover " v-if="loading"> <span>  <i class="fas fa-spinner fa-spin"></i></span> </button>
                    </li>

                  </ul>
                </div>

              </div>
            </div>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
  </section>
</template>

<script>
import { VueEditor } from "vue2-editor";
import authService from "@/services/authService";

export default {
  name: "AddArticle",
  data() {
    return {
      loading: false,
      title: '',
      category: '1',
      tags: '',
      draft: false,
      htmlForEditor: "",
      files: [],
      imageSelected: false,
      imageLengthError: false,
      editorSettings: {
        modules: {
          imageResize: {}
        }
      }
    }
  },
  components: {
    VueEditor
  },
  methods:{
    onFileChange(e) {
      if (e.target.files.length > 1) {
        this.imageLengthError = true
        this.files = null
      } else {
        let files = e.target.files || e.dataTransfer.files;
        if (!files.length)
          return;
        this.files = files
        this.imageLenghtError = false
        this.imageSelected = true
      }
    },
    message(data){
      this.$swal({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        icon: data.icon,
        title: data.title,
        text: data.message,
      });
    },
    saveDraft(){
      this.draft = true
      this.saveArticle()
    },
    saveArticle() {
      this.loading = true
      authService.createArticle({data: {title: this.title,category: this.category,content: this.htmlForEditor, tags: this.tags, draft: this.draft},files: this.files})
      .then((res) => {
        if(res.state) {
          if(this.draft){
            this.message({icon: 'success', title: 'Teşekkürler', message: 'Makale taslaklara eklendi, yönlendiriliyorsunuz..'})
          }else{
            this.message({icon: 'success', title: 'Teşekkürler', message: 'Makaleniz başarıyla eklendi, en kısa süre içerisinde yayınlanacak!'})
          }

          this.title = ''
          this.category = ''
          this.content = ''
          this.tags = ''
          this.imageSelected = false
          this.loading = false
          this.draft = false
          this.files = []
          setTimeout(() =>{
            this.$router.push('/main/articles')
          },1500)
        }else{
          this.loading = false
          this.message({icon: 'error', title: 'Ops!', message: res.message})
        }
      })
    },
    handleImageAdded: function(file, Editor, cursorLocation, resetUploader) {
      // An example of using FormData
      // NOTE: Your key could be different such as:
      // formData.append('file', file)

      authService.userImageUpload(file)
      .then((res) =>{
        let url = res.url; // Get url from response
        Editor.insertEmbed(cursorLocation, "image", url);
        resetUploader();
      })
    }
  },
  metaInfo() {
    return {
      title: 'Makale Ekle - Hesabım',
    }
  }

}

</script>

<style scoped>
.article-page .article-form .row select {
  width: 100%;
  height: 48px;
  border-radius: 8px;
  border: 1px solid #FFFFFF50;
  background: transparent;
  color: #fff;
  outline: none;
  box-shadow: none;
  padding-left: 10px;
}
</style>