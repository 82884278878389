<template>
  <section class="pubpanel">
    <div class="pubpanel-title">
      <div class="container">
        <div class="bg-blur"></div>
        <h1>Yayıncı Paneli </h1>
      </div>
    </div>
    <div class="container">
      <div class="sidebar-one">
        <LeftMenu :data="page"></LeftMenu>
      </div>
      <div v-if="!streamerCheckLoading">
        <RegisterStreaming v-if="!isStreamer"></RegisterStreaming>
        <StreamerDetail v-else></StreamerDetail>
      </div>
      <div class="  text-center" v-if="streamerCheckLoading">
          <i class="fas fa-fas fa-spinner fa-spin text-success"></i>
          <p class="text-white"> Yükleniyor .. </p>
      </div>
      <div class="clearfix"></div>
    </div>
  </section>
</template>

<script>
import LeftMenu from "@/components/User/LeftMenu";
import RegisterStreaming from "@/components/User/Streaming/RegisterStreaming";
import streamService from "@/services/streamService";
import StreamerDetail from "@/components/User/Streaming/StreamerDetail";

export default {
  name: "Streaming",
  data() {
    return  {
      page: "streaming",
      isStreamer : false,
      streamerCheckLoading: true,
      code: this.$route.query.code,
    }
  },
  components: {LeftMenu, RegisterStreaming, StreamerDetail},
  methods: {
      checkStreamer() {
        streamService.isStreamer({id: this.$store.getters.userId})
        .then((res) => {
          this.streamerCheckLoading = false
          this.isStreamer = res.state
        })
      },
    verifyCode() {
        streamService.verifyStreamlabsToken({code: this.code})
      .then((res) => {
        if(res.state){
          this.checkStreamer()
        }else
        {
          this.clientMessage({icon: 'error', title:'ops!', text: res.message})
        }
      })
    },
    clientMessage(msg) {
      this.$swal({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        icon: msg.icon,
        title: msg.title,
        text: msg.text,
      });
    },

  },
  async created() {
    if(this.code){
      this.streamerCheckLoading = true
      this.verifyCode()
    }else{
      this.checkStreamer()
    }
  },
  watch:
      {
      },
  metaInfo() {
    return {
      title: 'Yayıncı Paneli - Hesabım',
    }
  }
}
</script>

<style scoped>

</style>