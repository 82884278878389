<template>
  <div>
    <Header></Header>
    <div class="clearfix"></div>
    <section class="profile-page">
      <div class="page-title">
        <div class="container">
          <div class="bg-blur"></div>
          <div class="profile-title" v-if="loading">
            <div class="title-img">
              <b-skeleton type="avatar" width="100%" height="100%"></b-skeleton>
            </div>
            <div class="title-text">
              <h3>
                <b-skeleton animation="throb" variant="dark" width="32%"></b-skeleton>
                <b-skeleton animation="throb" variant="dark" width="22%"></b-skeleton>
                <b-skeleton animation="throb" variant="dark" width="12%"></b-skeleton>
              </h3>
              <div class="pm-button btn-hover">
               <b-skeleton type="button" width="100%"></b-skeleton>
              </div>
            </div>
          </div>
          <div class="profile-title" v-if="!loading">
            <div class="title-img">
              <img :src="data.profilePic">
            </div>
            <div class="title-text">
              <h3>{{ data.username }} <label>Satıcı Puanı:<span>{{data.point}}</span></label></h3>
              <div class="pm-button btn-hover">
                <a v-b-modal.sendMessage><span><img :src="require('@/assets/images/pm.png')"> MESAJ GÖNDER</span></a>
              </div>
            </div>
          </div>

        </div>
        <div class="clearfix"></div>
      </div>
      <div v-if="!loading">
        <section class="games" v-if="data.stores.length > 0">
          <div class="container" >
            <h1>Mağazalar</h1>
            <div class="swiper-container">
              <div class="swiper-wrapper" v-if="!loading">
                <Carousel
                    :perPageCustom = "[[768, 3], [1024, 4]]"
                    :paginationEnabled=false
                >
                  <slide v-for="(item, index) in data.stores" :key="index">
                    <div class="swiper-slide"><div class="item">
                      <router-link :to="{path: '/store/'+item.link}">
                        <a>
                          <img :src="item.image" />
                        </a>
                      </router-link>
                      <div class="games-blur"></div>
                      <router-link :to="{path: '/store/'+item.link}">
                        <a>
                          <strong>{{item.title}}</strong>
                        </a>
                      </router-link>

                    </div>
                    </div>
                  </slide>

                </Carousel>
              </div>
            </div>
          </div>
        </section>
        <section class="sales" v-if="data.advertisements.length > 0">
          <div class="container" >
            <h1>İlanlar</h1>
            <div class="owl-carousel">
              <Carousel
                  :perPageCustom = "[[768, 3], [1024, 6]]"
                  :paginationEnabled=false
                  :autoplay=true
              >
                <slide v-for="(advertisement, index) in data.advertisements" :key="index">
                  <div class="item">
                    <router-link :to="{path: '/advertisement/'+advertisement.id}">
                      <figure>
                        <img :src="advertisement.image" />
                        <figcaption>{{ advertisement.price | currency}} TL </figcaption>
                      </figure>
                      <strong>{{ advertisement.gameTitle }}</strong>
                      <span>{{ advertisement.title }}</span>
                    </router-link>
                  </div>
                </slide>
              </Carousel>



            </div>
          </div>
        </section>
        <section class="sales" v-if="data.articles.length > 0">
          <div class="container" >
            <h1>Makaleler</h1>
            <div class="swiper-container">
              <div class="swiper-wrapper" v-if="!loading">
                <Carousel
                    :perPageCustom = "[[768, 3], [1024, 4]]"
                    :paginationEnabled=false
                >
                  <slide v-for="(item, index) in data.articles" :key="index">
                    <div class="swiper-slide"><div class="item">
                      <router-link :to="{path: '/article/'+item.id}">
                        <a>
                          <img :src="item.image" />
                        </a>
                      </router-link>
                      <div class="games-blur"></div>
                      <router-link :to="{path: '/article/'+item.link}">
                        <a>
                          <strong>{{item.title}}</strong>
                        </a>
                      </router-link>

                    </div>
                    </div>
                  </slide>

                </Carousel>
              </div>
            </div>
          </div>
        </section>

      </div>
      <div class="clearfix"></div>
    </section>
    <Footer></Footer>
    <SendMessage :username="data.username"></SendMessage>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import authService from "@/services/authService";
import { Carousel, Slide } from 'vue-carousel';
import SendMessage from "@/components/Modals/SendMessage";

export default {
  name: "Profile",
  data() {
    return {
      data: [],
      loading: true
    }
  },
  components: {
    Header, Footer,
    Carousel,
    Slide,
    SendMessage
  },
  methods: {
    getUserProfile() {
      authService.profile({username: this.$route.params.username})
      .then((res) => {
        this.loading = false
        if(res.state){
          this.data = res.data[0]
        }else{
          this.$router.push({path: "/"})
        }
      })
    }
  },
  created() {
    this.getUserProfile()
  },
}
</script>

<style scoped>
.VueCarousel-slide {
  margin-right: 10px;
  max-width: 270px;
}
.VueCarousel-slide img {
  max-width: 270px;
}

</style>