import Vue from 'vue';
import Vuex from 'vuex';
//import Axios from "axios";
import createPersistedState from 'vuex-persistedstate'
//
import siteInfo from './siteInfo'
import User from './User'
import Cart from "./Cart";
Vue.use(Vuex);

export const store = new Vuex.Store({
    plugins: [createPersistedState()],
    state: {
        token: '',
        depositError : false,
        depositMessage: "",
        homeLoading: true,
        user_id: '',
        payDetail: {
            link : '',
            isIframe: true,
            paymentActive: false
        }
    },
    mutations: {
        setDepositDefault(state, data) {
            state.depositError = data.depositError
            state.depositMessage = data.depositMessage
        },
        setHomeLoader(state, data){
            state.homeLoading = data
        },
        setUserId(state, data){
            state.user_id = data
        },
        setPay(state, data){
            state.payDetail = data
        }
    },
    actions: {
        depositDefault(context, payLoad) {
            context.commit('setDepositDefault', payLoad)
        },
        homeLoader(context, payLoad) {
            context.commit('setHomeLoader', payLoad)
        },
        userId(context, payLoad) {
            context.commit('setUserId', payLoad)
        },
        Pay(context, payLoad){
            context.commit('setPay', payLoad)
        }
    },
    getters: {
        depositError: state => state.depositError,
        depositMessage: state => state.depositMessage,
        homeLoading: state => state.homeLoading,
        userId: state => state.user_id,
        payDetail: state => state.payDetail
    },
    modules: {
        siteInfo,
        createPersistedState,
        User,
        Cart
    }
});