<template>
    <section class="settings">
      <div class="page-title">
          <div class="container">
            <div class="bg-blur"></div>
            <h1> {{ user.username }} </h1>
          </div>
        </div>
      <div class="container">
        <div class="sidebar-one">
          <LeftMenu :data="page"></LeftMenu>
        </div>
        <div class="settings-content">
          <div class="row settings-item">
            <div class="col-md-4">
              <label>ALIŞVERİŞ BAKİYENİZ - TL</label>
              <input type="text" v-model="user.balance" disabled>
            </div>
            <div class="col-md-4">
              <label>KAZANÇ BAKİYENİZ - TL</label>
              <input type="text" v-model="user.earning_balance" disabled>
            </div>
            <div class="col-md-4">
              <label>TOPLAM BAKİYENİZ - TL</label>
              <input type="text" v-model="user.totalBalance" disabled>
            </div>
          </div>
          <h3>BAKİYE İŞLEMLERİ</h3>
          <button class="btn-hover" v-b-modal.deposit><span> <i class="fas fa-money-bill-wave-alt" /> BAKİYE YÜKLE</span></button><br>
          <button class="btn-hover" v-b-modal.withdrawal><span><i class="fas fa-university" /> PARA ÇEK</span></button><br>
          <button class="btn-hover" @click="settingClick"><span><i class="fas fa-user"></i> HESAP BİLGİLERİN</span></button><br>
          <button class="btn-hover" v-b-modal.password><span><i class="fas fa-key"></i> ŞİFREMİ DEĞİŞTİR</span></button><br>
        </div>
        <div class="clearfix"></div>
      </div>
      <Withdraw></Withdraw>
      <Password></Password>
    </section>
  </template>
  
  <script>
  import LeftMenu from "@/components/User/LeftMenu";
  import {mapGetters} from "vuex"
  import Withdraw from "@/components/Modals/Withdraw";
  import Password from "@/components/Modals/Password";
  
  export default {
    name: "HomeUser",
    components: {
      LeftMenu, Withdraw, Password
    },
    data() {
      return {
        page: "main",
        balanceName: 'Alışveriş bakiyesi'
      }
    },
    computed:{
      ...mapGetters({
        user: 'User/user'
      })
    },
    methods: {
      settingClick() {
        this.$router.push({path: 'main/settings'})
      },
      depositModalCheck(){
        if(this.$route.query.action === 'balance'){
          this.$bvModal.show('deposit')
        }
      }
    },
    metaInfo() {
    return {
      title: 'Hesabım',
    }
   },
    created() {
      this.depositModalCheck()
    }
   }
  </script>
  
  <style scoped>
  .profile-page {
    padding-top: 0px;
  }
  
  </style>