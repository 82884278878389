<template>
  <div>
    <section class="login-page">
      <div class="page-title">
        <div class="container">
          <div class="bg-blur"></div>
          <h1>Swift (EURO) ile Öde!</h1>
        </div>
      </div>
      <div class="login-content">
        <div class="container">
          <div class="login-right">
            <div class="login-form">
              <form class="needs-validation" novalidate @submit.prevent="sendSwiftForm">
                <div class="logfor-group">
                  <label class="text-white" @click="copy(swift_name)">Alıcı İsim <i class="fas fa-copy text-white"></i></label>
                  <input type="text" class="form-control" disabled :value="swift_name">
                </div>
                <div class="logfor-group">
                  <label class="text-white" @click="copy(swift_bank_name)">Alıcı Banka <i class="fas fa-copy text-white"></i></label>
                  <input type="text" class="form-control" disabled :value="swift_bank_name">
                </div>
                <div class="logfor-group">
                  <label class="text-white" @click="copy(swift_code)">Swift Kodu <i class="fas fa-copy text-white"></i></label>
                  <input type="text" class="form-control" disabled :value="swift_code">
                </div>
                <div class="logfor-group">
                  <label class="text-white" @click="copy(swift_iban)">İBAN <i class="fas fa-copy text-white"></i></label>
                  <input type="text" class="form-control" disabled :value="swift_iban">
                </div>
                <div class="logfor-group">
                  <label class="text-white" >Ödeme Yapmanız Gereken Tutar (EUR)</label>
                  <input type="text" class="form-control" disabled :value="$route.query.amount | currency">
                </div>
                <b-alert variant="primary" show> <b>Lütfen Dikkat</b>: Western Union ile ödeme yaptıktan sonra aşağıda istenilen bilgileri giriniz..</b-alert>
                <div class="logfor-group">
                  <label class="text-white">Gönderici İsim Soyisim </label>
                  <input type="text" class="form-control"  v-model="swiftFormUsd.name">
                </div>
                <div class="logfor-group">
                  <label class="text-white">Gönderici Telefon </label>
                  <input type="text" class="form-control"  v-model="swiftFormUsd.phone">
                </div>
                <div class="logfor-group">
                  <label class="text-white">Gönderici Ülke </label>
                  <input type="text" class="form-control"  v-model="swiftFormUsd.country">
                </div>
                <div class="logfor-group">
                  <div class="">
                    <div v-if="!loading">
                      <div class="register-button">
                        <button class="btn-hover" ><span><img :src="require('@/assets/images/lr.png')"/> GÖNDER</span></button>
                      </div>
                    </div>
                    <div v-if="loading" class="register-button">
                      <button class="btn-hover " > <span>  <i class="fas fa-spinner fa-spin"></i></span> </button>
                    </div>
                  </div>
                </div>
              </form>


            </div>
          </div>
          <div class="login-left">
            <div class="login-left-item">
              <div class="lole-img"><img :src="require('@/assets/images/l1.png')"/></div>
              <div class="lole-text">
                <h4>Güvenli Platform</h4>
                <p>
                  Kasagame çatısı altında üyelik bilgileriniz ve buna bağlı kişisel/banka/telefon gibi bilgileriniz güven altındadır, Hiçbir şekilde üçüncü parti servislerle paylaşılmaz. Siz alışverişinizi tamamlamadan paranız karşı tarafa gönderilmez, Kasagame Havuz Sistemi’nde bekletilir.
                </p>
              </div>
              <div class="clearfix"></div>
            </div>
            <div class="login-left-item">
              <div class="lole-img"><img :src="require('@/assets/images/l2.png')"/></div>
              <div class="lole-text">
                <h4>Kolay ve Avantajlı Alışveriş</h4>
                <p>
                  Dilediğiniz ürünleri rahatlıkla alabilir ve satabilirsiniz. Ödemenizi güvenli bir şekilde hesabınıza aktarabilirsiniz. Kampanyalar, indirimler ya da özel fırsatlar içeride sizi bekliyor.
                </p>
              </div>
              <div class="clearfix"></div>
            </div>
            <div class="login-left-item">
              <div class="lole-img"><img :src="require('@/assets/images/l3.png')"/></div>
              <div class="lole-text">
                <h4>İçerik Üret Para Kazan</h4>
                <p>
                  Kasagame'in Haberler kategorisine güncel ve kopya olmayan içerikler üreterek Kasa Kredi kazanabilir, bu kredileri dilediğiniz gibi sistem içerisinde harcayabilirsiniz.
                </p>
              </div>
              <div class="clearfix"></div>
            </div>
            <div class="login-left-item">
              <div class="lole-img"><img :src="require('@/assets/images/l4.png')"/></div>
              <div class="lole-text">
                <h4>Hızlı Bağış Al/Yap</h4>
                <p>
                  Siz de YouTube, Twitch, Mixer ya da Dlive gibi platformlarda yayın yapıyorsanız rahatlıkla bağış alabilirsiniz.
                </p>
              </div>
              <div class="clearfix"></div>
            </div>
            <div class="login-left-item">
              <div class="lole-img"><img :src="require('@/assets/images/l5.png')"/></div>
              <div class="lole-text">
                <h4>Düşük Komisyon</h4>
                <p>
                  İnternet sitemiz üzerinden yapılan her işlem için düşük komisyon ücreti ödersiniz.
                </p>
              </div>
              <div class="clearfix"></div>
            </div>
          </div>

        </div>
      </div>
      <div class="clearfix"></div>
    </section>

  </div>
</template>

<script>
import paymentService from "@/services/paymentService";
export default {
  name: "Login",
  data(){
    return {
      loading: false,
      swiftFormUsd: {
        name: '',
        phone: '',
        country: '',
        loading: true,
        id: this.$route.query.id,
        send: false,
        currency: 'euro'
      },
      western_name: '',
      western_country: '',
      swift_name: '',
      swift_bank_name: '',
      swift_iban: '',
      swift_code: '',
      swift_bank_id: '',

    }
  },
  components:{
  },
  methods: {
    copy(value){
      this.$swal({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        icon: 'success',
        title: 'Kopyalandı',
        text: value,
      });

      let input = document.createElement('textarea');
      input.innerHTML = value;
      document.body.appendChild(input);
      input.select();
      let result = document.execCommand('copy');
      document.body.removeChild(input);
      return result
    },
    sendSwiftForm(){
      this.swiftFormUsd.loading = true
      paymentService.sendSwiftForm({amount: this.$route.query.amount, id: this.swiftFormUsd.id, name: this.swiftFormUsd.name, phone: this.swiftFormUsd.phone, country: this.swiftFormUsd.country, currency: this.swiftFormUsd.currency, bank_id: this.swift_bank_id})
          .then((res) => {
            this.swiftFormUsd.loading = false
            if(res.state){
              this.successMessage("Talebiniz alındı, 48 saat içerisinde size dönüş sağlanacak. ilginiz için teşekkürler")
              this.swiftFormUsd.send = true
            }else{
              this.errorMessage(res.message)
            }
          })
    },
    swiftInformation(currency){
      this.loading = true
      this.swiftFormUsd.currency = currency
      paymentService.swiftBankDetail({currency: currency})
          .then((res) => {
            if(res.state){
              this.loading = false
              this.swift_code = res.data[0].swift_code
              this.swift_name = res.data[0].account_name
              this.swift_bank_name = res.data[0].bank_name
              this.swift_iban = res.data[0].iban
              this.swift_bank_id = res.data[0].id
            }
          })
    },
    successMessage(val) {
      this.$swal({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        icon: 'success',
        title: val,
        text: '',
      });
    },
    errorMessage(val) {
      this.$swal({
        showConfirmButton: true,
        icon: 'error',
        title: 'HATA',
        text: val,
        confirmButtonText: 'OK',
        confirmButtonColor: '#3085d6',
      });
    },

  },
  metaInfo () {
    return {
      title: "Swift ile Öde | " + this.siteSettings[0].title,
      meta: [
        { name: 'description', content: this.siteSettings[0].description+" Western Union" },
        { name: 'keywords', content: this.siteSettings[0].keywords+ " Western Union" },
        { charset: 'utf-8' }
      ]
    }
  },
  created() {
    this.swiftInformation('euro')
  }
}
</script>

<style scoped>
.login-page {
  background-color: #140f17;
}
.login-and {
  height: 90px;
}
.vue-tel-input {
  border: 0px;
}
.invalid-feedback {
  display: block;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef00 !important;
  opacity: 1;
}
</style>