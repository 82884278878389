<template>
  <div id="app">
    <router-view />
    <div id="widget-container" ></div>
    </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      live_desk_code: this.$store.getters['siteInfo/siteSettings'][0].live_desk_code
    }
  },
  components: {
  },
  sockets: {
    connect() {
    },
    messages(msg) {
      this.messages(msg)
    },
    notification(msg) {
      this.notification(msg)
    }
  },
  methods: {
    join() {
      this.$socket.client.emit('join', this.$store.getters.userId);
    },
    notification(msg) {
      this.$swal({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        icon: msg.icon,
        title: msg.title,
        text: msg.message,
      });
    },
    messages(msg){
      this.$swal({
        showConfirmButton: true,
        icon: msg.icon,
        title: msg.title,
        text: msg.message,
        confirmButtonText: 'OK',
        confirmButtonColor: '#3085d6',
      });
    },
  },
  created() {
    if(this.$store.getters["User/isLoggedIn"]){
      this.join()
    }
  },
  watch: {
    '$store.getters.userId': function() {
      if(this.$store.getters["User/isLoggedIn"]){
        this.join()
      }
    }
  },

}
</script>

<style>

</style>
