<template>
  <section class="advertisement-page">
    <div class="page-title">
      <div class="container">
        <div class="bg-blur"></div>
        <h1>Reklamlarım</h1>
      </div>
    </div>
    <div class="advertisement-content">
      <div class="container">
        <div class="sidebar-one">
          <LeftMenu :data="page"></LeftMenu>
        </div>
        <div class="advertisement-right">
        <div class="advertisement-add">
          <button @click="addAdds"><img :src="require('@/assets/images/add.png')"> YENİ REKLAM OLUŞTUR</button>
        </div>
        <div class="advertisement-list">
          <div class="advertisement-title">
            <h3>REKLAMLARIM <label>2 AKTİF</label></h3>
          </div>
          <div class="ad-item pause">
            <div class="item-left">
              <h5>Item mağaza kampanyam</h5>
              <ul>
                <li><label>Gösterim: 723 / 1.000</label></li>
                <li><label><img src="images/doc.png"> Etkileşim: 47</label></li>
                <li><label><img src="images/doc.png"> Devam Ediyor</label></li>
              </ul>
            </div>
            <div class="item-right">
              <div class="it-right-one"><img src="images/date-time.png"> 1 saat önce</div>
              <div class="it-right-two">145 TL</div>
              <div class="it-right-three"><button><img src="images/pause.png"></button></div>
              <div class="it-right-four"><button onclick="window.location.href='#'"><img src="images/cog.png"></button></div>
            </div>
            <div class="clearfix"></div>
          </div>
          <div class="ad-item play">
            <div class="item-left">
              <h5>Item mağaza kampanyam</h5>
              <ul>
                <li><label>Gösterim: 723 / 1.000</label></li>
                <li><label><img src="images/doc.png"> Etkileşim: 47</label></li>
                <li><label><img src="images/doc.png"> Devam Ediyor</label></li>
              </ul>
            </div>
            <div class="item-right">
              <div class="it-right-one"><img src="images/date-time.png"> 1 saat önce</div>
              <div class="it-right-two">145 TL</div>
              <div class="it-right-three"><button><img src="images/play.png"></button></div>
              <div class="it-right-four"><button onclick="window.location.href='#'"><img src="images/cog.png"></button></div>
            </div>
            <div class="clearfix"></div>
          </div>
          <div class="ad-item replay">
            <div class="item-left">
              <h5>Item mağaza kampanyam</h5>
              <ul>
                <li><label>Gösterim: 723 / 1.000</label></li>
                <li><label><img src="images/doc.png"> Etkileşim: 47</label></li>
                <li><label><img src="images/doc.png"> Devam Ediyor</label></li>
              </ul>
            </div>
            <div class="item-right">
              <div class="it-right-one"><img src="images/date-time.png"> 1 saat önce</div>
              <div class="it-right-two">45 TL</div>
              <div class="it-right-three"><button><img src="images/replay.png"></button></div>
              <div class="it-right-four"><button onclick="window.location.href='#'"><img src="images/cog.png"></button></div>
            </div>
            <div class="clearfix"></div>
          </div>
          <div class="ad-item conf">
            <div class="item-left">
              <h5>Item mağaza kampanyam</h5>
              <ul>
                <li><label>Gösterim: 723 / 1.000</label></li>
                <li><label><img src="images/doc.png"> Etkileşim: 47</label></li>
                <li><label><img src="images/doc.png"> Devam Ediyor</label></li>
              </ul>
            </div>
            <div class="item-right">
              <div class="it-right-one"><img src="images/date-time.png"> 1 saat önce</div>
              <div class="it-right-two">45 TL</div>
              <div class="it-right-three"></div>
              <div class="it-right-four"><button onclick="window.location.href='#'"><img src="images/cog.png"></button></div>
            </div>
            <div class="clearfix"></div>
          </div>
        </div>
      </div>
      </div>
    </div>
    <div class="clearfix"></div>
  </section>
</template>

<script>
import LeftMenu from "@/components/User/LeftMenu";
import authService from "@/services/authService";

export default {
  name: "Articles",
  data() {
    return  {
      page: "adds",
      logs: [],
      isLoading: false,
      lastPage: 1,
      logsPage: 1,
      errorMessage: false
    }
  },
  components: {LeftMenu},
  methods: {
    addAdds() {
      this.$router.push({path: '/main/add-adds'})
    },
    getLogs() {
      this.isLoading = true
      authService.getArticles({page: this.logsPage})
          .then((res) => {
            this.isLoading = false
            if(res.data.state) {
              this.lastPage = res.data.lastPage
              this.logsPage = res.data.page
              this.logs = res.data.data
              this.errorMessage = false
            } else {
              this.errorMessage = true
            }
          })

    },
    getMore() {
      this.logsPage++
      authService.getArticles({page: this.logsPage})
          .then((res) => {
            this.isLoading = false
            if(res.data.state) {
              this.lastPage = res.data.lastPage
              this.logsPage = res.data.page
              this.logs.push(...res.data.data)
              this.errorMessage = false
            } else {
              this.errorMessage = true
              this.logs = []
            }
          })
    }

  },
  async created() {
    this.getLogs()
  },
  watch:
      {
        logs: function () {
          if (this.logsPage < this.lastPage)
            document.getElementById('moreButton').scrollIntoView();
        }
      },
  metaInfo() {
    return {
      title: 'Reklamlarım - Hesabım',
    }
  }
}
</script>

<style scoped>
  /deep/.v-select-toggle {
    background: #0B0A0B;
  }
</style>