<template>
  <section class="pubpanel">
    <div class="pubpanel-title">
      <div class="container">
        <div class="bg-blur"></div>
        <h1>Siparişlerim</h1>
      </div>
    </div>
    <div class="container">
      <div class="sidebar-one">
        <LeftMenu :data="page"></LeftMenu>
      </div>
      <div class="pubpanel-content">
        <div class="stepullah">
          <b-alert variant="danger" show v-if="dataError"> Veriler Yüklenemedi.Lütfen sayfayı yenileyin veya daha sonra tekrar deneyin.</b-alert>,
          <b-container >
            <b-row lg="12" class="user-filters">
              <b-col md="3">
                <b-form-datepicker v-model="startDate" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" class="mb-2" size="sm" label-no-date-selected="Bşlgç. Tarihi" dark></b-form-datepicker>
              </b-col>
              <b-col md="3">
                <b-form-datepicker v-model="endDate" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" class="mb-2" size="sm" label-no-date-selected="Bitiş Tarihi" dark></b-form-datepicker>
              </b-col>
              <b-col md="3">
                <b-select size="sm" v-model="status"  :options="[{value: null, text: 'Tümü', select: true},{value: 0, text: 'Onay Bekleyen'},{value: 1, text: 'Onaylanan'},{value: 2, text: 'İptal Edilen'}]"></b-select>
              </b-col>
              <b-col md="3">
                <b-button variant="info"  size="sm" @click="search">Sorgula</b-button>
              </b-col>
            </b-row>
            <b-row lg="12">

              <b-table
                  v-if="!dataError"
                  :items="items"
                  :fields="fields"
                  :busy="isLoading"
                  hover
                  dark
                  striped
                  borderless
                  responsive
                  show-empty
              >
                <template v-slot:table-busy>
                  <div class="text-center text-white my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Yükleniyor...</strong>
                  </div>
                </template>
                <template v-slot:empty="">
                  <b-alert show variant="warning">
                    Kayıt bulunamadı.
                  </b-alert>
                </template>
                <template v-slot:cell(price)="data">
                  {{ data.item.price | currency}} TL
                </template>
                <template v-slot:cell(epin_code)="data">
                  <div v-if="data.item.epin_code" @click="copy(data.item.epin_code)">
                    {{ data.item.epin_code }}
                    <i class="fas fa-copy"></i>
                  </div>
                </template>
                <template v-slot:cell(product)="data">
                 <router-link :to="{path: data.item.product.gameLink}" class="text-white">{{ data.item.product.gameTitle }} - {{ data.item.product.title }}</router-link>
                </template>
                <template v-slot:cell(status)="data">
                  <b-badge pill variant="success" v-if="data.item.status == '1'">Onaylandı</b-badge>
                  <b-badge pill variant="warning" v-if="data.item.status == '0'">Onay Bekliyor</b-badge>
                  <b-badge pill variant="danger" v-if="data.item.status == '2'">İptal Edildi</b-badge>
                </template>
                <template v-slot:cell(sale_date)="data">
                  {{ data.item.sale_date  | formatDate }}
                </template>
                <template v-slot:cell(details)="">
                  <b-badge variant="info"><i class="fas fa-info"/> Detaylar</b-badge>
                </template>


              </b-table>
            </b-row>
          </b-container>
        </div>

      </div>
      <div class="clearfix"></div>
    </div>
  </section>
</template>

<script>
import LeftMenu from "@/components/User/LeftMenu";
import authService from "@/services/authService";
  export default {
    name: "Orders",
    data() {
      return  {
        startDate: "",
        endDate: "",
        status: "",
        isLoading: true,
        page: "orders",
        dataError: false,
        fields: [
          { key: 'id',label: "Sipariş ID" },
          { key: 'product',label: "Ürün" },
          { key: 'epin_code',label: "EPİN" },
          { key: 'price',label: "Tutar" },
          { key: 'sale_date',label: "Tarih" },
          { key: 'status',label: "Durum" },
          { key: 'details', label: "#"}
        ],
        items: []
      }
    },
    components:{
      LeftMenu
    },
    methods:{
      search() {
        this.isLoading = true
        this.getOrders()
      },
      getOrders(){
        authService.getOrders({startDate: this.startDate, endDate: this.endDate, status: this.status})
            .then((response) => {
              this.isLoading = false
              if(response.data.state){
                this.items = response.data.data
              }else
                this.dataError = true
            })
      },
      copy(item){
        let input = document.createElement('textarea');
        input.innerHTML = item;
        document.body.appendChild(input);
        input.select();
        let result = document.execCommand('copy');
        document.body.removeChild(input);
        this.$swal({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          icon: 'success',
          title: 'Kopyalandı!',
          text: item,
        });

        return result
      },

    },
    async created() {
      this.getOrders()
    },
    metaInfo() {
      return {
        title: 'Siparişlerim - Hesabım',
      }
    }


  }
</script>

<style scoped>
/deep/.table-dark {
  background-color: #1E1622;
  color: #fff;
  border-radius: 10px;
}
.user-filters {
  background-color: #1E1622;
  color: #fff;
  border-radius: 10px;
  margin-bottom: 10px;
  height: 65px;
  padding: 14px;
}

</style>