<template>
  <section class="pubpanel">
    <div class="pubpanel-title">
      <div class="container">
        <div class="bg-blur"></div>
        <h1>Bakiye LOG'ları</h1>
      </div>
    </div>
    <div class="container">
      <div class="sidebar-one">
        <LeftMenu :data="page"></LeftMenu>
      </div>
      <div class="balance-right">
        <div class="balance-add">
          <button type="button" v-b-modal.deposit><img :src="require('@/assets/images/add.png')"> BAKİYE YÜKLE</button>
        </div>

        <div class="balance-list">
          <div class="list-title">
            <div class="col-md-6"><h3>İŞLEM GEÇMİŞİM</h3></div>
            <div class="clearfix"></div>
          </div>
          <div class="text-center text-white my-2" v-if="isLoading">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Yükleniyor...</strong>
          </div>
          <b-alert variant="warning" show v-if="errorMessage && !isLoading">
            Henüz kayıt yok..
          </b-alert>
          <div class="item" v-for="(item,index) in logs" :key="index">
            <div class="left">
              <span>{{ item.description }}</span>
            </div>
            <div class="right">
              <div class="item-arrow">
                <img :src="require('@/assets/images/arrow-up.png')" v-if="!item.out">
                <img :src="require('@/assets/images/arrow-down.png')" v-if="item.out">
              </div>
              <div class="item-try">{{ item.sum | currency}} TL</div>
              <div class="item-status"><img :src="require('@/assets/images/item-date.png')"> {{ item.date | formatDate }}</div>
            </div>
            <div class="clearfix"></div>
          </div>

          <div id="moreButton">
            <div class="balance-more" v-if="logsPage < lastPage" >
              <a href="#" @click="getMore">DAHA FAZLA</a>
            </div>
          </div>

        </div>
      </div>
      <div class="clearfix"></div>
    </div>
  </section>
</template>

<script>
import LeftMenu from "@/components/User/LeftMenu";
import authService from "@/services/authService";
export default {
  name: "Balancelogs",
  data() {
    return  {
      page: "balancelogs",
      logs: [],
      isLoading: false,
      lastPage: 1,
      logsPage: 1,
      errorMessage: false
    }
  },
  components:{
    LeftMenu
  },
  methods: {
    getLogs() {
      this.isLoading = true
      authService.getBalanceLogs({page: this.logsPage})
      .then((res) => {
        this.isLoading = false
        if(res.data.state) {
          this.lastPage = res.data.lastPage
          this.logsPage = res.data.page
          this.logs = res.data.data
          this.errorMessage = false
        } else {
          this.errorMessage = true
        }
      })

    },
    getMore() {
      this.logsPage++
      authService.getBalanceLogs({page: this.logsPage})
          .then((res) => {
            this.isLoading = false
            if(res.data.state) {
              this.lastPage = res.data.lastPage
              this.logsPage = res.data.page
              this.logs.push(...res.data.data)
              this.errorMessage = false
            } else {
              this.errorMessage = true
              this.logs = []
            }
          })
    }
  },
  async created() {
    this.getLogs()
  },
  watch:
      {
        logs: function () {
          if (this.logsPage < this.lastPage)
            document.getElementById('moreButton').scrollIntoView();
        }
      },
  metaInfo() {
    return {
      title: 'Bakiye Kayıtlarım - Hesabım',
    }
  }

}
</script>

<style scoped>

</style>