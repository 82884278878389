<template>
  <div>
    <Header></Header>
    <section class="aboutus">
      <div class="page-title">
        <div class="container">
          <div class="bg-blur"></div>
          <h1>Gizlilik Sözleşmesi</h1>
        </div>
      </div>
      <div class="content">
        <div class="container">
          <p> <br>
            Bu beyan kasagame.com (Decade Bilişim LTD.)'un gizlilik politikasını içerir. kasagame.com web sitesini
            ziyaret ederek aşağıdaki şartları ve kuralları uygulamayı kabul etmiş sayılmaktasınız.<br><br>

            kasagame.com üyelik aşamasında ve daha sonrasında sizden bazı kişisel bilgilerinizi talep eder. Kişisel
            bilgilerinizin korunması ve gizliliğinizin sürdürülebilmesi, kasagame.com ekibi olarak birinci
            önceliğimizdir. Bu nedenle vermiş olduğunuz bilgiler, Üyelik Sözleşmesi'nde belirtilen kurallar ve amaçlar
            dışında herhangi bir kapsamda kullanılmayacak, üçüncü şahıslarla paylaşılmayacaktır. Sistemle ilgili
            sorunların tespiti ve söz konusu sorunların en hızlı şekilde giderilebilmesi için, kasagame.com,
            gerektiğinde kullanıcıların IP adresini tespit etmekte ve bunu kullanmaktadır. IP adresleri, kullanıcıları
            genel bir şekilde tanımlamak ve kapsamlı demografik bilgi toplamak amacıyla da kullanılabilir.<br><br>

            Sitemizdeki kayıt formları, sipariş formları, anketler ve yarışmalarda, irtibat bilgilerinizi (adınız,
            soyadınız, e-posta adresiniz, telefon numaralarınız ve posta adresiniz) vermeniz talep edilmektedir. Ayrıca,
            satın alma formunda kredi kartı bilgilerinizi vermeniz gerekmektedir. Yukarıda bahsedilen kişisel
            bilgileriniz; siparişlerinizi almak, ürün ve hizmetlerimizi sunmak, ödemelerinizi gerçekleştirmek,
            siparişlerinizi ulaştırmak ve siparişleriniz ile ilgili bilgi vermek, ürünler ve hizmetler hakkında
            pazarlama amacıyla sizinle irtibata geçmek, bilgilerinizi güncellemek, üyeliğinizi yönetmek ve sürdürmek,
            ilginizi çekebilecek ürün ve hizmetleri önermek ve 3. şahısların teknik, lojistik ve benzeri diğer
            işlevlerini bizim adımıza yerine getirmelerini sağlamak amacıyla kullanılmaktadır.<br><br>

            kasagame.com üyesi olduğunuz andan itibaren, aksi tarafınızdan talep edilmediği sürece, günlük ve haftalık
            bilgilendirme e-postaleri, e-mail adresinize gönderilecektir. Dilediğiniz zaman bu e-maillerle ilgili
            değişiklik yapma, hizmetten vazgeçme ve yeniden başlatma hakkınız bulunmaktadır. Bu e-maillerin içeriği;
            satın almış olduğunuz ürün veya ürünlerle ilgili bilgiler, ilgileneceğinizi düşündüğümüz diğer ürünler, 3.
            kişilerin reklamlarını ve benzeri bilgileri içerebilir. Demografik bilgiler, sitemizi kullanıcılarımızın
            ilgi alanlarına göre uyarlamak için kullanılmaktadır. Bu bilgiler, reklamların hedef kitleye
            uyarlanabilmesini temin etmek maksadıyla ve yalnızca diğer kullanıcılara ait bilgilerle birlikte olmak
            üzere, reklam verenlerle paylaşılabilir. Bu bilgilerde herhangi bir şekilde kişisel bilgi verilmez, sadece
            grup olarak kullanıcı eğilimleri ile ilgili bir takım çıkarımlar yapmak ve segmentasyon amacıyla kullanılır.
            Toplanan mali bilgiler satın aldığınız ürün ve hizmetleri size fatura etmek için kullanılmaktadır. Sitemizde
            bir satın alma yaptığınızda size ait mali bilgilerin, işleminizi gerçekleştirmek için gerekli 3. şahıslara
            (bankalar, kredi kartı şirketleri vb.) verilmesini kabul etmektesiniz. Paylaşılacak bilgiler; kredi kartı
            numarası, son kullanma tarihi, CVV2 de dahil olmak üzere gerekli tüm mali bilgileri içerir.<br><br>

            Tüm kredi kartı ve kişisel bilgileriniz, internet güvenlik standardı olan SSL Secure sistemi ile 128 mbit
            şifrelenmiştir. Bu şekilde, internet üzerindeki dolaşımları sırasında herhangi bir şekilde bu bilgilerinizin
            istenilmeyen kişi veya kurumlar tarafından ele geçirilmesi önlenmiştir. Gizlilik politikamız ile ilgili her
            türlü soru ve öneriniz için sitemizdeki iletişim bölümünden bize e-posta gönderebilirsiniz. kasagame.com
            üyelik iptal işleminizi iletisim@kasagame.com adresine e-posta göndererek talepte bulunabilirsiniz.
            kasagame.com'a aşağıda yer alan iletişim bilgilerinden ulaşabilirsiniz.<br><br>

            Kasagame.com'da kendileri hakkında hangi bilgilerin saklanmış olduğunu öğrenmek için ücretsiz olarak yazılı
            bilgi talep edebilirler. Olası düzeltmeler, engellemeler veya bilgilerin silinmesi hakkındaki talepleriniz,
            yasaların öngördüğü ölçüde, derhal işleme alınmaktadır. Taleplerinize ve isteklerinize yanıt verebilmek, bu
            istekleri yerine getirmek ya da interaktif müşteri programlarını yönetebilmek için, adınız, adresiniz,
            e-posta adresiniz ve telefon numaranız gibi kişisel bilgileri talep etmemiz gerekebilir. Bu bilgileri
            bizimle paylaşmak ve www.kasagame.com adresine üye olmak, sizin istek ve onayınızla gerçekleşecektir.
            Topladığımız bilgiler firmamız veri bankasında güvenilir bir şekilde saklanmaktadır. Bu bilgiyi,
            isteklerinize yanıt vermek, sizinle posta, e-posta ya da telefon yoluyla yeni ürünlerimiz, hizmetlerimiz ve
            kampanyalarımız hakkında bilgi vermek amacıyla iletişim kurmak için kullanabiliriz. Yürürlükteki mevzuat
            gerektirmedikçe, bu detayları izniniz olmadan hiçbir üçüncü şahsa vermeyeceğiz. İlaveten web sitemiz IP
            adresiniz, bilgisayarınızın işletim sistemi, tarayıcı uygulamanız gibi verileri toplayan teknolojileri
            kullanmaktadır. Herhangi bir zamanda günlük mail gönderim listemizden çıkmak isterseniz, göndermiş olduğumuz
            maillerin alt kısmında bulunan “Mail listemizden çıkmak için lütfen tıklayınız” bağlantısına tıklayarak mail
            aboneliğinden tek tıkla çıkabilirsiniz.<br><br>

            <b>Adres:</b> Decade Bilişim LTD. Serbest Liman ve Bölge, Posta Kutusu No : 568 Gazimağusa / Kıbrıs<br>

            <b>E-Posta:</b> iletisim@kasagame.com<br>
          </p>
        </div>
      </div>
      <div class="page-title">
        <div class="container">
          <div class="bg-blur"></div>
          <h1>KVKK Sözleşmesi</h1>
        </div>
      </div>
      <div class="content">
        <div class="container">
          <p>
            <b>Veri Sorumlusu:</b> Decade Bilişim LTD. ("kasagame.com") Serbest Liman ve Bölge, Posta Kutusu No : 568
            Gazimağusa / Kıbrıs <br><br>

            Biz, kasagame.com olarak; müşterilerimiz ve firma çalışanlarımız dahil Şirketimiz ile ilişkisi olan
            şahısların kişisel verilerinin Türkiye Cumhuriyeti Anayasası ve insan haklarına ilişkin ülkemizin tarafı
            olduğu uluslararası sözleşmeler ile 6698 sayılı Kişisel Verilerin Korunması Kanunu (“KVKK”) başta olmak
            üzere ilgili mevzuat çerçevesinde; güvence altına alınması ve işlenmesi konusuna hassasiyet
            göstermekteyiz.<br><br>

            Bu çerçevede, KVKK kapsamında Veri Sorumlusu sıfatıyla sizleri aydınlatmak istiyoruz.<br><br>

            Şirketimiz ile müşteri, tüketici, tedarikçi veya çalışan sıfatı ile paylaştığınız kişisel verileriniz
            KVKK’ya uygun şekilde, faaliyet ve hizmet amaçlarımız ile bağlantılı ve ölçülü olarak işlenebilecek, yurt
            içi ve yurt dışındaki üçüncü kişilere aktarılabilecek, saklanabilecek, profilleme için kullanılabilecek ve
            sınıflandırılabilecektir.<br><br>

            <b>Kişisel Verilerinizin İşlenme Amacı</b><br><br>

            Kişisel verileriniz;<br><br>

            Hukuka ve dürüstlük kuralının öngördüğü biçimde,<br>
            İşlenme amaçları ile bağlantılı, sınırlı ve ölçülü olarak,<br>
            Doğru ve güncel olarak,<br>
            Belirli açık ve meşru amaçlar ile işlenecektir.<br><br>

            Veri sorumlusu olarak Şirketimiz tarafından kişisel verileriniz, yıllardır süren kalitemizden ödün vermeden,
            sizleri daha iyi tanıyarak ihtiyaçlarınızı anlamak, isteklerinize daha hızlı cevap verebilmek ve sizlerle
            olan iletişimimizi geliştirerek sizlere daha iyi hizmet vermek ve daha kişisel ürün, hizmet ve teklif
            sunabilmek, bunları yapabilmek için analiz yapmak, sizleri ürün ve hizmetlerimiz hakkında
            bilgilendirebilmek, ürün ve hizmetlerimiz hakkında memnuniyetinizi ölçmek, istekleriniz ve ihtiyaçlarınız
            doğrultusunda ürün ve hizmetlerimizi geliştirmek ve çeşitlendirmek için ve bunlarla birlikte gerekli kalite
            ve standart denetimlerimizi yapabilmek ya da mevzuat tarafından öngörülen raporlama ve sair
            yükümlülüklerimizin yerine getirilmesi gibi amaçlar için işlenebilecektir.<br><br>

            Çalışanlarımızın verileri, İş Kanunu ve çalışma ve sosyal güvenlik mevzuatı ile yürürlükte olan diğer
            mevzuatın öngördüğü zorunlulukların yanı sıra insan kaynakları politikamız dahilinde veya performans
            düzeyini ve çalışan memnuniyetini arttırmak ve iş güvenliği ve iş barışının sağlanması gibi işletmesel
            nedenler ile Şirketimiz veya Şirketimizin işbirliği yaptığı veya yetkilendirdiği gerçek veya tüzel kişiler
            tarafından işlenebilecektir.<br><br>

            <b>Kişisel Verilerinizin Aktarılması</b><br><br>

            Kişisel verileriniz yukarıda sayılan amaçlar dahilinde, KVKK ve yürürlükte olan mevzuata uygun olarak, Grup
            şirketleri ile paylaşılabileceği gibi, sayılanlar ile sınırlı olmamak üzere; iş ortaklarımız, iş
            bağlantılarımız, ifa yardımcılarımız ve alt yüklenicilerimiz ile sunduğumuz hizmetin amacı doğrultusunda ya
            da düzenleyici denetleyici kurumlar ve resmi mercilerle ilgili mevzuatın öngördüğü durumlarda yurt içine
            veya yurt dışına aktarılabilecektir.<br><br>

            <b>Kişisel Verilerinizin Toplanma Yöntemi ve Hukuki Sebebi</b><br><br>

            Kişisel verileriniz, yukarıda belirtilen amaç ve kapsamda sözlü, yazılı veya elektronik olarak
            toplanabilir.<br><br>

            Kişisel verileriniz, Şirketimiz veya Şirketimiz adına veri işleyen gerçek ya da tüzel kişiler tarafından
            sayılanlarla sınırlı olmamak üzere, elektronik olarak doldurduğunuz üyelik formu veya sadakat programı
            kapsamına üyeliğiniz sırasında doldurulan üyelik formu ya da internet sitelerimize üyelik veya giriş
            sırasında üye girişi yapmanızı sağlayan sosyal ağlar, internet sitelerimiz veya üçüncü kişilere ait internet
            sitelerinde yer alan bizimle iletişime geçmek üzere doldurduğunuz iletişim formlar, çevrim içi alışveriş
            uygulamaları, elektronik kitap uygulamaları, Şirketimiz ile imzalamış olduğunuz muhtelif sözleşmeler ile
            Şirketimize göndermiş olduğunuz elektronik postalar, faks ve mektuplar gibi araçlar üzerinden, Şirketimiz
            ile yapılan yazılı veya sözlü iletişimler vb. kanallar aracılığıyla sözlü, yazılı veya elektronik ortamda
            toplanmaktadır.<br><br>

            <b>Kişisel Veri Sahibi Olarak Haklarınız</b><br><br>

            KVKK ve yürürlükte bulunan diğer mevzuat çerçevesinde kalmak kaydıyla,<br><br>

            Kişisel verilerinizin işlenip işlenmediğini öğrenme,<br>
            Kişisel verileriniz işlenmişse buna ilişkin bilgi talep etme,<br>
            Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,<br>
            Yurt içinde veya yurt dışında kişisel verilerinizin aktarıldığı üçüncü kişileri bilme,<br>
            Kişisel verilerinizin eksik veya yanlış işlenmiş olması halinde bunların düzeltilmesini isteme,<br>
            KVKK mevzuatında öngörülen şartlar çerçevesinde kişisel verilerinizin silinmesini veya yok edilmesini
            isteme,<br>
            Eksik veya yanlış verilerin düzeltilmesi ile kişisel verilerinizin silinmesi veya yok edilmesini talep
            ettiğinizde, bu durumun kişisel verilerinizin aktarıldığı üçüncü kişilere bildirilmesini isteme,<br>
            İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle aleyhinize bir sonucun
            ortaya çıkmasına itiraz etme,<br>
            Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğramanız halinde bu zararın giderilmesini
            talep etme,haklarına sahipsiniz. Bu haklarınızı kullanmak ile ilgili talebinizi yazılı olarak veya Kişisel
            Verileri Koruma Kurulu tarafından ayrı bir yöntem belirlenmesi halinde bu yönteme uygun olarak bize
            iletebilirsiniz.<br><br>

            Yazılı talebinizi <b>Serbest Liman ve Bölge, Posta Kutusu No : 568 Gazimağusa / Kıbrıs</b> adresine ıslak
            imzalı veya <b>iletisim@kasagame.com</b> kayıtlı elektronik posta adresimize güvenli elektronik imza ile
            imzalanmış olarak gönderebilirsiniz.<br><br>

            Kişisel veri sahibi olarak sahip olduğunuz ve yukarıda belirtilen haklarınızı kullanmak için yapacağınız ve
            kullanmayı talep ettiğiniz hakka ilişkin açıklamalarınızı içeren başvuruda; talep ettiğiniz hususun açık ve
            anlaşılır olması, talep ettiğiniz konunun şahsınız ile ilgili olması veya başkası adına hareket ediyor
            iseniz bu konuda özel olarak yetkili olmanız ve yetkinizi belgelendirilmesi, başvurunun kimlik ve adres
            bilgilerini içermesi ve başvuruya kimliğinizi tevsik edici belgelerin eklenmesi gerekmektedir.<br><br>
          </p>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/Header';
import Footer from '@/components/Footer';

export default {
  name: "PageDetail",
  data() {
    return {
      loading: true,
    }
  },
  components: { Header, Footer },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { name: 'description', content: this.title },
        { name: 'keywords', content: this.title },
        { charset: 'utf-8' }
      ]
    }
  }

}
</script>

<style scoped>
.aboutus {
  background: #130E17;
}
</style>