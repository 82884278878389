import axios from 'axios'
import { config } from './apiServiceConfig'
import {store} from "@/store";
//import { store } from '@/store'
axios.defaults.headers.common['x-access-token'] = `${store.getters["User/token"]}`

const apiUrl = config.$api_url
export default {
    isStreamer(data) {
        return axios.get(apiUrl + '/streamers/check/' + data.id)
            .then(response => response.data)
    },
    setStreamerStreamlabs(data) {
        return axios.post(apiUrl+ '/streamers/register-streamlabs', data)
            .then(response => response.data)
    },
    verifyStreamlabsToken(data) {
        return axios.post(apiUrl+ '/streamers/verify-streamlabs-code', data)
            .then(response => response.data)
    },
    checkMyDetails() {
        return axios.get(apiUrl+ '/streamers/my-details')
            .then(response => response.data)
    },
    streamers(data) {
        return axios.get(apiUrl+'/streamers/list', {
            params: data
        })
            .then(response => response.data)
    },
    getStreamer(data) {
        return axios.get(apiUrl+'/streamers/detail/'+ data)
            .then(response => response.data)
    },
    donate(data) {
        return axios.post(apiUrl+ '/streamers/donate', data)
            .then(response => response.data)
    },
    donates(data) {
        return axios.get(apiUrl+ '/streamers/donates?startDate='+data.startDate+'&endDate='+data.endDate)
            .then(response => response.data)
    }
}