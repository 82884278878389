<template>
<div>
  <Header></Header>
  <div class="clearfix"></div>
  <div class="clearfix"></div>
  <section class="news-page">
    <div class="page-title">
      <div class="container">
        <div class="bg-blur"></div>
        <h1>Haberler </h1>
      </div>
    </div>
    <div class="news-content" >
      <div class="container">
        <div class="news-list">
          <div class="news-left" v-if="!loading">
            <h3>Arama Sonuçları - {{ key }}</h3>
            <div class="item" v-for="(item, index) in data" :key="index">
              <router-link :to="{path: '/article/'+item.id}">
                <div class="item-img"><img :src="item.image"></div>
                <div class="item-text">
                  <h5>{{ item.title }}</h5>
                  <p v-html="item.content.slice(0,120)">
                  </p>
                </div>
              </router-link>
              <div class="clearfix"></div>
            </div>
            <div class="more-bottom" id="moreButton" >
              <div class="news-more" v-if="page < lastPage">
                <div class="more-btn text-white" @click="getMore"><a>DAHA FAZLA</a></div>
              </div>
            </div>
          </div>
          <div class="news-right">
            <div class="best" v-if="!top5loading">
              <h2>ÇOK OKUNANLAR</h2>

              <div v-for="(item, index) in top5Data" :key="index">
                <router-link :to="{path: '/article/'+item.id}" class="item">
                  <span class="left">{{index+1}}.</span>
                  <span class="center">{{item.title}}</span>
                  <span class="right"><img :src="item.image" /></span>
                </router-link>

              </div>
            </div>

            <div class="banner">
              <Adds container="articles_one"></Adds>
            </div>
          </div>
        </div>
        <div class="banner">
          <Adds container="articles_two"></Adds>
        </div>
      </div>
    </div>

    <div class="clearfix"></div>
  </section>
  <Footer></Footer>
</div>
</template>

<script>
import Footer from "@/components/Footer";
import Header from "@/components/Header"
import articlesServices from "@/services/articlesServices";
import Adds from "@/components/Adds/Adds";

import {mapGetters} from "vuex";
export default {
  name: "News",
  components: {
    Header, Footer, Adds
  },
  data() {
    return {
      editorArticlesLoading : true,
      articlesEditor: [],
      top5Data: [],
      top5loading: true,
      page: 1,
      lastPage: 1,
      data: [],
      loading:true,
      moreClick: false,
      key: this.$route.params.key

    }
  },
  methods:{
    getTop5(){
      articlesServices.articlesTop5()
          .then((rest) => {
            this.top5loading = false
            if(rest.state){
              this.top5Data = rest.data
            }
          })
    },
    getArticles() {
      articlesServices.getArticlesSearch({page:this.page, key: this.key})
      .then((res) => {
        if(res.state){
          this.loading = false
          this.data = res.data
          this.page = res.page
          this.lastPage = res.lastPage
        }
      })
    },
    getMore() {
      this.loading = false
      this.page++
      this.moreClick = true
      articlesServices.getArticles({page:this.page})
          .then((res) => {
            if(res.state){
              this.loading = false
              this.page = res.page
              this.lastPage = res.lastPage
              this.data.push(...res.data)
            }
          })
    }

  },
  created() {
    this.getTop5()
    this.getArticles()
  },
  computed: {
    ...mapGetters({
      siteSettings: 'siteInfo/siteSettings'
    })
  },
  watch:
      {
        moreClick: function () {
          if (this.moreClick)
            setTimeout(function () {
              document.getElementById('moreButton').scrollIntoView();
              this.moreClick = false
            }, 1000);

        }
      },
  metaInfo() {
    return {
      title: "Haberler - " + this.siteSettings[0].title,
      meta: [
        {name: 'description', content: "Haberler - " + this.siteSettings[0].description},
        {name: 'keywords', content: "Haberler - " + this.siteSettings[0].keywords},
        {charset: 'utf-8'}
      ]
    }
  },

}
</script>

<style scoped>
.news-page {
  background: #130E17;
}
.news-more {
  margin-top: -170px;
}
.news-more  {
  background: #ccc0;
  box-shadow: 0 -23px 25px #ccc0;
  position: absolute;
  z-index: 3;
  width: 100%;
  padding: 10px;
  margin-top: -80px;
  height: 10px;
}
</style>