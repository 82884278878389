<template>
  <b-modal id="gift"  modal-class="gif-modal"  hide-footer hide-header>

    <div class="gf-left">
      <h2>HEDİYE GÖNDER</h2>
      <div class="gf-list">
        <label>ALICININ E-POSTA ADRESİ</label>
        <input placeholder="ornek@mail.com">
      </div>
      <div class="gf-list">
        <label>ALICININ TELEFON NUMARASI</label>
        <input placeholder="+90">
      </div>
      <div class="gf-list">
        <label>MESAJINIZ</label>
        <input placeholder="Alıcının görmesini istediğiniz mesaj">
      </div>
      <div class="btn-group btn-group-toggle" data-toggle="buttons">
        <label class="btn btn-secondary">
          <input type="radio" name="options" id="option1"> 20 TL
        </label>
        <label class="btn btn-secondary">
          <input type="radio" name="options" id="option2"> 50 TL
        </label>
        <label class="btn btn-secondary">
          <input type="radio" name="options" id="option3"> 100 TL
        </label>
        <input type="text" placeholder="TL" class="input-try"/>
      </div>
      <div class="gf-list margin-0">
        <button class="btn-hover"><span><img :src="require('@/assets/images/paper-plane.svg')"/> GÖNDER</span></button>
      </div>
    </div>
    <div class="gf-right">
      <img :src="require('@/assets/images/gf-right.png')"/>
      <div class="gf-right-text">
        Kasa Kredi hediye kartlarıyla sevdiğin oyunseverleri <br/> mutlu et !
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
name: "Gift"
}
</script>

<style scoped>

</style>