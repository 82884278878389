<template>
  <div>
    <Header></Header>
    <section class="sss">
      <div class="page-title">
        <div class="container">
          <div class="bg-blur"></div>
          <h1>Sıkca Sorulan Sorular</h1>
        </div>
      </div>

      <div class="content">
        <div class="container">
          <div class="accordion" id="accordionExample">
            <div class="card" v-for="(item, index) in questions" :key="index">
              <div class="card-header" id="headingOne">
                <h2>
                  <button type="button" class="collapsed" @click="click(index)">
                    <label>SORU </label>
                    <p>{{ item.title }}</p>
                    <i class="fa fa-angle-down" v-if="showId === index"></i>
                    <i class="fa fa-angle-up" v-else></i>
                  </button>
                </h2>
              </div>
              <b-card-body>
                <div class="collapse " v-if="showId === index">
                  <div>
                    <div class="img"><img :src="require('../assets/images/sss-border.png')"></div>
                    <div class="text">
                      {{ item.answer }}
                    </div>
                    <div class="clearfix"></div>
                  </div>
                </div>

              </b-card-body>
            </div>


          </div>
          <div class="sss-bottom">
            <p>Aradığın sorunun cevabını bulamadıysan, bize sorabilirsin.</p>
            <a href="https://tawk.to/chat/5b25774361a2e64e5fb58883/default" target="_blank"
              style="text-decoration: none"><button class="btn-hover"><span><img
                    :src="require('../assets/images/support.png')"> CANLI DESTEK </span></button></a>
          </div>
        </div>
      </div>

    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/Header';
import Footer from '@/components/Footer';

export default {
  name: "PageDetail",
  data() {
    return {
      loading: true,
      title: '',
      content: '',
      showId: 0,
      questions: [
        {
          title: 'Kasagame.com\'a üyelik ücretli mi?',
          answer: '\n' +
            '                                Hayır. Kasagame.com\'a üye olurken herhangi bir ücret ödemezsiniz. Sadece Kasagame.com üzerinde yer alan ürünleri satın almak için ödeme yapmanız gerekmektedir.\n' +
            '                            '
        },
        {
          title: 'Hangi ödeme yöntemleri kabul edilmektedir?',
          answer: '\n' +
            '                                Kasagame müşterileri Havale, Kredi Kartı, Mobil Ödeme (Kontörlü Hat) ve İninal Kart\'ları ile bakiye yükleyebilir ve bu bakiye ile alışveriş yapabilir.\n' +
            '                            '
        },
        {
          title: 'Ödeme yaptım hesabıma bakiye yüklenmedi',
          answer: 'Havale yöntemini kullanarak ödeme yaptıysanız "Havale Bildirim Formu\'nu doldurmanız gerekmektedir. Doldurduğunuz form muhasebe ekibi tarafından kontrol edilip en geç 10 dakika içerisinde onaylanacaktır.\n'
        },
        {
          title: 'En hızlı para ödeme yöntemi nedir?',
          answer: 'Kredi Kartı ve İninal gibi online anlık hizmet sağlayan servisleri kullanarak hızlı ödeme yapabilirsiniz.\n'
        },
        {
          title: 'PvP Sunucu için EP/CASH almam mümkün mü?',
          answer: 'Elbette. Eğer oynadığınız oyun bizim alt yapımızı kullanıyorsa hesabınıza kolay bir şekilde EP/CASH yüklemesi yapabilirsiniz. Eğer oyununuz bizim alt yapımızı kullanmıyorsa kendilerinin bizimle iletişime geçmesini sağlarsanız en kısa sürede entegrasyon yapılır ve Kasagame ile ödeme yapabilirsini.\n'
        },
        {
          title: 'İlan sattım paramı nasıl alırım?',
          answer: 'Yapmış olduğunuz alışveriş başarılı bir şekilde tamamlandıysa paranız için bir sonraki gün banka hesabınıza transfer edebilirsiniz.\n'
        },
        {
          title: 'Satın aldığım ürünü iade edebilir miyim?',
          answer: 'Maalesef satın aldığınız ürün iade edilemez. Elektronik kodlar hızlı tüketim ürünleri olduğu için iadesi ve iptali söz konusu değildir. Yasa gereği bu ürünü iade almak zorunda değiliz.\n'
        },
        {
          title: 'PvP oyunum var, sizinle çalışabilir miyim?',
          answer: 'Elbette. Kasagame.com\'a üye olun ve mağaza başvurusu yapın. Mağazanız incelendikten sonra en kısa sürede tarafınıza entegrasyon dosyaları gönderilecektir. Yazılım bilginiz ve yazılımcınız yoksa Kasagame Teknik Ekibi sizin için entegrasyonu ÜCRETSİZ yapmaktadır.\n'
        },
        {
          title: 'Ödeme hizmeti almak için şirket ya da vergi levhası gerekli mi?',
          answer: 'Hayır. kasagame.com (Decade Bilişim LTD) Kıbrıs merkezli bir ödeme hizmeti sunan şirkettir ve bizim müşterimiz olmak için Şirket Sahibi/Vergi Levhası sahibi olmanıza gerek yoktur.\n'
        },
      ]
    }
  },
  components: { Header, Footer },
  methods: {
    click(id) {
      this.showId = id
    }
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { name: 'description', content: this.title },
        { name: 'keywords', content: this.title },
        { charset: 'utf-8' }
      ]
    }
  }

}
</script>

<style scoped>
.sss {
  background: #130E17;
}
</style>