<template>
  <div>
    <Header></Header>
    <section class="article-page">
      <div class="page-title">
        <div class="container">
          <div class="bg-blur"></div>
          <div class="title-prev"><router-link :to="{path: '/main/articles'}"><img :src="require('@/assets/images/title-prev.png')"> Geri Dön</router-link></div>
          <h1>Makale Düzenle</h1>

        </div>

      </div>
      <div class="article-content">
        <div class="container">
          <div class="article-form">
            <div class="row">
              <div class="col-md-6" v-if="loading">
                <label>MAKALE BAŞLIĞI</label>
                <b-skeleton width="100%" class="bg-dark"></b-skeleton>
              </div>
              <div class="col-md-6" v-if="!loading">
                <label>MAKALE BAŞLIĞI</label>
                <input type="text" v-model="title">
              </div>
              <div class="col-md-6" v-if="loading">
                <label>KATEGORİ</label>
                <b-skeleton width="100%" class="bg-dark"></b-skeleton>
              </div>
              <div class="col-md-6" v-if="!loading">
                <label>KATEGORİ</label>
                <b-select :options="[{value:'1',text:'Genel'}]" v-model="category" >
                </b-select>
              </div>
            </div>
            <div class="row" v-if="loading">
              <div class="col-md-12 text-white">
                <label>İÇERİK</label>
                <b-skeleton height="100%" class="bg-dark"></b-skeleton>
              </div>
            </div>
            <div class="row" v-if="!loading">
              <div class="col-md-12 text-white">
                <label>İÇERİK</label>
                <vue-editor id="editor" useCustomImageHandler @image-added="handleImageAdded" v-model="htmlForEditor"></vue-editor>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6" v-if="loading">
                <label>ÖNE ÇIKAN GÖRSEL <img :src="require('@/assets/images/asoru.png')"></label>
                <b-skeleton-img class="avatar"></b-skeleton-img>
              </div>
              <div class="col-md-6" v-if="!loading">
                <label>ÖNE ÇIKAN GÖRSEL <img :src="require('@/assets/images/asoru.png')"></label>
                <img :src="image" style="max-width: 100%;max-height: 100%;" v-if="!imageSelected">
                <label for="file" class="custom-file-upload file-type" v-if="!imageSelected">
                  Gözat <i class="fal fa-cloud-upload"></i>
                </label>
                <input type="file" multiple id="file" accept="image/jpeg, image/png, image/gif" v-on:change="onFileChange" v-if="!imageSelected"/>
                <div class="text-success" v-if="imageSelected"> <small>{{files.length}} adet görsel seçildi.</small> </div>
                <div  v-if="imageLengthError"  class="text-danger">
                  <small>En fazla 1 adet görsel yükleyebilirsiniz.</small>
                </div>
              </div>
              <div class="col-md-6" v-if="loading">
                <label>ETİKETLER</label>
                <b-skeleton class="bg-dark"></b-skeleton>
              </div>
              <div class="col-md-6" v-if="!loading">
                <label>ETİKETLER</label>
                <input type="text" placeholder="Etiketleri virgül ile ayırarak giriniz." v-model="tags">
              </div>
            </div>
            <div class="row form-footer">
              <div class="col-md-6">
                <ul>
                  <li v-if="!loading"><button class="btn-save" @click="saveDraft">TASLAK OLARAK KAYDET</button></li>
                  <li v-if="!loading"><button class="btn-hover" @click="saveArticle"><span><img :src="require('@/assets/images/ar.png')"> YAYINLA</span></button></li>
                  <li v-if="loading">
                    <button class=" btn-hover " v-if="loading"> <span>  <i class="fas fa-spinner fa-spin"></i></span> </button>
                  </li>

                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import authService from "@/services/authService";
import Header from "@/components/Header";
import Footer from "@/components/Footer";
export default {
  name: "AddArticle",
  data() {
    return {
      loading: true,
      title: '',
      category: '',
      tags: '',
      draft: false,
      htmlForEditor: "",
      files: [],
      imageSelected: false,
      imageLengthError: false,
      image: '',
      editorSettings: {
        modules: {
          imageResize: {}
        }
      }
    }
  },
  components: {
    VueEditor,
    Header,
    Footer
  },
  methods:{
    onFileChange(e) {
      if (e.target.files.length > 1) {
        this.imageLengthError = true
        this.files = null
      } else {
        let files = e.target.files || e.dataTransfer.files;
        if (!files.length)
          return;
        this.files = files
        this.imageLenghtError = false
        this.imageSelected = true
      }
    },
    message(data){
      this.$swal({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        icon: data.icon,
        title: data.title,
        text: data.message,
      });
    },
    saveDraft(){
      this.draft = true
      this.saveArticle()
    },
    getPreview() {
      authService.articlePreview({id: this.$route.params.id})
          .then((res) =>{
            if(res.state) {
              this.loading = false
              this.htmlForEditor = res.data.content
              this.title = res.data.title
              this.category = res.data.category_id
              this.tags = res.data.tags
              this.image = res.data.image
            }else{
              this.$router.push('/main/articles')
            }
          })
    },
    saveArticle() {
      this.loading = true
      authService.editArticle({data: {id: this.$route.params.id,title: this.title,category: this.category,content: this.htmlForEditor, tags: this.tags, draft: this.draft},files: this.files})
      .then((res) => {
        if(res.state) {
          if(this.draft){
            this.message({icon: 'success', title: 'Teşekkürler', message: 'Makale taslaklara eklendi, yönlendiriliyorsunuz..'})
          }else{
            this.message({icon: 'success', title: 'Teşekkürler', message: 'Makaleniz başarıyla eklendi, en kısa süre içerisinde yayınlanacak!'})
          }
          this.title = ''
          this.category = ''
          this.content = ''
          this.tags = ''
          this.imageSelected = false
          this.loading = false
          this.draft = false
          this.files = []
          setTimeout(() =>{
            this.$router.push('/main/articles')
          },1500)
        }else{
          this.message({icon: 'error', title: 'Ops!', message: res.message})
        }
      })
    },
    handleImageAdded: function(file, Editor, cursorLocation, resetUploader) {
      // An example of using FormData
      // NOTE: Your key could be different such as:
      // formData.append('file', file)

      authService.userImageUpload(file)
      .then((res) =>{
        let url = res.url; // Get url from response
        Editor.insertEmbed(cursorLocation, "image", url);
        resetUploader();
      })
    }
  },
  metaInfo() {
    return {
      title: 'Makale Düzenle - Hesabım',
    }
  },
  created() {
    this.getPreview()
  }

}

</script>

<style scoped>

</style>