<template>
<div>
  <Header></Header>
  <section class="adver-detail-page">
    <div class="page-title">
      <div class="container">
        <div class="bg-blur"></div>
        <ul v-if="loading">
          <li><router-link to="/advertisements">İlanlar</router-link><img :src="require('@/assets/images/br.png')"/></li>
        </ul>
        <ul v-if="!loading">
          <li><router-link to="/advertisements">İlanlar</router-link><img :src="require('@/assets/images/br.png')"/></li>
          <li><router-link :to="{path: '/advertisement/'+details.id}">{{ details.title }}</router-link></li>
        </ul>
      </div>
    </div>
    <div class="adver-detail-content">
      <div class="container">
        <div class="content-left">
          <h2 v-if="!loading">{{ details.title }}</h2>
          <h2 v-if="loading"><b-skeleton width="100%" height="100%" v-if="loading" class="bg-dark" animation="throb"></b-skeleton></h2>
          <div class="carousel slide advertisements-slider" data-ride="carousel" v-if="loading">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <picture>
                  <source media="(max-width: 992px)" srcset="" />
                  <b-skeleton-img width="100%" height="100%" variant="dark" animation="throb"/>
                </picture>
              </div>
            </div>
          </div>
          <div class="carousel slide advertisements-slider" data-ride="carousel" v-if="!loading">
            <div class="carousel-inner">
              <carousel :per-page="1"  :loop=true :autoplay=true :paginationEnabled=true>
                <slide v-for="(item, id) in details.images" :key="id">
                  <div class="carousel-item active">
                    <picture>
                      <source media="(max-width: 992px)" srcset="" />
                      <img :src="item.image_url" class="w-100" @click="show(id)"/>
                    </picture>
                  </div>
                </slide>
              </carousel>
              <image-viewer ref="viewer"/>
            </div>
          </div>
        </div>
        <div class="content-right">
          <label class="number" v-if="!loading">İLAN NO: #{{ details.id }}</label>
          <h2 v-if="!loading">{{ details.title }}</h2>
          <h3 v-if="loading"><b-skeleton width="100%" height="10%" v-if="loading" class="bg-dark" animation="throb"></b-skeleton></h3>
          <div class="user-information">
            <div class="avatar" v-if="loading">
              <div class="avatar-img"><b-skeleton-img animation="throb"  variant="dark"></b-skeleton-img></div>
              <div class="avatar-text">
                <div class="col-md-9">
                  <h5><b-skeleton width="50%" height="50%" v-if="loading" class="bg-dark" animation="throb"></b-skeleton></h5>
                  <h5><b-skeleton width="30%" height="50%" v-if="loading" class="bg-dark" animation="throb"></b-skeleton></h5>
                </div>
                <div class="col-md-3 text-right">
                  <b-skeleton type="button" class="bg-dark" animation="throb"></b-skeleton>
                </div>
              </div>
              <div class="clearfix"></div>
            </div>
            <div class="avatar" v-if="!loading">
              <div class="avatar-img"><img :src="details.user.profilePic"/></div>
              <div class="avatar-text">
                <div class="col-md-9">
                  <h5><router-link :to="{path: '/profile/'+details.user.username}" class="text-white">{{ details.user.username }}</router-link></h5>
                  <label>Satıcı Puanı: <span>{{ details.user.point }}</span></label>
                </div>
                <div class="col-md-3 text-right">
                  <button v-b-modal.sendMessage><i class="fas fa-envelope"></i> MESAJ GÖNDER</button>
                </div>
              </div>
              <div class="clearfix"></div>
            </div>
            <div class="information-list">
              <div class="col-md-3 text-right">
                <label class="number">İLAN NO: #{{ details.id }}</label>
                <label><img :src="require('@/assets/images/inf-date.png')"/> {{ details.date | formatDate}}</label>
              </div>
              <div class="col-md-9" v-if="loading">
                <b-skeleton width="65%" height="100%" v-if="loading" class="bg-dark" animation="throb"></b-skeleton>
                <b-skeleton width="60%" height="100%" v-if="loading" class="bg-dark" animation="throb"></b-skeleton>
                <b-skeleton width="55%" height="100%" v-if="loading" class="bg-dark" animation="throb"></b-skeleton>
              </div>
              <div class="col-md-9" v-if="!loading">
                <ul v-if="details.reserve || details.unique || details.dual || details.set">
                  <li v-if="details.reserve"><img :src="require('@/assets/images/inf-line.png')"/> Reserve</li>
                  <li v-if="details.unique"><img :src="require('@/assets/images/inf-line.png')"/> Unique</li>
                  <li v-if="details.dual"><img :src="require('@/assets/images/inf-line.png')"/> Dual</li>
                  <li v-if="details.set"><img :src="require('@/assets/images/inf-line.png')"/> Set</li>
                </ul>
              </div>

            </div>
            <div class="user-inf-bottom">
              <label v-if="!loading"> {{ details.price | currency }} TL</label>
              <div class="clearfix visible-xs"></div>
              <button class="btn btn-readmore btn-hover" v-if="loading"><i class="fas fa-spinner fa-spin"></i></button>
              <button class="btn-hover" v-if="!loading" @click="buy(id)"><span><img :src="require('@/assets/images/arrow-right.png')"> SATIN AL</span></button>
              <button class="notification" v-if="!loading"><img :src="require('@/assets/images/bell.png')"/></button>
            </div>
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="content-explanation">
          <ul class="nav nav-pills">
            <li class="nav-item">
              <a class="nav-link " :class="{'active': activeTab =='1'}" @click="tab(1)">ÜRÜN AÇIKLAMASI</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" :class="{'active': activeTab =='2'}" @click="tab(2)">İLAN SİSTEMİ NASIL ÇALIŞIR ?</a>
            </li>
          </ul>
          <div class="tab-content">
            <div class="tab-pane fade show active" v-if="activeTab == '1'">
              <p class="pre-formatted">
                {{details.description}}
                <b-skeleton width="50%" height="100%" v-if="loading" class="bg-dark" animation="throb"></b-skeleton>
                <b-skeleton width="55%" height="100%" v-if="loading" class="bg-dark" animation="throb"></b-skeleton>
                <b-skeleton width="40%" height="100%" v-if="loading" class="bg-dark" animation="throb"></b-skeleton>
                <b-skeleton width="45%" height="100%" v-if="loading" class="bg-dark" animation="throb"></b-skeleton>

              </p>

            </div>
            <div class="tab-pane fade active" v-if="activeTab == '2'">...</div>
          </div>
        </div>

        <div class="clearfix"></div>
        <div class="row-fluid sales adver-list">
          <Advertisements :advertisementList="advertisementList" v-if="advertisementList.length > 0"></Advertisements>
        </div>
        <Adds container="advertisement_detail"></Adds>
      </div>
    </div>
    <div class="clearfix"></div>
  </section>
  <Footer></Footer>
  <buy-advertisement></buy-advertisement>
  <div v-if="!loading">
    <SendMessage :username="details.user.username"></SendMessage>
  </div>
</div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import advertisementsService from "@/services/advertisementsService";
import Advertisements from "@/components/Advertisements/Advertisements";
import { mapGetters } from "vuex";
import { Carousel, Slide } from 'vue-carousel';
import imageViewer from "@/components/imageViewer";
import Adds from "@/components/Adds/Adds";
import BuyAdvertisement from "@/components/Modals/BuyAdvertisement";
import SendMessage from "@/components/Modals/SendMessage";

export default {
  name: "AdvertisementDetail",
  data() {
    return {
      id: this.$route.params.id,
      adds : {
        advertisementAdds: {
          title: "title gelcek",
          link: "https://www.sultanwar.com/",
          imageUrl: "https://demo.kasagame.com/images/dirilis-kasagame-banner.gif"
        },
      },
      loading: true,
      details:
          {
            "id": "",
            "user_id": "",
            "game_id": "",
            "price": "",
            "title": "",
            "description": "",
            "doping_homepage": true,
            "doping_boldtitle": false,
            "doping_colorframe": false,
            "doping_adds": false,
            "status": "",
            "is_account": false,
            "reserve": true,
            "unique": true,
            "dual": true,
            "set": true,
            "date": "",
            "images": [
              {
                "id": "",
                "image_url": ""
              }
            ],
            "user": {
              "username": "",
              "id": "",
              "point": "",
              "profilePic": ""
            },
            "image": "",
            "game": {
              "id": "5",
              "image": "",
              "title": "",
              "link": "",
              "is_mall": false
            },
            "gameTitle": "",
            "gameLink": "",
            "gameIsMall": false,
            "gameImage": "",
            "gameId": ""
          },
      activeTab: '1',
      advertisementList: []
    }
  },
  computed: {
    ...mapGetters({
      siteSettings: 'siteInfo/siteSettings'
    })
  },
  methods: {
    errorMessage(message) {
      this.$swal({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        icon: 'error',
        title: 'Hata',
        text: message,
      });
    },
    buy() {
      if(this.details.user_id == this.$store.getters["User/user"].id){
        this.errorMessage('Kendinize ait ilanı satın alamazsınız.')
      }else {
        this.$bvModal.show('buyAdvertisement')
      }
    },
    show(index) {
            this.$refs.viewer.show(
                this.details.images,
                index
        );
      },
      tab(value) {
      this.activeTab = value
    },
    getAdvertisement() {
      advertisementsService.getAdvertisement({id: this.id})
      .then((res) => {
        this.loading = false
        if(res.list.length > 0) {
          this.loading = false
          this.details = res.list[0]
          if(res.list[0].images.length == 0) {
            let setImageData = {image_url: res.list[0].image}
            this.details.images.push(setImageData)
          }
        }else{
          this.$router.push('/')
        }
      })
    },
    getAdvertisementsGameDetail() {
      if(!this.loading) {
        advertisementsService.getAdvertisementsGameDetail({game_id: this.details.game_id})
            .then((res) => {
              this.advertisementList = res.list
            })
      }
    }
  },
  components: {
    Header,
    Footer,
    Advertisements,
    Carousel,
    Slide,
    imageViewer,
    Adds,
    BuyAdvertisement,
    SendMessage
  },
  created() {
    this.getAdvertisement()
  },
  metaInfo() {
    return {
      title: this.details.title+" - " + this.siteSettings[0].title,
      meta: [
        {name: 'description', content: this.details.title+" - " + this.siteSettings[0].description},
        {name: 'keywords', content: this.details.title+" - " + this.siteSettings[0].keywords},
        {charset: 'utf-8'}
      ]
    }
  },
  watch: {
    loading: function () {
      if(!this.loading){
        this.getAdvertisementsGameDetail()
      }
    }
  }
}
</script>

<style scoped>
.adver-detail-page {
  background:#130E17;
}
.pre-formatted {
  white-space: pre;
}
/deep/.VueCarousel-pagination {
  background: #1e1622;
  border-radius: 10px;
  margin-top: 5px;
}
/deep/.VueCarousel-dot-container {
  margin-top: 2px;
}
</style>