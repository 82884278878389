<template>
  <div class="carousel slide slider">
    <div class="carousel-inner" v-if="loading">
      <carousel :per-page="1"  :loop=true :autoplay=true :paginationEnabled=false>
        <slide >
          <div class="carousel-item active bg-dark">
            <picture>
              <source media="(max-width: 992px)" srcset="" />
            </picture>

            <div class="carousel-caption d-md-block">
              <span>
               <button class="btn btn-readmore btn-hover"><i class="fas fa-spinner fa-spin"></i></button>
              </span>
            </div>
          </div>
        </slide>
      </carousel>
    </div>
    <div class="carousel-inner" v-if="!loading">
      <b-carousel
          id="carousel-1"
          v-model="slide"
          controls
          indicators
          background="#ababab"
          img-width="1024"
          img-height="480"
          style="text-shadow: 1px 1px 2px #333;"
          @sliding-start="onSlideStart"
          @sliding-end="onSlideEnd"
          :interval="3000"
          :autoplay="true"
      >

        <b-carousel-slide v-for="(item, id) in data" :key="id" >
          <template #img>
            <img
                class="d-block img-fluid w-100"
                width="1024"
                height="480"
                :src="item.imageUrl"
                alt="image slot"
            >
            <div class="carousel-caption d-md-block">
              <span v-html="item.content"></span>
              <a :href="item.buttonLink" target="_blank" class="btn btn-readmore btn-hover"><span> {{ item.buttonText }} </span></a>
            </div>

          </template>
        </b-carousel-slide>

      </b-carousel>
    </div>



  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';

export default {
  name: "Slider",
  data() {
    return {
      loading: this.$store.getters.homeLoading,
      slide: 0,
      sliding: null
    }
  },
  components: {
    Carousel,
    Slide
  },
  props: ['data'],
  methods: {
    onSlideStart() {
      this.sliding = true
    },
    onSlideEnd() {
      this.sliding = false
    }
  },
  watch: {
    '$store.getters.homeLoading': function() {
      this.loading = this.$store.getters.homeLoading
    }
  }
}
</script>

<style scoped>

</style>