<template>
  <div>
    <div class="balance-modal-check">
      <i class="fas fa-hourglass-half fa-5x text-warning" />
      <h5>
        <label>Teşekkürler.</label>
        <label>Ödemenin başarılı olması halinde maks. 15 dakika içerisinde bakiyeniz otomatik aktarılacaktır.</label>
        <label>Hesabınızdan bakiyenizi kontrol edebilirsiniz . .</label>
      </h5>
    </div>
  </div>
</template>

<script>
export default {
  name: "iframeDetail"
}
</script>

<style scoped>

</style>