export default {
    setProduct(state, data) {
        state.cart.push(data)
    },
    updateProduct(state, data) {
        let productIndex = state.cart.findIndex(e => e.id === data.id)
        state.cart[productIndex].count = data.count
    },
    removeProduct(state, data) {
        let productIndex = state.cart.findIndex( e=> e.id == data.id)
        state.cart.splice(productIndex, 1);
    },
    clearCart(state){
        state.cart = []
    }
}