<template>
<div>
  <Header></Header>
  <section class="aboutus">
    <div class="page-title">
      <div class="container">
        <div class="bg-blur"></div>
        <h1 v-if="!loading">{{ title }}</h1>
        <h1 v-if="loading"><b-skeleton></b-skeleton></h1>
      </div>
    </div>
    <div class="content">
      <div class="container" v-html="content">
      </div>
    </div>
  </section>
  <Footer></Footer>
</div>
</template>

<script>
import Header from '@/components/Header';
import Footer from '@/components/Footer';
import siteInfo from "@/services/siteInfo";

export default {
  name: "PageDetail",
  data() {
    return {
      loading: true,
      title: '',
      content: ''
    }
  },
  components: {Header, Footer},
  methods: {
    detail(){
      siteInfo.pageDetail({link: this.$route.params.link})
      .then((res) => {
        this.loading = false
        if(res.state){
          this.title = res.data.title
          this.content = res.data.content
        }else{
          this.$router.push({ path: '/'})
        }
      })
    }
  },
  created() {
    this.detail()
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        {name: 'description', content: this.title},
        {name: 'keywords', content:this.title},
        {charset: 'utf-8'}
      ]
    }
  }

}
</script>

<style scoped>
.aboutus{
  background: #130E17;
}

</style>