import axios from 'axios'
import { config } from './apiServiceConfig'
import {store} from "@/store";
//import { store } from '@/store'
const apiUrl = config.$api_url

export default  {
    getGame() {
        return axios.get(apiUrl+'/advertisement-games')
            .then(response => response.data)
    },
    buyAdvertisement(data) {
        return axios.post(apiUrl+'/advertisements/buy', data)
            .then(response => response.data)
    },
    getAdvertisementGames() {
        return axios.get(apiUrl+'/advertisements/advertisement-games')
            .then(response => response.data)
    },
    getMyOneAdvertisement(id) {
        axios.defaults.headers.common['x-access-token'] = `${store.getters["User/token"]}`
        return axios.get(apiUrl+'/advertisements/my/'+id)
            .then(response => response.data)
    },
    getAdvertisementGamesHome(data) {
        return axios.post(apiUrl+'/advertisements/home', data)
            .then(response => response.data)
    },
    getAdvertisementsList(data) {
        return axios.post(apiUrl+'/advertisements/list', data)
            .then(response => response.data)
    },
    getAdvertisementsGameDetail(data) {
        return axios.post(apiUrl+'/advertisements/game', data)
            .then(response => response.data)
    },
    getAdvertisement(data) {
        return axios.get(apiUrl+'/advertisement/'+data.id)
            .then(response => response.data)
    },
    getDoping(data) {
        axios.defaults.headers.common['x-access-token'] = `${store.getters["User/token"]}`
        return axios.post(apiUrl+'/user/get-doping-advertisement',data)
            .then(response => response.data)
    },
    removeAdvertisement(data) {
        axios.defaults.headers.common['x-access-token'] = `${store.getters["User/token"]}`
        return axios
            .post(apiUrl+'/advertisements/remove', data)
            .then((response) => {
                return response
            })
    },
    cancelAdvertisementOrderBySaler(data) {
        axios.defaults.headers.common['x-access-token'] = `${store.getters["User/token"]}`
        return axios
            .post(apiUrl+'/advertisements/cancel-order', data)
            .then((response) => {
                return response
            })
    },
    getMyAdvertisements(data) {
        axios.defaults.headers.common['x-access-token'] = `${store.getters["User/token"]}`
        return axios
            .get(apiUrl+'/advertisements/my-advertisements', {
                params: data
            })
            .then((response) => {
                return response
            })
    },
    createAdvertisement(data) {
        axios.defaults.headers.common['x-access-token'] = `${store.getters["User/token"]}`
        const formData = new FormData();
        for (let i = 0; i < data.files.length; i++) {
            let file = data.files[i];

            formData.append('files', file);
        }

        formData.append('data', JSON.stringify(data.data));


        return axios.post(apiUrl+'/user/add-advertisement',formData,
            {
                headers: {
                    'Content-Type': 'undefined'
                }
            }
        )
            .then(response => response.data)
    },
    confirmAdvertisement(data) {
        axios.defaults.headers.common['x-access-token'] = `${store.getters["User/token"]}`
        const formData = new FormData();
        for (let i = 0; i < data.files.length; i++) {
            let file = data.files[i];

            formData.append('files', file);
        }

        formData.append('data', JSON.stringify(data.data));


        return axios.post(apiUrl+'/user/confirm-advertisement-saler',formData,
            {
                headers: {
                    'Content-Type': 'undefined'
                }
            }
        )
            .then(response => response.data)
    }
}