<template>
<div>
  <Header></Header>
  <section class="games-page">
    <div class="page-title">
      <div class="container">
        <div class="bg-blur"></div>
        <h1>Oyunlar
          <div class="title-search">
            <div class="input-group mb-3">
              <input v-model="search" @keyup.enter="getSearch()" type="text" class="form-control" id="filter" placeholder="Oyun adı giriniz" aria-label="Recipient's username" aria-describedby="button-addon2">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary" type="button" id="button-addon2"><img :src="require('@/assets/images/title-search.png')"/></button>
              </div>
            </div>
          </div>
        </h1>
        <div class="to-sort">
          <div class="dropdown">
            <b-navbar class="">
              <b-navbar-nav>

                <!-- Navbar dropdowns -->
                <b-nav-item-dropdown text="SIRALA" right toggle-class="btn btn-secondary">
                  <b-dropdown-item @click="sortByTitle()">A'dan Z'ye</b-dropdown-item>
                  <b-dropdown-item @click="sortByNews()">Önce En Yeniler</b-dropdown-item>
                </b-nav-item-dropdown>

              </b-navbar-nav>
            </b-navbar>
          </div>


        </div>
      </div>
    </div>
    <div class="games-content" id="results">
      <div class="container" >
        <b-alert variant="warning" show v-if="games.length < 1 && !loading">
          <strong>Aradığınız kritere uygun sonuç bulunamadı.</strong>
        </b-alert>
        <div class="row" v-if="loading">
          <div class="col-md-3"  v-for="index in 8" :key="index">
            <div class="item">
                <a>
                  <b-skeleton-img animation="throb"></b-skeleton-img>
                  <strong><b-skeleton animation="throb" class="bg-dark "></b-skeleton></strong>
                </a>
            </div>
          </div>
        </div>
        <div class="row" v-if="!loading">
          <div class="col-md-3" v-for="(game, index) in games" :key="index">
            <div class="item">
              <router-link :to="{path: 'game/'+game.link}">
              <a>
                <img :src="game.image" />
                <strong>{{ game.title }}</strong>
              </a>
              </router-link>
            </div>
          </div>
          </div>
       <div class="clearfix"></div>
        <div class="more-bottom" id="moreButton" >
          <div class="game-more" v-if="page < lastPage">
            <div class="more-btn text-white" @click="getMore"><a>DAHA FAZLA</a></div>
          </div>
        </div>

      </div>

    </div>
    <div class="clearfix"></div>
  </section>
  <Footer></Footer>
</div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import gameService from "@/services/gameService";
import {mapGetters} from 'vuex';

export default {
  name: "Games",
  data() {
    return {
      games: [],
      page: 1,
      lastPage: 1,
      search: "",
      loading: true
    }
  },
  components: {
    Header,
    Footer
  },
  computed: {
    ...mapGetters({
      siteSettings: 'siteInfo/siteSettings'
    })
  },
  metaInfo() {
    return {
      title: "Oyunlar - " + this.siteSettings[0].title,
      meta: [
        {name: 'description', content: "Oyunlar - " + this.siteSettings[0].description},
        {name: 'keywords', content: "Oyunlar - " + this.siteSettings[0].keywords},
        {charset: 'utf-8'}
      ]
    }
  },
  methods: {
    sortByNews() {
      this.loading = true
      gameService.getGames({page: this.page, search: this.search, sort: 'news'})
          .then((response) => {
            this.loading = false
            this.games = response.games
            this.page = response.page
            this.lastPage = response.lastPage
          })
    },
    sortByTitle() {
      this.loading = true
      gameService.getGames({page: this.page, search: this.search, sort: ''})
          .then((response) => {
            this.loading = false
            this.games = response.games
            this.page = response.page
            this.lastPage = response.lastPage
          })
    },
    getGames() {
      this.loading = true
      gameService.getGames({page: this.page})
          .then((response) => {
            this.loading = false
            this.games = response.games
            this.page = response.page
            this.lastPage = response.lastPage
          })

    },
    getSearch() {
      gameService.getGames({page: 1, search: this.search})
          .then((response) => {
            this.games = response.games
            this.page = response.page
            this.lastPage = response.lastPage
          })
    },
    getMore() {
      this.loading = false
      this.page++

      gameService.getGames({page: this.page, search: this.search})
          .then((response) => {
            this.games.push(...response.games)
            this.page = response.page
            this.lastPage = response.lastPage
          })
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getGames()
    })
  },
  watch:
      {
        games: function () {
          if (this.page < this.lastPage)
            document.getElementById('moreButton').scrollIntoView();
        }
      }
}
</script>

<style scoped>
.games-page {
  background: #130E17;
}
.form-control, .form-control:focus, .form-control:hover {
  width: 1%;
}
.game-more {
  background: #ccc0;
  box-shadow: 0 -23px 25px #ccc0;
  position: absolute;
  z-index: 3;
  width: 100%;
  margin-top: -30px;
  height: 0px;
}
.games-content {
  min-height: 400px;
}
.to-sort .dropdown {
  position: absolute;
  right: 0;
  top: -40px;
}
</style>