<template>
<div>
  <Header></Header>
  <section class="news-page detail">
    <div class="page-title">
      <div class="container">
        <div class="bg-blur"></div>
        <div class="title-prev"><router-link :to="{path: '/main/articles'}"><img :src="require('@/assets/images/title-prev.png')"> Geri Dön</router-link></div>

        <h1 v-if="!loading">{{ data.title }}</h1>
        <h1 v-if="loading">
          <b-skeleton v-if="loading" class="bg-dark" width="50%" animation="throb"></b-skeleton>
        </h1>
      </div>
    </div>
    <div class="news-content" >
      <div class="container">
        <div class="news-list">
          <div class="news-left">
            <div class="detail-top" v-if="!loading">
              <div class="avatar">
                <img :src="data.user.profilePic">
              </div>
              <div class="avatar-text">
                <label><a href="#">{{ data.user.username }}</a> tarafından yazıldı.</label>
                <ul>
                  <li><img :src="require('@/assets/images/nd.png')"> {{ data.date  | formatDate}}</li>
                  <li><img :src="require('@/assets/images/no.png')"> Okuma Süresi: {{ data.stats.text }}</li>
                </ul>
              </div>
              <div class="clearfix"></div>
            </div>
            <div class="detail-top" v-else>
              <b-skeleton type="avatar" class="bg-dark"></b-skeleton>
              <div class="avatar-text">
                <label><b-skeleton animation="throb" width="50%" class="bg-dark"></b-skeleton></label>
x              </div>
              <div class="clearfix"></div>
            </div>
            <div class="news-detail" v-if="!loading">
              <img :src="data.image">
              <div class="text" >
                <p v-html="data.content"></p>
                <div class="row">
                  <div class="col-md-6">
                    <ul>
                      <li><a href="#">callof</a></li>
                      <li><a href="#">oyun</a></li>
                      <li><a href="#">test</a></li>
                    </ul>
                  </div>
                  <div class="col-md-6 text-right">
                    <a href="#"><i class="fa fa-external-link-alt"></i> PAYLAŞ</a>
                  </div>
                </div>
              </div>
            </div>
          </div >
          <div class="news-right">
            <h2>ÇOK OKUNANLAR</h2>
            <div class="best">
              <a href="#" class="item">
                <span class="left">1.</span>
                <span class="center">Lorem Ipsum Dolor Sit Amet</span>
                <span class="right"><img :src="require('@/assets/images/list.png')"></span>
              </a>
              <a href="#" class="item">
                <span class="left">2.</span>
                <span class="center">Lorem Ipsum Dolor Sit Amet</span>
                <span class="right"><img :src="require('@/assets/images/nl2.png')"></span>
              </a>
              <a href="#" class="item">
                <span class="left">3.</span>
                <span class="center">Lorem Ipsum Dolor Sit Amet</span>
                <span class="right"><img :src="require('@/assets/images/list.png')"></span>
              </a>
              <a href="#" class="item">
                <span class="left">4.</span>
                <span class="center">Lorem Ipsum Dolor Sit Amet</span>
                <span class="right"><img :src="require('@/assets/images/list.png')"></span>
              </a>
              <a href="#" class="item">
                <span class="left">5.</span>
                <span class="center">Lorem Ipsum Dolor Sit Amet</span>
                <span class="right"><img :src="require('@/assets/images/list.png')"></span>
              </a>

            </div>
            <div class="banner">
              <a href="#">
                <img :src="require('@/assets/images/banner-writer.png')">
              </a>
            </div>
            <div class="sponsor-right">
              <label>SPONSORLU</label>
            </div>
          </div>
        </div>
        <div class="banner">
          <a href=""><img :src="require('@/assets/images/banner.png')"></a>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
  </section>
  <Footer></Footer>
</div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import authService from "@/services/authService";

export default {
  name: "ArticlePreview",
  data() {
    return {
      data: null,
      loading: true
    }
  },
  components: {
    Header, Footer
  },
  methods: {
    getPreview() {
      authService.articlePreview({id: this.$route.params.id})
      .then((res) =>{
        if(res.state) {
          this.loading = false
          this.data = res.data
        }else{
          this.$router.push('/main/articles')
        }
      })
    }
  },
  created() {
    this.getPreview();
  }
}
</script>

<style scoped>
.detail {
  background: #130E17;
}
.title-prev {
  position: absolute;
  top: -30px;
}

</style>