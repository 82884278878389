<template>
  <section class="news" v-if="data.length > 0">
    <div class="container">
      <h1>Haberler</h1>
      <div class="row">
        <div class="col-md-8 list">
          <div class="row"  v-if="loading">
            <div class="col-md-6" v-for="id in 2  " :key="id">
              <div class="item" >
                  <b-skeleton-img></b-skeleton-img>
                  <strong><b-skeleton></b-skeleton></strong>
              </div>
            </div>
          </div>

          <div class="row" v-if="!loading">
            <div class="col-md-6" v-for="(item, index) in data" :key="index">
              <div class="item">
                <router-link :to="/article/+item.id">
                  <img :src="item.image"  height="197" />
                  <strong>{{ item.title }}</strong>
                </router-link>
              </div>
            </div>
          </div>


          <div class="more" v-if="!loading">
            <router-link to="/news">DAHA FAZLA</router-link>
          </div>
        </div>
        <div class="col-md-4">
          <div class="best" v-if="!loading">
            <h2>ÇOK OKUNANLAR</h2>
            <div v-for="(item, index) in top5Data" :key="index">
              <router-link :to="{path: '/article/'+item.id}" class="item">
                <span class="left">{{index+1}}.</span>
                <span class="center">{{item.title}}</span>
                <span class="right"><img :src="item.image" /></span>
              </router-link>
            </div>
          </div>
          <div class="banner" v-if="!loading">
            <Adds container="dashboard_articles"></Adds>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import articlesServices from "@/services/articlesServices";
import Adds from "@/components/Adds/Adds";
export default {
  name: "News",
  data() {
    return {
      data: '',
      top5Data : '',
      loading: true
    }
  },
  components: {Adds},
  methods: {
    getLastArticles() {
      articlesServices.articlesHome()
      .then((res) => {
        if(res.state){
          this.data = res.data
          articlesServices.articlesTop5()
              .then((rest) => {
                this.loading = false

                if(rest.state){
                  this.loading = false
                  this.top5Data = rest.data
                }
              })
        }
      })
    }
    },
  created() {
    this.getLastArticles()
  }
}
</script>

<style scoped>

</style>