import Vue from 'vue';
import VueRouter from 'vue-router';

// store
import { store } from '../store'

// services
import AuthService from '@/services/authService';
import siteInfo from "@/services/siteInfo";

// User Pages
import ForgotPassword from "@/views/User/ForgotPassword";
import Register from "@/views/User/Register";
import Login from "@/views/User/Login";
import Logout from "@/views/User/Logout";
// Pages
import Home from "@/views/Home";
import Game from "@/views/Game";
import Games from "@/views/Games"
import Cart from "@/views/Cart";
import Malls from "@/views/Malls";
import Mall from "@/views/Mall";
import AdvertisementsView from "@/views/Advertisements"
import AdvertisementDetail from "@/views/AdvertisementDetail";
import News from "@/views/News";
import ArticleDetail from "@/views/ArticleDetail";
import Profile from "@/views/Profile";
import Streamers from "@/views/Streamers";

//User Main
import Main from "@/views/User/Main/Main";
import Balance from "@/views/User/Main/Balance";
import Orders from "@/views/User/Main/Orders";
import HomeUser from "@/views/User/Main/HomeUser"
import Withdraw from "@/views/User/Main/Withdraw";
import Balancelogs from "@/views/User/Main/Balancelogs";
import AddAdvertisement from "@/views/User/Main/AddAdvertisement";
import Advertisements from "@/views/User/Main/Advertisements";
import AddsOrders from "@/views/User/Main/AddsOrders";
import Articles from "@/views/User/Main/Articles";
import AddArticle from "@/views/User/Main/AddArticle";
import ArticlePreview from "@/views/ArticlePreview";
import EditArticle from "@/views/User/Main/EditArticle";
import Adds from "@/views/User/Main/Adds";
import Messages from "@/views/User/Main/Messages";
import MessageDetail from "@/views/User/Main/MessageDetail";
import AddAdds from "@/views/User/Main/AddAdds";
import Streaming from "@/views/User/Main/Streaming";
import Donate from "@/views/Donate";
import Pay from "@/views/Pay";
import iframeDetail from "@/components/PaymentDetail/iframeDetail";
import AccountSettings from "@/views/User/Main/AccountSettings";
import PaymentApi from "@/components/PaymentApi/PaymentApi";
import Page from "@/views/Page";
import ArticleSearch from "@/views/ArticleSearch";
import GiftCards from "@/views/GiftCards";
import Destek from "@/views/Destek";
import Contact from "@/views/Contact";
import Hakkimizda from "@/views/Hakkimizda";
import Gizlilik from "@/views/Gizlilik";
import Satis from "@/views/Satis";
import Western from "@/views/payments/Western";
import SwiftUSD from "@/views/payments/SwiftUSD";
import SwiftEURO from "@/views/payments/SwiftEURO";

Vue.use(VueRouter);

const userChildRoute = (prop) => [
    {
        path: '',
        name: prop + '.maind',
        component: HomeUser,
        meta: { requiresLogin: true }
    },
    {
        path: 'balance',
        name: prop + '.balance',
        component: Balance,
        meta: { requiresLogin: true }
    },
    {
        path: 'withdraw',
        name: prop + '.withdraw',
        component: Withdraw,
        meta: { requiresLogin: true}
    },
    {
        path: 'balancelogs',
        name: prop + '.balancelogs',
        component: Balancelogs,
        meta: { requiresLogin: true}
    },
    {
        path: 'orders',
        name: prop+ '.orders',
        component: Orders,
        meta: { requiresLogin: true}
    },
    {
        path: 'advertisements',
        name: prop + '.advertisements',
        component: Advertisements,
        meta: {requiresLogin: true}
    },
    {
        path: 'addadvertisement',
        name: prop + '.addadvertisement',
        component: AddAdvertisement,
        meta: {requiresLogin: true}
    },
    {
        path: 'addsorders',
        name: prop + '.addsorders',
        component: AddsOrders,
        meta: {requiresLogin: true}
    },
    {
        path: 'articles',
        name: prop + '.articles',
        component: Articles,
        meta: {requiresLogin: true}
    },
    {
        path: 'add-article',
        name: prop + '.add-article',
        component: AddArticle,
        meta: {requiresLogin: true}
    },
    {
        path: 'adds',
        name: prop + '.adds',
        component: Adds,
        meta: {requiresLogin: true}
    },
    {
        path: 'add-adds',
        name: prop + '.add-adds',
        component: AddAdds,
        meta: {requiresLogin: true}
    },
    {
        path: 'messages',
        name: prop + '.messages',
        component: Messages,
        meta: {requiresLogin: true}
    },
    {
        path: 'chat/:id',
        name: prop + '.chatDetail',
        component: MessageDetail,
        meta: {requiresLogin: true}
    },
    {
        path: 'streaming',
        name: prop + '.streaming',
        component: Streaming,
        meta: {requiresLogin: true}
    },
    {
        path: 'settings',
        name: prop + '.settings',
        component: AccountSettings,
        meta: {requiresLogin: true}
    },
    {
        path: 'adds',
        name: prop + '.adds',
        component: Adds,
        meta: { requiresLogin: true }
    }

]

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/register',
        name: 'register',
        component: Register,
        props: true,
        meta: {requiresLogout: true}

    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        props: true,
        meta: {requiresLogout: true}
    },
    {
      path: '/forgot-password',
      name: 'forgotPassword',
      component: ForgotPassword,
      meta: {requiresLogout: true}
    },
    {
        path: '/logout',
        name: 'logout',
        component: Logout
    },
    {
        path: '/main',
        name: 'main',
        meta: {requiresLogin: true},
        children: userChildRoute('main'),
        component: Main,
    },
    {
        path: '/game/:link',
        name: 'gameDetail',
        component: Game,
    },
    {
        path: '/games',
        name: 'games',
        component: Games
    },
    {
        path: '/gift-cards',
        name: 'giftCards',
        component: GiftCards
    },
    {
      path: '/stores',
      name: 'stores',
      component: Malls
    },
    {
        path: '/store/:link',
        name: 'storeDetail',
        component: Mall,
    },
    {
        path: '/cart',
        name: "cart",
        component: Cart
    },
    {
        path: '/advertisements',
        name: "advertisements",
        component: AdvertisementsView
    },
    {
        path: '/advertisement/:id',
        name: 'advertisement',
        component: AdvertisementDetail,
    },
    {
        path: '/article-preview/:id',
        name: 'article-preview',
        component: ArticlePreview,
        meta: {requiresLogin: true}
    },
    {
        path: '/edit-article/:id',
        name: 'edit-article',
        component: EditArticle,
        meta: {requiresLogin: true}
    },
    {
        path: '/news',
        name: 'news',
        component: News
    },
    {
        path: '/article/:id',
        name: 'articles',
        component: ArticleDetail
    },
    {
        path: '/profile/:username',
        name: 'profile',
        component: Profile
    },
    {
        path: '/streamers',
        name: 'streamers',
        component: Streamers
    },
    {
        path: '/donate/:username',
        name: 'donate',
        component: Donate
    },
    {
        path: '/pay',
        name: 'Pay',
        component: Pay
    },
    {
        path: '/payment-iframe',
        name: 'PaymentIframe',
        component: iframeDetail
    },
    {
        path: '/payment-iframed',
        name: 'PaymentIframe',
        component: iframeDetail
    },
    {
        path: '/payment',
        name: 'PaymentApi',
        component: PaymentApi
    },
    {
        path: '/page/:link',
        name: 'PageDetail',
        component: Page
    },
    {
        path: '/article-search/:key',
        name: 'articleSearch',
        component: ArticleSearch
    },
    {
        path: '/destek',
        name: 'destek',
        component: Destek
    },
    {
        path: '/contact',
        name: 'contact',
        component: Contact
    },
    {
        path: '/hakkimizda',
        name: 'hakkimizda',
        component: Hakkimizda
    },
    {
        path: '/gizlilik-sozlesmesi',
        name: 'gizlilik',
        component: Gizlilik
    },
    {
        path: '/satis-sozlesmesi',
        name: 'satisp',
        component: Satis
    },
    {
        path: '/western-union',
        name: 'westernUnion',
        component: Western
    },
    {
        path: '/swift-usd',
        name: 'swiftUSD',
        component: SwiftUSD
    },
    {
        path: '/swift-euro',
        name: 'swiftEURO',
        component: SwiftEURO
    }



]


const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior() {
        return {x: 0, y: 0}
    }
});
router.beforeEach((to, from, next) => {
    siteInfo.getSiteInfo()
    if (to.meta.requiresLogin) {
        if(store.getters["User/isLoggedIn"]) {
            const response = AuthService.getSecretContent()
            response.then((result) => {
                if(!result.state){
                    store.dispatch('User/logout')
                    store.dispatch('userId')
                    next({name: 'login'})
                }else {
                    store.dispatch('User/userLogin', result)
                    store.dispatch('userId', result.user.id)

                    next()
                }
            })
        }
        else {
            next({name: 'login'})
        }
    }
    else {
        if(to.meta.requiresLogout)
            if(store.getters["User/isLoggedIn"])
                next('/main')
            else
                next()
        else
            next()
    }

})
export default router
