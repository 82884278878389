<template>
  <section class="sales game-sales">
    <div class="container">
      <div class="title">
        <h1>İlanlar </h1>
        <b-skeleton animation="throb" class="bg-dark nav" v-if="loading" width="20%"></b-skeleton>
      </div>
      <div class="tab-content" v-if="loading">
        <div class="tab-pane active">
          <div class="owl-carousel">
            <Carousel
                :perPageCustom = "[[768, 3], [1024, 6]]"
                :paginationEnabled=false
                :autoplay=true
            >
              <slide v-for="item in 6" :key="item">
                <div class="item">
                  <a >
                    <figure>
                      <b-skeleton-img animation="throb"></b-skeleton-img>
                    </figure>
                    <strong>
                      <b-skeleton animation="throb" class="bg-dark " width="100%"></b-skeleton>
                    </strong>
                    <span>
                      <b-skeleton animation="throb" class="bg-dark " width="100%"></b-skeleton>
                    </span>
                  </a>
                </div>
              </slide>
            </Carousel>



          </div>
        </div>
      </div>

      <div class="tab-content" v-if="!loading">
        <div class="tab-pane active">
          <div class="owl-carousel">
            <Carousel
                :perPageCustom = "[[768, 3], [1024, 6]]"
                :paginationEnabled=false
                :autoplay=true
            >
              <slide v-for="(advertisement, index) in advertisementList" :key="index">
                <div class="item">
                  <router-link :to="{path: '/advertisement/'+advertisement.id}">
                    <figure>
                      <img :src="advertisement.image" />
                      <figcaption>{{ advertisement.price | currency}} TL </figcaption>
                    </figure>
                    <strong>{{ advertisement.gameTitle }}</strong>
                    <span>{{ advertisement.title }}</span>
                  </router-link>
                </div>
              </slide>
            </Carousel>



          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';
export default {
  name: "Sales",
  data() {
    return {
      loading: false,
    }
  },
  props:['advertisementList'],
  components:{
    Carousel,
    Slide
  },
}
</script>

<style scoped>
.game-sales {
  background: #130E17;
  margin-top: 0px;
}

</style>