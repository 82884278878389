<template>
  <section class="stores">
    <div class="container">
      <h1>Oyunlar</h1>
      <div class="row">
        <div class="col-md-3" v-for="(game, index) in games" :key="index">
          <div class="item">
            <router-link :to="{path: 'game/'+game.link}">
              <a>
                <img :src="game.image" />
                <strong>{{game.title}}</strong>
              </a>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="more">
      <router-link to="/games">DAHA FAZLA</router-link>
    </div>
  </section>
</template>

<script>

export default {
  name: "GamesSlider",
  data() {
    return {
      loading: this.$store.getters.homeLoading,
      games: []
    }
  },
  props: ['data'],
  watch: {
    '$store.getters.homeLoading': function() {
      this.loading = this.$store.getters.homeLoading
    }
  },
  created() {
    if(this.data.length > 12){
      let i = 0
      this.data.map((item) => {
        i++
        if(i < 13){
          this.games.push(item)
        }
      })
    }
  }

}
</script>

<style scoped>
.VueCarousel-slide {
  margin-right: 10px;
  max-width: 270px;
}
.VueCarousel-slide img {
  max-width: 270px;
}



</style>