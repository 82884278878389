<template>
  <div>
    <Header></Header>
    <div class="clearfix"></div>
    <section class="game-detail-page">
      <div class="gade-bg">
        <img :src="require('@/assets/images/od-bg.png')" >

      </div>
      <div class="gade-title">
        <div class="container">
          <div class="gadeti-img">
              <b-skeleton width="100%" height="100%" v-if="loading" class="bg-dark" animation="throb"></b-skeleton>
              <img :src="image" v-if="!loading"/>
          </div>
          <div class="gadeti-text">
            <h1 v-if="!loading">{{ title }}</h1>
            <h1 v-if="loading">
              <b-skeleton animation="throb" class="bg-dark" width="50%"></b-skeleton>
            </h1>
            <ul v-if="!loading">
              <li><a :href="web_site" target="_blank">{{ developer }}</a></li>
              <li><a :href="web_site" target="_blank">RESMİ WEBSİTESİ <img :src="require('../assets/images/href.png')"/></a></li>
            </ul>
            <div class="row" v-if="loading">
              <div class="col-md-3">
                <b><b-skeleton animation="throb" class="bg-dark" v-if="loading" width="100%"></b-skeleton></b>
              </div>
              <div class="col-md-3">
                <b><b-skeleton animation="throb" class="bg-dark" v-if="loading" width="100%"></b-skeleton></b>
              </div>
            </div>
            <p v-if="loading">
              <b-skeleton animation="throb" class="bg-dark" v-if="loading" width="15%"></b-skeleton>
              <b-skeleton animation="throb" class="bg-dark" v-if="loading" width="20%"></b-skeleton>
              <b-skeleton animation="throb" class="bg-dark" v-if="loading" width="25%"></b-skeleton>
            </p>

            <p v-html="description">

            </p>

          </div>
          <div class="clearfix"></div>
        </div>

      </div>
      <div class="game-products">
        <div class="container">
          <h1 class="product-title">Ürünler </h1>
          <div class="gamepro-left">

            <div class="item" v-if="products.length == 0 && !loading" style="height: 100%">
              <b-alert show variant="warning">
                <p>Bu ürüne ait ürünler halen hazırlanıyor..</p>
              </b-alert>
            </div>
            <div v-if="loading">
              <div class="item" v-for="index in 5" :key="index">
                <div class="gpi-img">
                  <b-skeleton animation="throb" class="bg-dark" v-if="loading" width="100%" height="100%"></b-skeleton>
                </div>
                <div class="gpi-text">
                  <div class="col-md-9">
                    <h3>
                      <b-skeleton animation="throb" class="bg-dark" v-if="loading" width="25%"></b-skeleton>
                    </h3>
                    <p>
                      <b-skeleton animation="throb" class="bg-dark" v-if="loading" width="25%"></b-skeleton>
                    </p>

                  </div>
                  <div class="col-md-3">
                    <button disabled><i class="fas fa-spinner fa-spin"></i></button>

                  </div>
                </div>
                <div class="clearfix"></div>
              </div>
            </div>
            <div class="item" v-for="(product, index) in products" :key="index">
              <div class="gpi-img"><img :src="image"/></div>
              <div class="gpi-text">
                <div class="col-md-9">
                  <h3>
                    {{ product.title }}
                  </h3>
                  <p v-html="product.description">

                  </p>
                  <label> {{ product.price }} &#8378;</label>
                </div>
                <div class="col-md-3">
                  <button v-if="waitCartButton" disabled><i class="fas fa-spinner fa-spin"></i></button>
                  <button @click="getCart(index)" v-else><i class="fas fa-cart-plus" /> SEPETE EKLE</button>

                </div>
              </div>
              <div class="clearfix"></div>
            </div>


          </div>
          <div class="gamepro-right">
            <h2 v-if="news.length > 0">İLGİLİ HABERLER</h2>
            <div class="best" v-if="news.length > 0">
              <div v-for="(item, index) in news" :key="index">
                <router-link :to="{path: '/article/'+item.id}" class="item">
                  <span class="left">{{index+1}}.</span>
                  <span class="center">{{item.title}}</span>
                  <span class="right"><img :src="item.image" /></span>
                </router-link>
              </div>
            </div>
            <div class="game-banner">
              <label>SPONSORLU</label>
              <Adds container="store_detail"></Adds>
            </div>
            <br>

          </div>
        </div>
      </div>
      <div class="clearfix"></div>
    </section>
    <Advertisements :advertisementList="advertisementList" v-if="advertisementList.length > 0"></Advertisements>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import gameService from "@/services/gameService";
import {mapGetters} from 'vuex'
import advertisementsService from "@/services/advertisementsService";
import Advertisements from "@/components/Advertisements/Advertisements";
import Adds from "@/components/Adds/Adds";
import articlesServices from "@/services/articlesServices";

export default {
  name: "Game",
  data() {
    return {
      loading: true,
      link: this.$route.params.link,
      background_image: "",
      description: "",
      developer: "",
      id: "",
      image: "",
      products: [],
      title: "",
      web_site: "",
      waitCartButton: false,
      advertisementList: [],
      news: []
    }
  },
  computed: {
    ...mapGetters({
      cartInfo: ['Cart/cart'],
      siteSettings: 'siteInfo/siteSettings'
    })
  },
  methods:{
    getArticles() {
      let splitTitle = this.title.split(' ')
      splitTitle.map((key) => {
        articlesServices.getArticlesSearch({key: key})
            .then((res) => {
              if(res.state){
                res.data.map((article) => {
                  this.news.push(
                      {
                        id: article.id,
                        title: article.title,
                        image: article.image
                      }
                  )
                })
              }
            })
      })
    },
    getAdvertisementsGameDetail() {
      if(!this.loading) {
        advertisementsService.getAdvertisementsGameDetail({game_id: this.id})
            .then((res) => {
              this.advertisementList = res.list
            })
      }
    },
    errorMessage(val) {
      this.$swal({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        icon: 'error',
        title: val,
        text: '',
      });

    },
    successMessage(val) {
      this.$swal({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        icon: 'success',
        title: val,
        text: '',
      });
      },
    getCart(index) {
      this.waitCartButton = true
      this.products[index].count = 1
      gameService.getProduct({id: this.products[index].id})
          .then((res) => {
            this.waitCartButton = false
            if(res.state) {
              if(res.product.count > 0) {
                let cartProducts = this.cartInfo
                let cartProduct = cartProducts.filter(e => e.id === this.products[index].id)
                if(cartProduct.length > 0) {
                  //let dataq = cartProduct[0]
                  let count = cartProduct[0].count + 1
                  if(count > res.product.count)
                  {
                    this.errorMessage("Bu ürün için yeterli stok yok.")
                  }else {
                    this.$store.dispatch('Cart/updateProduct', {
                      title: res.product.title,
                      count: count,
                      price: res.product.price,
                      description: res.product.description,
                      id: res.product.id,
                      image: this.image,
                      gameLink: this.link,
                      gameTitle: this.title
                    }).then(this.successMessage("Ürün Alışveriş Sepetinize Eklendi!"))
                  }

                }else {
                  this.$store.dispatch('Cart/setProduct', {
                    title: res.product.title,
                    count: 1,
                    price: res.product.price,
                    description: res.product.description,
                    id: res.product.id,
                    image: this.image,
                    gameLink: this.link,
                    gameTitle: this.title
                  }).then(this.successMessage("Ürün Alışveriş Sepetinize Eklendi!"))
                }
              }else {
                this.errorMessage("Bu ürün için şuanda yeterli stok yok.")
              }
            }else {
              this.errorMessage(res.message)
            }
          })
    }
  },
  components: {
    Header,
    Footer,
    Advertisements,
    Adds
  },
  async created() {
    gameService.getStore({link: this.link})
        .then((data) =>
        {
          this.loading = false
          if(data.state)
          {
            const res = data.data[0]
            this.background_image = res.background_image
            this.description = res.description
            this.developer = res.developer
            this.id = res.id
            this.image = res.image
            this.products = res.products
            this.title = res.title
            this.web_site = res.web_site
            this.getArticles()
          }
          else{
            this.$router.push({path: "/"})
          }
        })
  },
  watch: {
    loading: function () {
      if(!this.loading) {
        this.getAdvertisementsGameDetail()
      }
    }
  },
  metaInfo() {
    return {
      title: this.title+" Ürünleri - " + this.siteSettings[0].title,
      meta: [
        {name: 'description', content: this.title+" Ürünleri - " + this.siteSettings[0].description},
        {name: 'keywords', content: this.title+ " Ürünleri - " + this.siteSettings[0].keywords},
        {charset: 'utf-8'}
      ]
    }
  }
}
</script>

<style scoped>
.game-detail-page {
  background: #130E17;
}
.game-products .gamepro-left .item .gpi-text button {
  letter-spacing: 0px;
}
</style>