<template>
  <b-modal id="sendMessage"  modal-class=" message-modal"  hide-footer hide-header >
      <div class="balmod-title">
        YENİ MESAJ
      </div>
      <div class="item">
        <h4>Kime</h4>
        <b-input v-model="user"></b-input>
      </div>
      <div class="item">
        <h4>Konu</h4>
        <b-input placeholder="Lütfen konu içeriği belirtin." v-model="subject"/>
      </div>
      <div class="item">
        <h4>Mesaj</h4>
        <b-textarea v-model="message" placeholder="Küfür, hakaret, reklam ve yanıltıcı mesaj atmanız durumunda hesabınız süresiz kapatılabilir."></b-textarea>
      </div>
      <div class="item text-right">
        <button class="btn-hover" @click="sendMessage" v-if="!loading">
                        <span>
                        <img :src="require('@/assets/images/paper.png')"> GÖNDER
                        </span>
        </button>
        <button class="btn-hover" v-if="loading">
          <span>  <i class="fas fa-spinner fa-spin"></i></span>
        </button>
      </div>
  </b-modal>
</template>

<script>
import authService from "@/services/authService";

export default {
  name: "SendMessage",
  data() {
    return {
      message: '',
      subject: '',
      loading: false,
      user: ''
    }
  },
  props: ['username'],
  methods: {
    clientAnswer(message) {
      this.$swal({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        icon: message.icon,
        title: '',
        text: message.message,
      });
    },
    sendMessage() {
      this.loading = true
      authService.createChat({username: this.user, subject: this.subject, message: this.message})
      .then((res) => {
        this.loading = false
        if(res.state) {
          this.clientAnswer({icon: 'success', message: 'Mesajınız gönderildi.'})
          this.message = ''
          this.subject = ''
          this.$bvModal.hide('sendMessage')
        }
        else
          this.clientAnswer({icon: 'error', message: res.message})
      })
    }
  },
  watch: {
    username: function () {
      this.user = this.username;
    }
  }
}
</script>

<style scoped>

</style>