<template>
<div>
  <Header />
  <section class="live-page">
    <div class="page-title">
      <div class="container">
        <div class="bg-blur"></div>
        <div class="live-title">
          <div class="live-title-left">
            <div class="title-img">
              <img :src="data.profile_pic" v-if="!loading">
              <b-skeleton v-if="loading" type="avatar" height="100%" width="100%"></b-skeleton>
            </div>
            <div class="title-text">
              <h3 v-if="loading"><b-skeleton width="50%"/> <label> </label>
              </h3>
              <h3 v-if="!loading">{{ data.platform_username }} <label  v-if="data.is_online"> <img :src="require('@/assets/images/title-dist.png')"> YAYINDA</label>
                <div class="title-collapse">
                  <a class="btn-hide"><img src="images/down.png"></a>
                  <a class="btn-show d-none"><img src="images/up.png"></a>
                </div>
              </h3>
              <p v-if="loading">
                <b-skeleton />
              </p>
              <p v-if="!loading">
                {{ data.description }}
              </p>

            </div>
          </div>
          <div class="live-title-right">
            <div class="live-button" v-if="loading">
              <b-skeleton width="100%" height="100%"/>
            </div>
            <div class="live-button" v-if="!loading">
              <a :href="data.channel_url" target="_blank" v-if="data.platform === 'twitch'">
                <img :src="require('@/assets/images/live-twitch.png')">
                <label>@{{ data.platform_username }} <img :src="require('@/assets/images/lh.png')"></label></a>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="live-content">
      <div class="container">
        <div class="content-left" v-if="!loading">
          <iframe
              :src="'https://player.twitch.tv/?channel='+data.platform_username+'&muted=true&autoplay=true&parent=kasagame.com'"
              height="410"
              width="730"
              frameborder="0"
              allowfullscreen="true">
          </iframe>

        </div>
        <div class="content-right" >
          <div class="support-form">
            <h3>YAYINCIYI DESTEKLE</h3>
              <div class="item">
                <label>DESTEK MİKTARI</label>
                <div class="btn-group btn-group-toggle">
                  <label class="btn btn-secondary " :class="{'active' : amount === 5}" @click="changePrice(5)">
                    <input type="radio" name="options" > 5 TL
                  </label>
                  <label class="btn btn-secondary" :class="{'active' : amount === 20}"  @click="changePrice(20)">
                    <input type="radio" name="options" > 20 TL
                  </label>
                  <label class="btn btn-secondary" :class="{'active' : amount === 50}"  @click="changePrice(50)">
                    <input type="radio" name="options" > 50 TL
                  </label>
                  <div class="addon input-try">
                    <input type="text" class="form-control" v-model="amount">
                    <span>TL</span>
                  </div>
                </div>
              </div>
              <div class="item">
                <label>DESTEKLEYEN İSİM</label>
                <input type="text" placeholder="Bu isim ekranda görünecektir." v-model="name">
              </div>
              <div class="item">
                <label>MESAJINIZ</label>
                <input type="text" placeholder="Mesajınızı yazınız." v-model="message">
              </div>
              <div class="item">
                <button class="btn-hover" v-if="loading || btnLoading"><span>  <i class="fa fa-spinner fa-spin" /></span></button>
                <button class="btn-hover" v-else @click="donate"><span>BAĞIŞ YAP  <img :src="require('@/assets/images/btn-right.png')"></span></button>
              </div>
          </div>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
  </section>
  <Footer />
</div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import streamService from "@/services/streamService";
import {mapGetters} from "vuex";
  export default {
    name: "Donate",
    data() {
      return {
        loading: true,
        data: [],
        username: '',
        amount: '',
        name: '',
        message: '',
        btnLoading: false
      }
    },
    methods: {
      changePrice(val) {
        this.amount = val
      },
      donate() {
        if(this.$store.getters["User/isLoggedIn"])
        {
          this.btnLoading = true
          streamService.donate({streamer_id: this.data.id,streamer_user_id: this.data.user_id, amount: this.amount, message: this.message, name: this.name })
          .then((response)  => {
            this.btnLoading = false
            if(response.state)
              this.clientMessage({icon: 'success', text: response.message, title: 'Teşekkürler!'})
            else {
              this.clientMessage({icon: 'error', title: 'Ops!', text: response.message})
              if(response.balanceError)
              {
                this.$store.dispatch('depositDefault', {
                  depositError: true,
                  depositMessage: 'Bakiyeniz yetersiz, lütfen bakiye yükleyin.'
                })
                this.$bvModal.show('deposit')
              }
            }
          })
        }else{
          this.$router.push('/login')
        }
      },
      getStreamer(){
        streamService.getStreamer(this.$route.params.username)
        .then((res) => {
          if(res.state) {
            this.loading = false
            this.data = res.data
            this.username = res.data.platform_username
          }
        })
      },
      clientMessage(msg) {
        this.$swal({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          icon: msg.icon,
          title: msg.title,
          text: msg.text,
        });
      },

    },
    components: {Header,Footer},
    created() {
      this.getStreamer()
    },
    computed: {
      ...mapGetters({
        siteSettings: 'siteInfo/siteSettings'
      })
    },
    metaInfo() {
      return {
        title: this.username+" Yayıncı destek sayfası - " + this.siteSettings[0].title,
        meta: [
          {name: 'description', content: "Yayıncılar - " + this.siteSettings[0].description},
          {name: 'keywords', content: "Yayıncılar - " + this.siteSettings[0].keywords},
          {charset: 'utf-8'}
        ]
      }
    },

  }
</script>

<style scoped>
.live-page {
  background: #130E17;
}
</style>