export default {
    setSiteSettings(state, data) {
        state.siteSettings = data
    },
    setHomeSlider(state, data) {
        state.homeSlider = data
    },
    setGamesSlider(state, data) {
        state.gamesSlider = data
    },
    setStoreSlider(state, data) {
        state.storeSlider = data
    },
    setCommissions(state, data) {
        state.commissions = data
    },
    setAdvertisementGames(state, data) {
        state.advertisement_games = data
    },
    setAdvertisements(state, data) {
        state.advertisements = data
    },
    setAdds(state, data) {
        state.adds = data
    },
    setGiftCards(state, data) {
        state.giftCards = data
    }



}