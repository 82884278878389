<template>
<div class="userpanel">
  <Header></Header>
  <router-view></router-view>
  <Footer></Footer>
</div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
export default {
  name: "Main",
  components: {
    Header,
    Footer
  },
  metaInfo() {
    return {
      title: "Hesabım"
    }
  },
}
</script>

<style scoped>
.userpanel {
  background:#130E17
}
</style>
