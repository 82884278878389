<template>
  <section class="pubpanel">
    <div class="pubpanel-title">
      <div class="container">
        <div class="bg-blur"></div>
        <h1>İlanlarım</h1>
      </div>
    </div>
    <div class="container">
      <div class="sidebar-one">
        <LeftMenu :data="page"></LeftMenu>
      </div>
      <div class="myads-right">
        <div class="myads-add">
          <router-link to="/main/addadvertisement">
            <button type="button" ><img :src="require('@/assets/images/add.png')"> YENİ İLAN EKLE</button>
          </router-link>
        </div>
        <div class="text-center text-white my-2" v-if="isLoading">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Yükleniyor...</strong>
        </div>
        <b-alert variant="warning" show v-if="errorMessage && !isLoading">
          Henüz kayıt yok..
        </b-alert>

        <div class="item" v-for="(item,index) in list" :key="index">
          <figure class="item-img">
            <img :src="item.image">
            <figcaption>{{ item.price | currency }} TL</figcaption>
          </figure>
          <div class="item-text">
            <h4><router-link :to="{path: '/advertisement/'+item.id}">{{ item.title }}</router-link></h4>
            <label>{{ item.gameTitle }}</label>
            <ul>
              <li><img :src="require('@/assets/images/eye.png')"> {{ item.views }} görüntülenme</li>
              <li><img :src="require('@/assets/images/my-date.png')"> {{ item.date | formatDate}}</li>
              <li><img :src="require('@/assets/images/my-dot.png')"> İLAN NO: #{{ item.id }}</li>
            </ul>
            <b-button-group>
              <b-button  v-if="item.status == 'waiting_sale2'" class="btn-sm" variant="info" v-b-tooltip:v-b-hover title="Düzenle"> <i class="fas fa-cog"></i> </b-button>
              <b-button v-if="item.status == 'waiting_saler'" class="btn-sm" variant="success"  v-b-tooltip:v-b-hover title="Sipariş Bilgilerini Gör" @click="confirm(item.id)"> <i class="fas fa-check"></i> </b-button>
              <b-button v-if="item.status == 'waiting_sale'" class="btn-sm" variant="danger" @click="removeAdvertisement(item.id)" v-b-tooltip:v-b-hover title="İlanı Durdur"> <i class="fas fa-minus-circle"></i> </b-button>
              <b-button v-if="item.status == 'waiting_saler'" class="btn-sm" variant="danger" @click="cancelOrder(item.id)" v-b-tooltip:v-b-hover title="İptal Et"> <i class="fas fa-minus-hexagon"></i> </b-button>
              <b-button v-if="item.status == 'waiting_buyer'" class="btn-sm" variant="warning"  v-b-tooltip:v-b-hover title="Alıcı Onayı Bekleniyor"> <i class="fas fa-hourglass-half"></i> </b-button>
              <b-button v-if="item.status == 'approved'" class="btn-sm" variant="success"  v-b-tooltip:v-b-hover title="Satış tamamlandı"> <i class="fas fa-check"></i> </b-button>
              <b-button v-if="item.status == 'declined'" class="btn-sm" variant="danger"  v-b-tooltip:v-b-hover title="İptal Edildi"> <i class="fas fa-minus"></i> </b-button>
              <b-button  v-if="item.status == 'waiting'" class="btn-sm" variant="warning"  v-b-tooltip:v-b-hover title="Yayın Onayı Bekleniyor"> <i class="fas fa-hourglass-half"></i> </b-button>
            </b-button-group>
          </div>
          <div class="clearfix"></div>
        </div>
        <div id="moreButton">
          <div class="balance-more"  v-if="logsPage < lastPage">
            <div class="more-btn"><a href="#" @click="getMore">DAHA FAZLA</a></div>
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
    </div>
    <confirm-advertisement :id="confirmId"></confirm-advertisement>
  </section>
</template>

<script>
import LeftMenu from "@/components/User/LeftMenu";
import {mapGetters} from "vuex"
import advertisementsService from "@/services/advertisementsService";
import ConfirmAdvertisement from "@/components/Modals/ConfirmAdvertisement";
export default {
  name: "HomeUser",
  components: {
    LeftMenu,
    ConfirmAdvertisement
  },
  data() {
    return {
      page: "advertisements",
      list: [],
      isLoading: false,
      lastPage: 1,
      logsPage: 1,
      errorMessage: false,
      confirmId: 0

    }
  },
  methods: {
    confirm(id) {
      this.confirmId = id
      this.$bvModal.show('confirm-advertisement')
    },
    cancelOrder(id) {
      this.$swal({
        title: "Emin misiniz?",
        text: "Tutar iade edilecek ve ilan satışı durdurulacaktır.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Evet, İade Et!",
        cancelButtonText: "Vazgeç"
      })
          .then((confirm) => {
            if(confirm.isConfirmed) {
              advertisementsService.cancelAdvertisementOrderBySaler({id: id})
                  .then((res) => {
                    if(res.data.state) {
                      this.$swal({
                        title: "Tebrikler!",
                        text: "Satış iptal edildi ve iade gerçekleşti.",
                        icon: "success"
                      })
                      this.getMyAdvertisements()
                    }else{
                      this.$swal({
                        title: "Ops!",
                        text: res.data.message,
                        icon: "error"
                      })

                    }
                  })
            }else {
              this.$swal({
                title: "OKEY!",
                text: "İşlemden vazgeçildi..",
                icon: "success"
              })

            }
          })
    },
    removeAdvertisement(id) {
      this.$swal({
        title: "Emin misiniz?",
        text: "İlan yayından tamamen kaldırılacaktır.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Evet, Kaldırılsın!",
        cancelButtonText: "Vazgeç"
        })
      .then((confirm) => {
        if(confirm.isConfirmed) {
          this.$swal({
            title: "Tebrikler!",
            text: "İlan yayından kaldırıldı.",
            icon: "success"
          })
          advertisementsService.removeAdvertisement({id: id})
          .then(() => {
            this.getMyAdvertisements()
          })
        }else {
          this.$swal({
            title: "OKEY!",
            text: "İşlem iptal edildi..",
            icon: "success"
          })

        }
      })

    },
    getMore() {
      this.logsPage++
      advertisementsService.getMyAdvertisements({page: this.logsPage})
          .then((res) => {
            this.isLoading = false
            if(res.data.state) {
              this.lastPage = res.data.lastPage
              this.logsPage = res.data.page
              this.list.push(...res.data.list)
              this.errorMessage = false
            } else {
              this.errorMessage = true
              this.list = []
            }
          })
    },
    getMyAdvertisements() {
      this.isLoading = true
      advertisementsService.getMyAdvertisements({page: this.logsPage})
          .then((res) => {
            this.isLoading = false
            if(res.data.state) {
              this.lastPage = res.data.lastPage
              this.logsPage = res.data.page
              this.list = res.data.list
              this.errorMessage = false
            } else {
              this.errorMessage = true
            }
          })

    }
  },
  computed:{
    ...mapGetters({
      user: 'User/user'
    })
  },
  watch:
      {
        list: function () {
          if (this.logsPage < this.lastPage)
            document.getElementById('moreButton').scrollIntoView();
        }
      },
  metaInfo() {
    return {
      title: 'İlanlarım - Hesabım',
    }
  },
  created() {
    this.getMyAdvertisements()
  },


}
</script>

<style scoped>
.profile-page {
  padding-top: 0px;
}

</style>