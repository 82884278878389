<template>
  <section class="message-page">
    <div class="page-title">
      <div class="container">
        <div class="bg-blur"></div>
        <h1> Mesajlarım </h1>
      </div>
    </div>
    <div class="message-content">
      <div class="container">
        <div class="sidebar-one">
          <LeftMenu :data="page"></LeftMenu>
        </div>
        <div class="message-right">
          <div class="message-add">
            <button type="button" v-b-modal.sendMessage><img :src="require('@/assets/images/add.png')"> YENİ MESAJ</button>
          </div>

          <div class="message-list">
            <div class="message-title">
              <h3>MESAJLAR </h3>
            </div>
            <div v-for="(item, index) in logs" :key="index">
              <div class="item " :class="{'unreader' : item.iam_sender && !item.sender_see, 'unread' : !item.iam_sender && !item.user_see }">
                <router-link :to="{path: '/main/chat/'+item.id}">
                  <div class="images" v-if="item.iam_sender"><img :src="item.user[0].profilePic"></div>
                  <div class="images" v-else><img :src="item.sender[0].profilePic"></div>
                  <div class="text">
                    <h5> {{ item.subject }}</h5>
                    <label v-if="item.iam_sender">{{ item.user[0].username }}</label>
                    <label v-else>{{ item.sender[0].username }}</label>
                  </div>
                  <div class="date"><img :src="require('@/assets/images/date-time.png')"> {{ item.date | formatDate}}</div>
                </router-link>
                <div class="cog dropdown">
                  <a href="#" class="dropdown-toggle" data-toggle="dropdown"><i class="fa fa-ellipsis-v-alt"></i></a>
                  <div class="dropdown-menu">
                    <a class="dropdown-item" href="#">Sil</a>
                    <a class="dropdown-item" href="#">Okundu Olarak İşaretle</a>
                    <a class="dropdown-item" href="#">Arşivle</a>
                  </div>

                </div>
                <div class="clearfix"></div>

              </div>
            </div>
            <div class="more" style="display: none;">
              <a href="#">DAHA FAZLA</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
    <SendMessage username=""></SendMessage>
  </section>
</template>

<script>
import LeftMenu from "@/components/User/LeftMenu";
import authService from "@/services/authService";
import SendMessage from "@/components/Modals/SendMessage";

export default {
  name: "Messages",
  data() {
    return  {
      page: "messages",
      logs: [],
      isLoading: false,
      lastPage: 1,
      logsPage: 1,
      errorMessage: false,
    }
  },
  components: {LeftMenu, SendMessage},
  methods: {
    getMessages() {
      this.isLoading = true
      authService.getChats({page: this.logsPage})
          .then((res) => {
            this.isLoading = false
            if(res.state) {
              this.logs = res.data
              this.errorMessage = false
            } else {
              this.errorMessage = true
            }
          })

    },
    getMore() {
      this.logsPage++
      authService.getChats({page: this.logsPage})
          .then((res) => {
            this.isLoading = false
            if(res.data.state) {
              this.lastPage = res.data.lastPage
              this.logsPage = res.data.page
              this.logs.push(...res.data.data)
              this.errorMessage = false
            } else {
              this.errorMessage = true
              this.logs = []
            }
          })
    }

  },
  async created() {
    this.getMessages()
  },
  watch:
      {
        logs: function () {
          if (this.logsPage < this.lastPage)
            document.getElementById('moreButton').scrollIntoView();
        }
      },
  metaInfo() {
    return {
      title: 'Mesajlarım - Hesabım',
    }
  }
}
</script>

<style scoped>

</style>