<template>
  <div class="container">
    <header class="header fixed-top">
      <div class="header-menu">
        <div class="logo">
          <router-link to="/">
          <a><img :src="require('../assets/images/kasagame-logo.svg')"/></a>
          </router-link>
        </div>
        <ul>
          <li><router-link to="/games"><a>OYUNLAR</a></router-link></li>
          <li><router-link to="/gift-cards"><a>HEDİYE KARTLARI</a></router-link></li>
          <li><router-link to="/stores"><a>MAĞAZALAR</a></router-link></li>
          <li><router-link to="/advertisements"><a>İLANLAR</a></router-link></li>
          <li><router-link to="/streamers">YAYINCILAR</router-link></li>
          <li><router-link to="/news">HABERLER</router-link></li>
        </ul>
      </div>
      <div class="header-right">
        <ul>

          <li class=" text-white">
            <router-link to="/cart">
              <a>
                <span class="basket-badge notranslate" v-if="$store.getters['Cart/cart'].length > 0"> {{ $store.getters["Cart/cart"].length }}</span>
                <i class="fas fa-shopping-cart fa-lg" />
              </a>
            </router-link>
          </li>
          <li><a v-b-modal.search class="modalSearch"><img :src="require('../assets/images/search.svg')"> <span class="">Ara</span></a></li>
          <LoginHeader></LoginHeader>
        </ul>

      </div>
    </header>
    <Gift></Gift>
    <Search></Search>
  </div>
</template>

<script>
import LoginHeader from "@/components/User/LoginHeader";
import Gift from "@/components/Modals/Gift";
import Search from "@/components/Modals/Search";
export default {
  name: "Header",
  data() {
    return {
      token: this.$store.getters["User/token"]
    }
  },
  components: {
    LoginHeader,
    Gift,
    Search
  }
}
</script>

<style scoped>

</style>