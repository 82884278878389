export default {
    setProduct(context, payLoad) {
        context.commit('setProduct', payLoad)
    },
    updateProduct(context, payLoad) {
        context.commit('updateProduct', payLoad)
    },
    removeProduct(context, payLoad) {
        context.commit('removeProduct', payLoad)
    },
    clearCart(context) {
        context.commit('clearCart')
    }
}