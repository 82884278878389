export default {
    setLogIn(state, data) {
        state.isLogged = true
        state.user = data.user
        state.token = data.token
    },
    logout(state) {
        state.isLogged = false
        state.user = {}
        state.token = ''
    },
    setRegisterSocial(state, data) {
        state.socialRegister = data
    }
}